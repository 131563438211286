import { Position, usePositionSpring } from '@nucleus/lib-animation';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { MenuListContext } from './MenuListContext';

export interface MenuListProps extends React.HTMLAttributes<HTMLUListElement> {
  /**
   * Indicates whether the MenuList is visible.
   */
  initialPosition?: Position;
  isVisible?: boolean;
}

/**
 * The MenuList component is used to render a <ul> list of menu items. It can manage the visibility of child elements
 * and applies animations for sliding and fading in/out.
 *
 * Intended to be used with <MenuItem> for automatic nested menu management
 *
 * @param props - The props for the MenuList component
 * @returns A JSX element representing the MenuList.
 */
export const MenuList = ({ isVisible = true, initialPosition, ...props }: MenuListProps): JSX.Element => {
  const [childMenuListIsVisible, setChildMenuListIsVisible] = React.useState(false);

  const context: MenuListContext = {
    setChildMenuListIsVisible: setChildMenuListIsVisible,
  };

  const currentPosition = childMenuListIsVisible ? 'left' : isVisible ? 'center' : initialPosition ?? 'right';

  return (
    <MenuListContext.Provider value={context}>
      <List {...props} as={animated.ul} style={{ ...props.style, ...usePositionSpring(currentPosition) }}>
        {props.children}
      </List>
    </MenuListContext.Provider>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 3px;
  list-style: none;
  margin: 0;
  min-width: 20rem;
  opacity: 1;
  padding: 0;
  transition: left 200ms ease-in-out;
  transition: opacity 0.05s ease-out;
`;
