import { Descendant, Editor, Element, Node } from 'slate';

interface PlainTextEditor {
  toPlainText: () => string;
}

declare module '../types/CustomEditor' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
  interface CustomEditor extends PlainTextEditor {}
}

export const withToPlainText = (editor: Editor): Editor => {
  editor.toPlainText = () => {
    return toPlainText(editor, editor.children);
  };

  return editor;
};

const toPlainText = (editor: Editor, nodes: Descendant[]): string => {
  let plainText = '';

  nodes.forEach((node) => {
    if (Element.isElement(node) && editor.isBlock(node)) {
      switch (node.type) {
        case 'bulleted-list':
          node.children!.forEach((child) => {
            plainText += '• ' + toPlainText(editor, child.children) + '\n';
          });
          break;
        case 'numbered-list':
          node.children!.forEach((child, index) => {
            plainText += `${index + 1}. ` + toPlainText(editor, child.children) + '\n';
          });
          break;
        default:
          plainText += toPlainText(editor, node.children!) + '\n';
      }
    } else {
      plainText += Node.string(node);
    }
  });

  return plainText;
};
