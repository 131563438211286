import { Site } from '@nucleus/types/web/site';
import { useContext } from 'react';
import { SiteContext } from '../../webTheme';

export const useNavigation = (
  navigationId: keyof Site['navigation'] | undefined
): Site['navigation'][string] | undefined => {
  const { getSite } = useContext(SiteContext);

  if (navigationId === undefined) {
    return;
  }

  const site = getSite();

  return site.navigation[navigationId];
};
