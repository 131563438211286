import React from 'react';
import { Node } from 'slate';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { withPlaceholder } from '../utils/withPlaceholder';
import { cornerStyleInterpolationFactory } from '../../lib/cornerStyle';
import { TextAreaElement as TextAreaElementInterface } from '../types/CustomElement';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    textarea?: {
      background?: string;
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

interface Props extends RenderElementProps {
  element: TextAreaElementInterface;
}

export const TextAreaElement = ({ attributes, children, element }: Props): JSX.Element => {
  return (
    <Card {...attributes}>
      <Content placeholder={element.placeholder} $showPlaceholder={Node.string(element) === ''}>
        {children}
      </Content>
    </Card>
  );
};

const Card = withPlaceholder(styled.div.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.textarea?.className,
}))`
  background: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.textarea?.background};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '12px',
    pill: '12px',
  })};
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.textarea?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.textarea?.color};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.textarea?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.textarea?.letterSpacing};
  margin: 12px 0;
  padding: 18px 36px 18px 18px;
  min-height: 116px;
`);

const Content = withPlaceholder(styled.div``);
