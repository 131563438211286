import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import { NavigationAction } from '@nucleus/sites';
import { MediaWeb, SectionAction, SectionActionType } from '@nucleus/types/web';
import { BrandIdentityWeb } from '@nucleus/types/web/branding';
import { NavigationItemHosted } from '@nucleus/web-hosting';
import { useAuthentication } from '@nucleus/web-theme';
import {
  Text,
  booleanPropHelperFactory,
  buildTransparentColorFromCssVariable,
  conditionalHelperFactory,
  media,
} from '@nucleus/web-theme-elements';
import { Auth } from 'aws-amplify';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useActionProps } from '../hooks/useActionProps';
import { usePreviousState } from '../hooks/usePreviousState';
import { HeaderBlockLayoutProps } from '../sections/Header/HeaderLayout';
import { buildThemeColorsVariationClassName } from '../utils/styles';
import { MediaItemBackground } from './Background';
import { Branding } from './Branding';
import { ButtonPrimary } from './Button';
import { IconAngleLeft } from './Icons';
import { Navbar, NavbarLeft, NavbarRight } from './Navbar';
import { Overlay, OverlayController } from './overlays/Overlay';
import { calculateTransitionType } from './overlays/lib/transitions';

export type NavigationProps = HeaderBlockLayoutProps & { topOffset: number };

export const NavigationMobile = (props: NavigationProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const navigationItems = props.navigation?.items ?? [];

  const brandIdentity = props.blocks[0].brandIdentity ?? {};
  const menuLogoDisplay = { ...brandIdentity, logo: props.blocks[0]?.menuLogo?.logo ?? brandIdentity.logo };

  const renderNavigation = props.navigation !== undefined;

  if (renderNavigation === false) {
    return null;
  }

  return (
    <>
      <NavMenuToggle active={isOpen} onClick={handleToggleMenu} />
      <NavOverlay
        background={props.backgroundMedia}
        brandIdentity={menuLogoDisplay}
        actions={props.blocks[0].buttons}
        isOpen={isOpen}
        items={navigationItems}
        onCloseMenu={handleToggleMenu}
        topOffset={props.topOffset}
        colorPaletteVariation={props.colorPaletteVariation}
      />
    </>
  );
};

const NavMenuToggleContainer = styled.button`
  --nav-menu-toggle-height: clamp(
    calc(1.5 * var(--min-unit-length)),
    calc(0.6 * var(--unit-length)),
    calc(0.55 * var(--max-unit-length))
  );

  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  margin-left: 5em;
  margin-right: calc(0.05 * var(--unit-length));

  cursor: pointer;
  pointer-events: auto;
  color: inherit;

  position: relative;
  height: calc(var(--nav-menu-toggle-height));
  width: calc(2 * var(--nav-menu-toggle-height));
`;

// prettier-ignore
const NavMenuToggleSwitch = styled.div.attrs({ className: Text.ClassName['main-nav-toggle-label'] })<{ active: boolean; }>`
  --nav-menu-toggle-border-thickness: calc(0.1 * var(--nav-menu-toggle-height));

  display: flex;
  flex-direction: row;
  align-items: center;

  border: var(--nav-menu-toggle-border-thickness) solid currentColor;
  border-radius: 100vw;
  height: 100%;
  width: 100%;
  position: relative;

  &:before {
    content: 'Menu';
    color: inherit;
    position: absolute;
    right: calc(100% + 0.5em);
  }

  &:after {
    content: '';
    background-color: currentColor;
    border-radius: 100vw;
    height: calc(0.6 * (var(--nav-menu-toggle-height)));
    width: calc(0.6 * (var(--nav-menu-toggle-height)));
    position: absolute;
    left: calc(0.1 * (var(--nav-menu-toggle-height)));
    transition: transform 0.1s ease-in-out;
  }

  ${({ active }) =>
    active &&
    css`
      &:after {
        transform: translateX(var(--nav-menu-toggle-height));
      }
    `}
`;

const NavMenuToggle = (props: { active: boolean; onClick: () => void }) => {
  return (
    <NavMenuToggleContainer onClick={props.onClick}>
      <NavMenuToggleSwitch active={props.active} />
    </NavMenuToggleContainer>
  );
};

interface NavOverlayContextValue {
  items: Array<NavigationItemHosted>;
  currentPath: string;
  previousPath: string;
  transitionType: string;
}
const NavOverlayContext = React.createContext({} as NavOverlayContextValue);

interface NavOverlayProps {
  background?: MediaWeb;
  colorPaletteVariation?: HeaderBlockLayoutProps['colorPaletteVariation'];
  actions?: Array<SectionAction>;
  isOpen: boolean;
  items?: Array<NavigationItemHosted>;
  onCloseMenu: () => void;
  brandIdentity?: BrandIdentityWeb;
  topOffset?: number;
}

type NavItem = NavigationItemHosted & {
  isParent?: boolean;
};

function parseNavItems(items: Array<NavigationItemHosted> = []): Array<NavItem> {
  const parsedItems = items.map((item) => {
    const parsedItem = Object.assign({}, item) as NavItem;
    if (item.items && item.items.length > 0) {
      parsedItem.isParent = true;
      parsedItem.items = parseNavItems(item.items);
    }
    return parsedItem;
  });
  return parsedItems;
}

const NavOverlay = ({ actions = [], ...props }: NavOverlayProps): JSX.Element => {
  const items = React.useMemo(() => parseNavItems(props.items), [props.items]);
  const defaultPath = '' as string;
  const [[path, direction], setPath] = React.useState([defaultPath, 1]);
  const previousPath = usePreviousState(path) ?? '';
  const currentPath = path; // TODO: validate path or fallback to default
  const isNested = currentPath !== defaultPath;
  const [isOpen, setIsOpen] = useState(false);

  // path is a string of comma separated indexes
  // get current menu from path
  const currentMenu = React.useMemo(() => {
    const pathParts = currentPath.split(',');
    let currentMenu = items;
    for (const pathPart of pathParts) {
      const index = parseInt(pathPart);
      if (currentMenu[index]) {
        currentMenu = currentMenu[index].items as NavItem[];
      }
    }
    return currentMenu;
  }, [currentPath, items]);

  const transitionType = calculateTransitionType(previousPath, currentPath);

  const contextValue = React.useMemo(
    (): NavOverlayContextValue => ({
      items: items,
      currentPath: currentPath,
      previousPath: previousPath,
      transitionType: transitionType,
    }),
    [items, currentPath, previousPath, transitionType]
  );

  const isSubmenu = currentPath !== defaultPath;
  const parentItem = React.useMemo(() => {
    if (isSubmenu !== true) {
      return undefined;
    }
    const pathParts = currentPath.split(',');
    const index = parseInt(pathParts[pathParts.length - 1]);
    return items[index];
  }, [currentPath, items]);

  const handleItemClick = (item: NavItem, index: number) => {
    if (item.isParent) {
      // navigate to submenu
      setPath([`${path},${index}`, 1]);
      return;
    }

    if (item.type === NavigationAction.Page) {
      setIsOpen(false);
      props.onCloseMenu();
      return;
    }
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
    props.onCloseMenu();
  };

  useIsomorphicLayoutEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const navigateBack = React.useCallback(() => {
    // navigate to previous path
    const newPath = currentPath.split(',').slice(0, -1).join(',');

    setPath([newPath, -1]);
  }, [currentPath]);

  interface ItemProps {
    as?: 'a' | typeof NavLink;
    end?: boolean;
    href?: string;
    to?: string;
    target?: '_blank';
  }
  const getItemProps = (item: NavItem): ItemProps => {
    const props: ItemProps = {};

    if (item.type === NavigationAction.Page) {
      props.as = NavLink;
      props.end = true;
      props.to = item.payload.destination;
    } else if (item.type !== NavigationAction.None) {
      props.as = 'a';
      props.href = item.payload.destination;
    }

    if (item.payload.openInNewTab) {
      props.target = '_blank';
    }

    return props;
  };

  return (
    <OverlayController currentPath={currentPath} defaultPath={defaultPath}>
      <NavOverlayContext.Provider value={contextValue}>
        <StyledOverlay
          show={props.isOpen}
          contentWidth="100%"
          topOffset={props.topOffset}
          backdropColor="var(--color-navigation-background)"
          className={buildThemeColorsVariationClassName(props.colorPaletteVariation)}
          shouldRestoreScrollLock={() => !window.location.hash.startsWith('#section_')}
        >
          <Navbar>
            <NavbarLeft>
              <BrandingContainer>
                <Branding brandIdentity={props.brandIdentity ?? {}} onClick={props.onCloseMenu} />
              </BrandingContainer>
            </NavbarLeft>
            <NavbarRight>
              <NavMenuToggle active={isOpen} onClick={handleCloseMenu} />
            </NavbarRight>
          </Navbar>
          <NavOverlayContainer>
            <NavOverlayImageContainer renderDarkOverlay={isNested}>
              <MediaItemBackground background={props.background} />
            </NavOverlayImageContainer>
            <NavOverlayMenuContainer>
              {isSubmenu && <DesktopBackButton size="medium" onClick={navigateBack} />}
              <NavOverlayMenuInner>
                <AnimatePresence initial={false} custom={direction}>
                  <NavOverlayMenu
                    key={currentPath}
                    initial="in"
                    animate="center"
                    exit="out"
                    variants={animationVariants}
                    custom={direction}
                  >
                    {parentItem && (
                      <NavOverlayMenuTitle {...getItemProps(parentItem)}>{parentItem.title}</NavOverlayMenuTitle>
                    )}
                    {currentMenu.map((item, index) => {
                      if (isSubmenu === true) {
                        return (
                          <NavOverlayMenuSubItem
                            key={item.id}
                            onClick={() => handleItemClick(item, index)}
                            {...(!item.isParent ? getItemProps(item) : {})}
                          >
                            {item.title}
                          </NavOverlayMenuSubItem>
                        );
                      }
                      return (
                        <NavOverlayMenuItem
                          key={item.id}
                          onClick={() => handleItemClick(item, index)}
                          {...(!item.isParent ? getItemProps(item) : {})}
                        >
                          {item.title}
                        </NavOverlayMenuItem>
                      );
                    })}
                  </NavOverlayMenu>
                </AnimatePresence>
              </NavOverlayMenuInner>
              <NavOverlayMenuFooter>
                <NavOverlayMenuFooterButtons>
                  {isSubmenu && <MobileBackButton onClick={navigateBack} />}
                  {actions.map((action) => (
                    <NavigationActionItem key={action.id} action={action} />
                  ))}
                </NavOverlayMenuFooterButtons>
              </NavOverlayMenuFooter>
            </NavOverlayMenuContainer>
          </NavOverlayContainer>
        </StyledOverlay>
      </NavOverlayContext.Provider>
    </OverlayController>
  );
};

interface NavigationActionItemProps {
  action: SectionAction;
}
const NavigationActionItem = (props: NavigationActionItemProps): JSX.Element => {
  const [isLoggedIn] = useAuthentication();

  const handleLogout = useCallback((): void => {
    Auth.signOut();
  }, []);

  const actionProps = useActionProps(props.action);

  if (isLoggedIn === true && props.action.type === SectionActionType.SignIn) {
    return (
      <ButtonPrimary widthMode="full" onClick={handleLogout}>
        Sign Out
      </ButtonPrimary>
    );
  }

  return (
    <ButtonPrimary widthMode="full" {...actionProps}>
      {props.action.title}
    </ButtonPrimary>
  );
};

const hasTopOffset = conditionalHelperFactory((props: NavOverlayProps) => props.topOffset !== undefined);

const StyledOverlay = styled(Overlay)`
  && {
    ${hasTopOffset(css<{ topOffset?: number }>`
      top: ${(props) => props.topOffset + 'px'};
      height: calc(100% - ${(props) => props.topOffset + 'px'});
    `)}
    --color-logo: var(--color-logo-navigation);
  }

  ${Navbar} {
    color: var(--color-navigation-text);
  }
`;

const ComponentHasLinkProp = (props: any) => 'href' in props || 'to' in props;

const BackButton = styled(ButtonPrimary)`
  flex: 0 0 auto;
`;
BackButton.defaultProps = {
  icon: <IconAngleLeft />,
  shape: 'square',
};

const DesktopBackButton = styled(BackButton)`
  position: absolute;
  z-index: 110;
  top: 3.8rem;
  left: 3rem;

  ${media.tabletPortraitAndDown`
      display: none;
    `}
`;

const MobileBackButton = styled(BackButton)`
  ${media.tabletLandscapeAndUp`
      display: none;
    `}
`;

const renderDarkOverlay = booleanPropHelperFactory('renderDarkOverlay');

const NavOverlayContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const NavOverlayImageContainer = styled.div<{ renderDarkOverlay: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  ${renderDarkOverlay(css`
    &::after {
      opacity: 0.5;
      transition: opacity 0.3s ease-out;
    }
  `)}

  ${media.tabletLandscapeAndUp`
    width: 50%;
    display: block;
  `}
`;

const NavOverlayMenuContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletLandscapeAndUp`
    width: 50%;
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    background-image: linear-gradient(
      0deg,
      ${buildTransparentColorFromCssVariable('--color-navigation-background', 0)} 0%,
      ${buildTransparentColorFromCssVariable('--color-navigation-background', 0.85)} 50%,
      var(--color-navigation-background) 92%
    );
    pointer-events: none;
    z-index: 1;
  }
`;

const NavOverlayMenuInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0px 2rem;
  -webkit-overflow-scrolling: touch;
  // Hide scrollbar
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavOverlayMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 16rem 25% 20rem;
  min-height: 100%;
  position: absolute;
  top: 0px;
  flex: 1 1 auto;
  gap: 0.6rem;
`;

const NavOverlayMenuTitle = styled.div.attrs({
  className: Text.ClassName['main-nav-item-label'],
})`
  cursor: default;
  opacity: 0.25;
  margin-bottom: 1.5rem;
  color: var(--color-navigation-text);
  text-decoration: underline;

  ${(props) => {
    if (ComponentHasLinkProp(props)) {
      return css`
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      `;
    }
  }}
`;

const NavOverlayMenuItem = styled.div.attrs({
  className: Text.ClassName['main-nav-item-label'],
})`
  opacity: 0.75;
  cursor: pointer;
  position: relative;
  padding: 1rem 0;
  color: var(--color-navigation-text);
  text-decoration: none;

  // add dot to left of active item
  &.active {
    &::before {
      content: '';
      display: inline-block;
      width: 0.35em;
      height: 0.35em;
      border-radius: 0.35em;
      background: var(--color-navigation-text);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -0.85em;
    }
  }

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
`;

const NavOverlayMenuSubItem = styled.div.attrs({
  className: Text.ClassName['main-nav-subitem-label'],
})`
  cursor: default;
  opacity: 0.75;
  position: relative;
  padding: 1rem 0;
  color: var(--color-navigation-text);
  text-decoration: none;

  // add dot to left of active item
  &.active {
    &::before {
      content: '';
      display: inline-block;
      width: 0.35em;
      height: 0.35em;
      border-radius: 0.35em;
      background: var(--color-navigation-text);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -0.85em;
    }
  }

  ${(props) => {
    if (ComponentHasLinkProp(props)) {
      return css`
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      `;
    }
  }}
`;

const NavOverlayMenuFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    background-image: linear-gradient(
      180deg,
      ${buildTransparentColorFromCssVariable('--color-navigation-background', 0)} 0%,
      ${buildTransparentColorFromCssVariable('--color-navigation-background', 0.85)} 50%,
      var(--color-navigation-background) 92%
    );
  }
`;

const NavOverlayMenuFooterButtons = styled.div`
  display: flex;
  padding: 3rem;
  gap: 1.5rem;
  pointer-events: auto;
`;

const animationVariants = {
  in: (direction: number) => {
    return {
      x: direction > 0 ? '50%' : '-50%',
      opacity: 0,
      transition: {
        type: 'spring',
        delay: 0,
        stiffness: 1000,
        damping: 55,
        mass: 0.5,
      },
    };
  },
  center: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 1000,
      damping: 55,
      mass: 0.5,
    },
  },
  out: (direction: number) => {
    return {
      x: direction < 0 ? '25%' : '-25%',
      opacity: 0,
      transition: {
        type: 'spring',
        delay: 0,
        stiffness: 1000,
        damping: 55,
        mass: 0.5,
      },
    };
  },
};

const BrandingContainer = styled.div`
  height: var(--header-content-height);
`;
