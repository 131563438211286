import { isEqual as _isEqual } from 'lodash';
import { useCallback, useRef, useSyncExternalStore } from 'react';

export const useLocalStorageState = <T>(key: string): [T | undefined, (value: T) => void, () => void] => {
  const ref = useRef<T>();

  const subscribe = useCallback((notify: () => void) => {
    const listener = (event: StorageEvent) => event.key === key && notify();
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  const getSnapshot = useCallback((): T | undefined => {
    const item = localStorage.getItem(key);

    if (item === null) {
      return;
    }

    let parsed: T | undefined;

    try {
      parsed = JSON.parse(item);
    } catch (error) {
      return;
    }

    if (_isEqual(ref.current, parsed)) {
      return ref.current;
    }

    ref.current = parsed;
    return parsed;
  }, []);

  const getServerSnapshot = useCallback((): T | undefined => {
    return;
  }, []);

  const state = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  const setState = useCallback((value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new StorageEvent('storage', { key: key }));
  }, []);

  const deleteState = useCallback(() => {
    localStorage.removeItem(key);
    window.dispatchEvent(new StorageEvent('storage', { key: key }));
    ref.current = undefined;
  }, []);

  return [state, setState, deleteState];
};
