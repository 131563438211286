/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useSpring } from 'react-spring';

export type Position = 'left' | 'center' | 'right';

/** Slide left coming in, and fade right going out */
export const usePositionSpring = (position: Position) => {
  const next = React.useCallback((position: Position) => {
    switch (position) {
      case 'left': {
        return {
          x: '-100%',
          opacity: 1,
          display: 'none',
        };
      }

      case 'center': {
        return {
          x: '0%',
          opacity: 1,
          display: 'flex',
        };
      }

      case 'right':
      default: {
        return {
          x: '100%',
          opacity: 0,
          display: 'none',
        };
      }
    }
  }, []);

  return useSpring({
    to: next(position),
    config: {
      max: 0.5,
      tension: 1000,
      friction: 55,
    },
  });
};
