import { SocialMediaPlatform } from '@nucleus/types/contact';
import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

export const IconSocialFacebook = styled((props) => (
  <Icon viewBox="0 0 46 46" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        fillRule="nonzero"
        d="M24.733 10.289a5.2 5.2 0 0 0-5.2 5.2v4.044h-2.889a.578.578 0 0 0-.577.578v4.622c0 .32.258.578.577.578h2.89v9.822c0 .32.258.578.577.578h4.622a.578.578 0 0 0 .578-.578v-9.822H28.2c.265 0 .496-.18.56-.437l1.156-4.623a.578.578 0 0 0-.56-.718H25.31V17.8c0-.957.776-1.733 1.733-1.733h2.312a.578.578 0 0 0 .577-.578v-4.622a.578.578 0 0 0-.577-.578h-4.623Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialTwitterX = styled((props) => (
  <Icon viewBox="0 0 1200 1226" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="600" cy="613" r="600" />
      <path
        transform="scale(0.50) translate(600, 600)"
        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
        fill="var(--color-section-background)"
        fillRule="nonzero"
      />
    </g>
  </Icon>
))``;

export const IconSocialInstagram = styled((props) => (
  <Icon viewBox="0 0 46 46" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="M17.4 9A8.4 8.4 0 0 0 9 17.4v11.2a8.4 8.4 0 0 0 8.4 8.4h11.2a8.4 8.4 0 0 0 8.4-8.4V17.4A8.4 8.4 0 0 0 28.6 9H17.4ZM12 17.702C12 14.29 14.289 12 17.702 12h10.596C31.71 12 34 14.289 34 17.702v10.596C34 31.71 31.711 34 28.298 34H17.702C14.29 34 12 31.711 12 28.298V17.702Zm11-1.858a7.156 7.156 0 1 1 0 14.312 7.156 7.156 0 0 1 0-14.312Zm0 2.656a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm9.19-2.95a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialYoutube = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="M9.465 17.616A69.989 69.989 0 0 0 9.25 23c0 2.04.1 3.958.228 5.55.182 2.272 1.913 4.007 4.146 4.162 2.243.156 5.382.288 9.376.288 3.994 0 7.133-.132 9.376-.288 2.233-.155 3.964-1.89 4.146-4.162.128-1.592.228-3.51.228-5.55 0-1.97-.093-3.825-.215-5.384-.183-2.352-2.014-4.16-4.325-4.324C29.99 13.134 26.955 13 23 13c-3.955 0-6.99.134-9.21.292-2.311.164-4.142 1.972-4.325 4.324ZM27.375 23 20.5 19.25v7.5L27.375 23Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialLinkedin = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="M14.727 15.455a2.727 2.727 0 1 0 0-5.455 2.727 2.727 0 0 0 0 5.455ZM17.455 34V17.636H12V34h5.455Zm2.181-16.364h4.91v2.123c.781-1.026 2.45-2.123 5.454-2.123 4.724 0 6 4.714 6 7.637V34h-5.455v-8.727C30.545 24.182 30 22 27.818 22c-1.55 0-2.64 1.1-3.273 2.129V34h-4.909V17.636Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialPinterest = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="M12.52 18.64c.46-4.564 4.624-9.14 10.528-9.14 4.084 0 6.941 1.548 8.646 3.904 1.644 2.274 2.05 5.07 1.772 7.362-.24 1.994-.871 4.33-2.152 6.167-1.337 1.918-3.454 3.346-6.385 3.053-1.476-.148-2.573-.721-3.375-1.46a438.776 438.776 0 0 1-.86 2.51 222.513 222.513 0 0 0-1.416 4.186 1.84 1.84 0 0 1-3.505-1.127c.404-1.257.914-2.737 1.441-4.265l.25-.725a188.057 188.057 0 0 0 1.83-5.517 17.662 17.662 0 0 1-.28-.6c-.158-.36-.33-.785-.465-1.214-.126-.4-.264-.938-.264-1.483 0-1.11.259-2.211.973-3.03a3.008 3.008 0 0 1 2.958-.968c1.447.318 1.737 1.684 1.795 2.148.076.6.008 1.272-.09 1.88-.152.937-.44 2.078-.794 3.301a4.516 4.516 0 0 0 .319 1.3c.302.72.79 1.294 1.85 1.4 1.32.132 2.246-.416 2.998-1.495.81-1.16 1.316-2.841 1.517-4.503.194-1.6-.12-3.407-1.1-4.762-.92-1.273-2.594-2.38-5.663-2.38-3.914 0-6.584 3.043-6.865 5.828-.194 1.918.235 3.259.662 3.882a1.84 1.84 0 1 1-3.039 2.079c-1.04-1.52-1.543-3.793-1.286-6.331Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialTikTok = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        fillRule="nonzero"
        d="M27.727 10h-4.136v18.318c0 1.773-1.773 3.546-3.546 3.546-1.772 0-3.545-.591-3.545-3.546 0-2.363 2.244-3.945 4.136-3.545v-4.137c-7.231 0-8.272 5.91-8.272 7.682 0 1.773 1.154 7.682 7.681 7.682 5.345 0 7.682-4.136 7.682-7.09V18.272c1.355 1.203 3.454 1.604 5.91 1.772V15.91c-3.566 0-5.91-3.136-5.91-5.909Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialReddit = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="m29.093 13.516-4.03-.896-.934 5.607c2.794.171 5.349.974 7.33 2.228a3.526 3.526 0 1 1 4.004 5.706c.024.225.037.454.037.685 0 2.546-1.548 4.733-3.808 6.24C29.426 34.596 26.35 35.5 23 35.5c-3.35 0-6.426-.903-8.692-2.414-2.26-1.507-3.808-3.694-3.808-6.24 0-.23.013-.46.038-.686a3.526 3.526 0 1 1 4.004-5.706c2.054-1.3 4.725-2.115 7.64-2.244l1.152-6.907a.961.961 0 0 1 1.157-.78l5.163 1.147a2.885 2.885 0 1 1-.561 1.845Zm-11.221 13.33a1.923 1.923 0 1 0 0-3.846 1.923 1.923 0 0 0 0 3.846Zm12.18-1.923a1.923 1.923 0 1 1-3.847 0 1.923 1.923 0 0 1 3.846 0Zm-10.74 4.595a.962.962 0 1 0-1.6 1.067c.393.588 1.122.998 1.949 1.263.864.278 1.982.447 3.339.447s2.475-.169 3.34-.447c.825-.265 1.555-.675 1.947-1.263a.962.962 0 1 0-1.6-1.067c-.035.053-.267.284-.936.5-.632.202-1.544.354-2.751.354-1.207 0-2.12-.152-2.75-.355-.67-.215-.902-.446-.937-.499Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialWhatsApp = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        d="M10.289 23c0-7.02 5.69-12.711 12.711-12.711 7.02 0 12.711 5.69 12.711 12.711 0 7.02-5.69 12.711-12.711 12.711-2.237 0-4.341-.578-6.168-1.594l-6.08 1.132 1.131-6.08A12.656 12.656 0 0 1 10.29 23Zm7.103-6.186c.756-.757 1.765-.823 2.309-.798.503.024.896.315 1.137.652l1.1 1.542c.492.69.414 1.634-.185 2.232l-.779.779c.257.629.775 1.64 1.47 2.335.696.695 1.707 1.213 2.335 1.47l.779-.778a1.733 1.733 0 0 1 2.232-.186l1.542 1.1c.337.241.629.635.652 1.138.025.544-.041 1.552-.798 2.309-1.232 1.232-3.167 1.245-4.926.762-1.792-.492-3.618-1.55-4.85-2.781-1.231-1.232-2.288-3.058-2.78-4.85-.483-1.759-.47-3.694.762-4.926Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialSnapchat = styled((props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx="23" cy="23" r="23" />
      <path
        fill="var(--color-section-background)"
        fillRule="nonzero"
        d="M18.292 11.943c1.585-1.347 3.471-1.654 4.708-1.654s3.123.307 4.708 1.654c1.484 1.262 2.621 3.364 2.783 6.745l.342-.137a2.655 2.655 0 0 1 2.257 4.793l-2.348 1.077c.309.467.807 1.066 1.436 1.695.868.867 1.92 1.726 2.915 2.295.349.199.653.523.779.918.138.434.042.927-.379 1.274-.632.521-1.598.939-2.724 1.04-.092.448-.303.808-.631 1.073-.423.34-.96.454-1.427.5-.352.033-.737.033-1.075.033h-.31c-.445.005-.732.035-.902.106-.232.097-.514.272-.88.515l-.219.146c-.3.2-.637.426-.996.64-.9.538-2.02 1.055-3.329 1.055-1.31 0-2.428-.517-3.33-1.055-.358-.214-.695-.44-.995-.64l-.22-.146c-.365-.243-.647-.418-.879-.515-.17-.071-.457-.1-.902-.106h-.31c-.338 0-.723 0-1.075-.034-.468-.045-1.004-.158-1.427-.499-.328-.265-.54-.625-.631-1.073-1.126-.101-2.092-.519-2.724-1.04a1.154 1.154 0 0 1-.379-1.274c.126-.395.43-.719.78-.918.995-.569 2.046-1.428 2.914-2.295.629-.629 1.127-1.228 1.436-1.695l-2.348-1.077a2.655 2.655 0 0 1 2.257-4.793l.342.137c.162-3.38 1.3-5.483 2.783-6.745Z"
      />
    </g>
  </Icon>
))``;

export const IconSocialMap: Record<SocialMediaPlatform, React.ReactNode> = {
  [SocialMediaPlatform.Facebook]: <IconSocialFacebook />,
  [SocialMediaPlatform.Instagram]: <IconSocialInstagram />,
  [SocialMediaPlatform.Twitter]: <IconSocialTwitterX />,
  [SocialMediaPlatform.YouTube]: <IconSocialYoutube />,
  [SocialMediaPlatform.LinkedIn]: <IconSocialLinkedin />,
  [SocialMediaPlatform.Pinterest]: <IconSocialPinterest />,
  [SocialMediaPlatform.TikTok]: <IconSocialTikTok />,
  [SocialMediaPlatform.Reddit]: <IconSocialReddit />,
  [SocialMediaPlatform.WhatsApp]: <IconSocialWhatsApp />,
  [SocialMediaPlatform.Snapchat]: <IconSocialSnapchat />,
};
