import { mustacheRenderer } from '@nucleus/src/template/mustache';
import { ImageWeb } from '@nucleus/types/media/image';
import { PageWeb, Site } from '@nucleus/types/web';
import { SharingMetadataItemWeb } from '@nucleus/types/web/sharing';
import { kebabCase as _kebabCase, sortBy } from 'lodash';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataResourceContext } from '../data/dataResourceContext';
import { SiteContext } from '../site/SiteContext';

type CamelToKebabCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '-' : ''}${Lowercase<T>}${CamelToKebabCase<U>}`
  : S;

type CamelToKebabCaseKeys<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T as CamelToKebabCase<K & string>]: T[K];
    }
  : T;

const kebabKeys = <T extends Record<string, unknown>>(obj: T): CamelToKebabCaseKeys<T> =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [_kebabCase(key)]: obj[key],
    }),
    {}
  ) as CamelToKebabCaseKeys<T>;

export const DataResourceSeoTags = (): JSX.Element | null => {
  const { getSite } = useContext(SiteContext);
  const { page } = useContext(DataResourceContext);

  if (page === undefined) {
    return null;
  }

  const site = getSite();

  return <PageSeoTags page={page} site={site} />;
};

interface Props {
  page: PageWeb;
  site: Site;
  parameters?: Record<string, string>;
}

export const PageSeoTags = ({ page, site, parameters }: Props): JSX.Element => {
  const renderer = mustacheRenderer();

  const baseRendererParams: Record<string, string> = {
    ...site.parameters,
    ...parameters,
    pageTitle: page.title ?? '',
  };

  const kebabRenderer = (value: string): string =>
    renderer(value, { ...baseRendererParams, ...kebabKeys(baseRendererParams) });

  return (
    <>
      <SEOTags site={site} page={page} titleRenderer={kebabRenderer} />
      <FacebookTags site={site} page={page} titleRenderer={kebabRenderer} />
      <TwitterTags site={site} page={page} titleRenderer={kebabRenderer} />
    </>
  );
};

interface SEOTagProps {
  page: PageWeb;
  site: Site;
  titleRenderer: ReturnType<typeof mustacheRenderer>;
}

const FacebookTags = ({ page, site, titleRenderer }: SEOTagProps): JSX.Element => {
  const entityMetadata = page.sharingMetadata?.entity;
  const facebookMetadata = page.sharingMetadata?.facebook;
  const siteMetadata = site.siteConfig.sharingMetadata?.entity;

  const getAttribute = <T extends keyof SharingMetadataItemWeb>(
    attribute: T
  ): SharingMetadataItemWeb[T] | undefined => {
    const value = facebookMetadata?.enabled === true ? facebookMetadata?.[attribute] : undefined;

    return value ?? entityMetadata?.[attribute] ?? siteMetadata?.[attribute];
  };

  const baseTitleText = getAttribute('title');
  const descriptionText = getAttribute('description');
  const baseImage = getAttribute('image');

  const titleText = titleRenderer(baseTitleText ?? site.siteConfig.title);

  const image = getImageSrcByMaxDimensions(baseImage);

  const renderDescription = descriptionText !== undefined;
  const renderImage = image !== undefined;
  const renderDimensions = renderImage === true && image?.dimensions !== undefined;

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleText} />
      {renderDescription && <meta property="og:description" content={descriptionText} />}
      {renderImage && <meta property="og:image" content={image?.src} />}
      {renderDimensions && <meta property="og:image:width" content={`${image.dimensions?.width}`} />}
      {renderDimensions && <meta property="og:image:height" content={`${image.dimensions?.height}`} />}
    </Helmet>
  );
};

const TwitterTags = ({ page, site, titleRenderer }: SEOTagProps): JSX.Element => {
  const entityMetadata = page.sharingMetadata?.entity;
  const twitterMetadata = page.sharingMetadata?.twitter;
  const siteMetadata = site.siteConfig.sharingMetadata?.entity;

  const getAttribute = <T extends keyof SharingMetadataItemWeb>(
    attribute: T
  ): SharingMetadataItemWeb[T] | undefined => {
    const value = twitterMetadata?.enabled === true ? twitterMetadata?.[attribute] : undefined;

    return value ?? entityMetadata?.[attribute] ?? siteMetadata?.[attribute];
  };

  const baseTitleText = getAttribute('title');
  const descriptionText = getAttribute('description');
  const baseImage = getAttribute('image');

  const image = getImageSrcByMaxDimensions(baseImage);

  const titleText = titleRenderer(baseTitleText ?? site.siteConfig.title);

  const renderDescription = descriptionText !== undefined;
  const renderImage = image !== undefined;

  const cardType = renderImage ? 'summary_large_image' : 'summary';

  return (
    <Helmet>
      <meta name="twitter:card" content={cardType} />
      <meta name="twitter:title" content={titleText} />
      {renderDescription && <meta name="twitter:description" content={descriptionText} />}
      {renderImage && <meta name="twitter:image" content={image?.src} />}
    </Helmet>
  );
};

const SEOTags = ({ page, site, titleRenderer }: SEOTagProps): JSX.Element => {
  const entityMetadata = page.sharingMetadata?.entity;
  const siteMetadata = site.siteConfig.sharingMetadata?.entity;

  const titleText = titleRenderer(entityMetadata?.title ?? site.siteConfig.title);
  const descriptionText = entityMetadata?.description ?? siteMetadata?.description;
  const keyWords = entityMetadata?.keywords ?? siteMetadata?.keywords ?? [];

  const renderDescription = descriptionText !== undefined;
  const renderKeywords = keyWords.length > 0;
  const renderNoIndex = entityMetadata?.noindex === true;

  return (
    <Helmet>
      <title>{titleText}</title>
      {renderDescription && <meta name="description" content={descriptionText} />}
      {renderKeywords && <meta name="keywords" content={keyWords?.join(', ')} />}
      {renderNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

const getImageSrcByMaxDimensions = (image?: ImageWeb, maxWidth = 1500, maxHeight = 1500) => {
  if (image?.srcSet === undefined) {
    return image;
  }

  return sortBy(image.srcSet, ['dimensions.width', 'dimensions.height'])
    .filter((src) => src.dimensions.width <= maxWidth && src.dimensions.height <= maxHeight)
    .reverse()[0];
};
