import { memoizePromise } from '@nucleus/lib-function';
import { safeToArray } from '@nucleus/lib-shape';
import { ParameterNotFoundError } from '../error/parameter-not-found-error';
import { ParameterStoreRepository } from '../type/repository';
import { ParameterStoreService } from '../type/service';

const fallbackTTLInMS = 1000 * 60 * 5; // 5 minutes
const fallbackNameGenerator = (parts: Array<string> | string) => '/' + safeToArray(parts).join('/');

interface ParameterStoreServiceOptions {
  nameGenerator?: (parts: Array<string> | string) => string;
  defaultTTLInMS?: number;
  allowNotFound?: boolean;
}

export const newParameterStoreService = (
  repository: ParameterStoreRepository,
  options: ParameterStoreServiceOptions = {}
): ParameterStoreService => {
  const { nameGenerator = fallbackNameGenerator, defaultTTLInMS = fallbackTTLInMS } = options;

  const getParameterName = (name: Array<string> | string): string => nameGenerator(name);

  const getParameter = async <T>(name: Array<string> | string, fallback?: T): Promise<string | T> => {
    const generatedName = nameGenerator(name);
    const response = await repository.get(generatedName);

    if (response !== undefined) {
      return response;
    }

    if (fallback === undefined) {
      if (options.allowNotFound === true) {
        return undefined as unknown as T;
      }
      throw new ParameterNotFoundError(generatedName);
    }

    return fallback;
  };

  const getMemoizedParameter = async <T>(
    name: Array<string> | string,
    fallback?: T,
    options: Parameters<typeof memoizePromise>[1] = {}
  ): Promise<string | T> => {
    return await memoizePromise(getParameter, {
      maxAge: defaultTTLInMS,
      ...options,
    })(name, fallback);
  };

  const putParameter = async (name: Array<string> | string, value: string): Promise<void> => {
    const generatedName = nameGenerator(name);
    await repository.put(generatedName, value);
  };

  return {
    getParameterName: getParameterName,
    getParameter: getParameter,
    getMemoizedParameter: getMemoizedParameter,
    putParameter: putParameter,
  };
};
