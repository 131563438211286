import React from 'react';
import { Node } from 'slate';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { BodyElement as BodyElementInterface } from '../types/CustomElement';
import { withPlaceholder } from '../utils/withPlaceholder';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    body?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

interface Props extends RenderElementProps {
  element: BodyElementInterface;
}

export const BodyElement = ({ attributes, children, element }: Props): JSX.Element => {
  return (
    <Body placeholder={element.placeholder} $showPlaceholder={Node.string(element) === ''} {...attributes}>
      {children}
    </Body>
  );
};

const Body = withPlaceholder(styled.div.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.body?.className,
}))`
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.body?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.body?.color};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.body?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.body?.letterSpacing};
  margin: 0;
`);
