import { Editor, Range, Transforms } from 'slate';

export const withInsertBreak = (editor: Editor): Editor => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    if (editor.selection && Range.includes(editor.selection, [0])) {
      Transforms.select(editor, Editor.last(editor, [])[1]);
      Transforms.collapse(editor, { edge: 'end' });
      return;
    }

    insertBreak();
  };

  return editor;
};
