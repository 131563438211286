import { SectionDataWeb } from '@nucleus/types/web';
import React, { useContext } from 'react';
import { MarkupComponentMap, MarkupRegistryContext } from './MarkupRegistryContext';

interface MarkupRegistryProviderProps {
  children: React.ReactNode;
  sectionMap?: MarkupComponentMap;
  sectionRenderErrorData?: SectionDataWeb;
}

export const MarkupRegistryProvider = ({
  sectionMap = {} as MarkupComponentMap,
  ...props
}: MarkupRegistryProviderProps): JSX.Element => {
  const { getComponentMap } = useContext<MarkupRegistryContext>(MarkupRegistryContext);

  const existingComponents = getComponentMap?.();

  const map = {
    ...existingComponents,
    ...sectionMap,
  };

  const context: MarkupRegistryContext = {
    getComponentMap: () => map,
    register: (key, markupComponent) => {
      map[key] = markupComponent;
    },
    getByType: (key) => map[key],
    sectionRenderErrorData: props.sectionRenderErrorData,
  };

  return <MarkupRegistryContext.Provider value={context}>{props.children}</MarkupRegistryContext.Provider>;
};
