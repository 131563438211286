/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'lodash';

type AnyFunction = (...args: any[]) => any;
type DefaultReturn<T extends { [key: string]: AnyFunction }, R> = Extract<keyof T, 'default'> extends never
  ? R
  : T['default'];

const noOpFunction = () => undefined;

export function getFunction<T extends { [key: string]: AnyFunction }>(
  map: T,
  key: (string & keyof T) | string
): NonNullable<T[keyof T]> | DefaultReturn<T, typeof noOpFunction>;

export function getFunction<T extends { [key: string]: AnyFunction }, R extends AnyFunction>(
  map: T,
  key: (string & keyof T) | string,
  defaultFn: R
): NonNullable<T[keyof T]> | DefaultReturn<T, R>;

export function getFunction<T extends { [key: string]: AnyFunction }>(
  map: T,
  key: (string & keyof T) | string,
  defaultFn = noOpFunction
): NonNullable<T[keyof T]> | DefaultReturn<T, typeof defaultFn> {
  return get(map, key, get(map, 'default', defaultFn)) as NonNullable<T[keyof T]> | DefaultReturn<T, typeof defaultFn>;
}
