import { ThemeFieldConfigBuilder } from '../ThemeConfigBuilder';

export const overline = new ThemeFieldConfigBuilder('overline')
  .setStyles(['label1', 'label2', 'label3', 'label4', 'label5', 'label6'])
  .build();

export const headline = new ThemeFieldConfigBuilder('headline')
  .setStyles([
    { label: 'Headline 1', styleId: 'headline1', elementType: 'heading-one' },
    { label: 'Headline 2', styleId: 'headline2', elementType: 'heading-two' },
    { label: 'Headline 3', styleId: 'headline3', elementType: 'heading-three' },
    { label: 'Headline 4', styleId: 'headline4', elementType: 'heading-four' },
    { label: 'Headline 5', styleId: 'headline5', elementType: 'heading-five' },
    { label: 'Headline 6', styleId: 'headline6', elementType: 'heading-six' },
  ])
  .build();

export const byline = new ThemeFieldConfigBuilder('byline')
  .setStyles(['label1', 'label2', 'label3', 'label4', 'label5', 'label6'])
  .build();

export const body = new ThemeFieldConfigBuilder('body')
  .setStyles(['paragraph1', 'paragraph2', 'paragraph3', 'paragraph4'])
  .build();
