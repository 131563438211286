import { SermonIndexSpeaker } from '@nucleus/sermons/types/Sermon';
import { slugifyString } from '@nucleus/src-platform/data/text';
import { SpeakerWeb } from '@nucleus/types/web/sermons/speaker';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { useLocalization } from './useLocalization';

export const useSpeakerPath = (basePath: string): ((speaker: SpeakerWeb | SermonIndexSpeaker) => string) => {
  const localize = useLocalization();

  return useCallback(
    (speaker: SpeakerWeb | SermonIndexSpeaker) => {
      return generatePath(':basePath/:speakers/:sermonSlug', {
        basePath: basePath,
        speakers: slugifyString(localize('speakers', 'speakers')),
        sermonSlug: speaker.slug,
      });
    },
    [basePath]
  );
};
