import React from 'react';
import { Node } from 'slate';
import { RenderElementProps } from 'slate-react';
import styled, { StyledComponentPropsWithRef } from 'styled-components';
import { BaseElement, ParagraphElement as ParagraphElementInterface } from '../types/CustomElement';
import { withPlaceholder } from '../utils/withPlaceholder';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    paragraph?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

interface Props extends RenderElementProps, StyledComponentPropsWithRef<typeof P> {
  element: ParagraphElementInterface | BaseElement;
}

export const ParagraphElement = ({ attributes, children, element, ...props }: Props): JSX.Element => {
  return (
    <P {...useParagraphProps(element)} $showPlaceholder={Node.string(element) === ''} {...attributes} {...props}>
      {children}
    </P>
  );
};

const useParagraphProps = (element: ParagraphElementInterface | BaseElement) => {
  if (element.type !== 'paragraph') {
    return {};
  }

  return {
    contentEditable: element.editable,
    placeholder: element.placeholder,
  };
};

const P = withPlaceholder(styled.p.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.className,
}))`
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.color};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.letterSpacing};
  margin: 0;
  margin-bottom: 15px;
`);
