import { ColorConfig, ColorPaletteColors, ColorPaletteKey } from '@nucleus/types/web';
import {
  BaseThemeColorKey,
  BaseThemeColors,
  CONTRAST_CHECKS,
  GlobalColorValues,
  TIER_1_DERIVED_THEME_COLORS,
  TIER_2_DERIVED_THEME_COLORS,
  TIER_3_DERIVED_THEME_COLORS,
  ThemeColorKey,
  ThemeColorsCssVariablesByVariation,
  ThemeColorsVariations,
  Tier1DerivedThemeColorKey,
  Tier1DerivedThemeColors,
  Tier2DerivedThemeColorKey,
  Tier2DerivedThemeColors,
  Tier3DerivedThemeColors,
} from '@nucleus/web-theme-elements';
import ColorApi from 'color';

export function buildThemeColorsVariationClassName(variationKey?: string): string {
  if (variationKey === undefined) {
    return '';
  }
  return `theme-colors-${variationKey}`;
}

export function getThemeColorsVariations(colorPalette: ColorPaletteColors): ThemeColorsVariations {
  const variations = Object.entries(ThemeColorsVariations).reduce((acc, [variationKey, variationThemeColors]) => {
    const themeColors = Object.entries(variationThemeColors).reduce((acc, [themeColorKey, themeColor]) => {
      // apply contrast checks
      const contrastCheck = CONTRAST_CHECKS[themeColorKey as BaseThemeColorKey];

      return {
        ...acc,
        [themeColorKey]: contrastCheck
          ? ensureContrast(
              themeColor,
              { ...variationThemeColors, ...acc }[contrastCheck[0]],
              colorPalette,
              contrastCheck[1]
            )
          : themeColor,
      };
    }, {} as BaseThemeColors);

    return {
      ...acc,
      [variationKey]: themeColors,
    };
  }, {} as ThemeColorsVariations);

  return variations;
}

// CSS Generation
// -----------------------------------------------

function getColorPaletteCssVariables(colorPalette: ColorPaletteColors): Record<string, string | number> {
  return Object.entries(colorPalette).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`--${key}-h`]: value.h,
      [`--${key}-s`]: value.s,
      [`--${key}-l`]: value.l,
      [`--${key}-a`]: value.a ?? 100,
    }),
    {}
  );
}

function getAllBaseThemeColorsCssVariables(themeColors: BaseThemeColors) {
  return Object.entries(themeColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...buildBaseThemeColorCssVariables(key, value),
    }),
    {}
  );
}

function buildBaseThemeColorCssVariables(newKey: string, originalKey: ColorPaletteKey) {
  return {
    [`--color-${newKey}-h`]: `calc(var(--${originalKey}-h) + var(--global-hue-offset))`,
    [`--color-${newKey}-s`]: `calc(var(--${originalKey}-s) * var(--global-saturation-multiplier))`,
    [`--color-${newKey}-l`]: `calc(var(--${originalKey}-l) * var(--global-lightness-multiplier))`,
    [`--color-${newKey}-a`]: `var(--${originalKey}-a)`,
    [`--color-${newKey}`]: `hsl(var(--color-${newKey}-h), calc(var(--color-${newKey}-s) * 1%), calc(var(--color-${newKey}-l) * 1%), calc(var(--color-${newKey}-a) * 1%))`,
  };
}

function getAllTier1DerivedThemeColorsCssVariables(derivedThemeColors: Tier1DerivedThemeColors) {
  return Object.entries(derivedThemeColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...buildTier1DerivedThemeColorCssVariables(key, value),
    }),
    {}
  );
}

function buildTier1DerivedThemeColorCssVariables(newKey: string, originalKey: BaseThemeColorKey) {
  return {
    [`--color-${newKey}-h`]: `var(--color-${originalKey}-h)`,
    [`--color-${newKey}-s`]: `var(--color-${originalKey}-s)`,
    [`--color-${newKey}-l`]: cssCalcDynamicLightnessShift(originalKey),
    [`--color-${newKey}-a`]: `var(--color-${originalKey}-a)`,
    [`--color-${newKey}`]: `hsl(var(--color-${newKey}-h), calc(var(--color-${newKey}-s) * 1%), calc(var(--color-${newKey}-l) * 1%), calc(var(--color-${newKey}-a) * 1%))`,
  };
}

function getAllTier2DerivedThemeColorsCssVariables(
  tier2DerivedThemeColors: Tier2DerivedThemeColors,
  tier1DerivedThemeColors: Tier1DerivedThemeColors
) {
  return Object.entries(tier2DerivedThemeColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...buildTier2DerivedThemeColorCssVariables(key, value, tier1DerivedThemeColors[value]),
    }),
    {}
  );
}

function buildTier2DerivedThemeColorCssVariables(
  newKey: string,
  originalKey: Tier1DerivedThemeColorKey,
  baseKey: BaseThemeColorKey
) {
  return {
    [`--color-${newKey}-h`]: `var(--color-${originalKey}-h)`,
    [`--color-${newKey}-s`]: `var(--color-${originalKey}-s)`,
    [`--color-${newKey}-l`]: cssCalcDynamicLightnessShift(originalKey, baseKey),
    [`--color-${newKey}-a`]: `var(--color-${originalKey}-a)`,
    [`--color-${newKey}`]: `hsl(var(--color-${newKey}-h), calc(var(--color-${newKey}-s) * 1%), calc(var(--color-${newKey}-l) * 1%), calc(var(--color-${newKey}-a) * 1%))`,
  };
}

function getAllTier3DerivedThemeColorsCssVariables(
  tier3DerivedThemeColors: Tier3DerivedThemeColors,
  tier2DerivedThemeColors: Tier2DerivedThemeColors,
  tier1DerivedThemeColors: Tier1DerivedThemeColors
) {
  return Object.entries(tier3DerivedThemeColors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...buildTier3DerivedThemeColorCssVariables(key, value, tier1DerivedThemeColors[tier2DerivedThemeColors[value]]),
    }),
    {}
  );
}

function buildTier3DerivedThemeColorCssVariables(
  newKey: string,
  originalKey: Tier2DerivedThemeColorKey,
  baseKey: BaseThemeColorKey
) {
  return {
    [`--color-${newKey}-h`]: `var(--color-${originalKey}-h)`,
    [`--color-${newKey}-s`]: `var(--color-${originalKey}-s)`,
    [`--color-${newKey}-l`]: cssCalcDynamicLightnessShift(originalKey, baseKey),
    [`--color-${newKey}-a`]: `var(--color-${originalKey}-a)`,
    [`--color-${newKey}`]: `hsl(var(--color-${newKey}-h), calc(var(--color-${newKey}-s) * 1%), calc(var(--color-${newKey}-l) * 1%), calc(var(--color-${newKey}-a) * 1%))`,
  };
}

function getThemeColorsCssVariablesByVariation(
  themeColorsVariations: ThemeColorsVariations
): ThemeColorsCssVariablesByVariation {
  return Object.entries(themeColorsVariations).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: buildThemeColorsCssVariables(value),
    }),
    {} as ThemeColorsCssVariablesByVariation
  );
}

function buildThemeColorsCssVariables(themeColors: BaseThemeColors) {
  return {
    ...getAllBaseThemeColorsCssVariables(themeColors),
    ...getAllTier1DerivedThemeColorsCssVariables(TIER_1_DERIVED_THEME_COLORS),
    ...getAllTier2DerivedThemeColorsCssVariables(TIER_2_DERIVED_THEME_COLORS, TIER_1_DERIVED_THEME_COLORS),
    ...getAllTier3DerivedThemeColorsCssVariables(
      TIER_3_DERIVED_THEME_COLORS,
      TIER_2_DERIVED_THEME_COLORS,
      TIER_1_DERIVED_THEME_COLORS
    ),
  };
}

export const buildCssVariablesForColorPaletteAndVariations = (
  colorPalette: ColorPaletteColors
): [
  Record<string, string | number>,
  Record<string, string | number>,
  Record<string, Record<string, string | number>>,
] => {
  const preparedColorPalette = prepareColorPalette(colorPalette);
  const globalCssVariables = GlobalColorValues;
  const colorCssVariables = getColorPaletteCssVariables(preparedColorPalette);
  const themeColorsVariations = getThemeColorsVariations(preparedColorPalette);
  const colorCssVariablesByVariation = getThemeColorsCssVariablesByVariation(themeColorsVariations);

  return [globalCssVariables, colorCssVariables, colorCssVariablesByVariation];
};

// Color Utilities
// -----------------------------------------------

function prepareColorPalette(colorPalette: Record<string, { hex: string }>): ColorPaletteColors {
  return Object.entries(colorPalette).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: hexToColorData(value.hex),
    };
  }, {} as ColorPaletteColors);
}

function hexToColorData(hex: string): ColorConfig {
  let hsl;
  try {
    hsl = ColorApi(hex).hsl().object();
  } catch (error) {
    return {
      hex: '#ffffff',
      h: 0,
      s: 0,
      l: 1,
    };
  }

  return {
    hex: hex,
    h: hsl.h,
    s: hsl.s,
    l: hsl.l,
  };
}

function getContrastRatio(color1: string, color2: string): number {
  return ColorApi(color1).contrast(ColorApi(color2));
}

function isAboveContrastRatio(color1: string, color2: string, ratio: number): boolean {
  return getContrastRatio(color1, color2) >= ratio;
}

function ensureContrast(
  colorDesired: keyof ColorPaletteColors,
  colorContext: keyof ColorPaletteColors,
  colorConfig: ColorPaletteColors,
  minContrastRatio: number
): keyof ColorPaletteColors {
  if (isAboveContrastRatio(colorConfig[colorDesired].hex, colorConfig[colorContext].hex, minContrastRatio)) {
    return colorDesired;
  }

  // get contrast with light and dark
  const contrastWithLight = getContrastRatio(
    colorConfig[ColorPaletteKey.ColorLight].hex,
    colorConfig[colorContext].hex
  );
  const contrastWithDark = getContrastRatio(colorConfig[ColorPaletteKey.ColorDark].hex, colorConfig[colorContext].hex);

  if (contrastWithLight >= minContrastRatio && contrastWithLight > contrastWithDark) {
    return ColorPaletteKey.ColorLight;
  }

  if (contrastWithDark >= minContrastRatio && contrastWithDark > contrastWithLight) {
    return ColorPaletteKey.ColorDark;
  }

  const contrastWithDesired = getContrastRatio(colorConfig[colorDesired].hex, colorConfig[colorContext].hex);

  // if desired, light, and dark all fail to meet the minimum, return the one with the highest contrast
  if (contrastWithLight > contrastWithDesired && contrastWithLight > contrastWithDark) {
    return ColorPaletteKey.ColorLight;
  }

  if (contrastWithDark > contrastWithDesired && contrastWithDark > contrastWithLight) {
    return ColorPaletteKey.ColorDark;
  }

  // if all else fails, return desired
  return colorDesired;
}

function cssCalcDynamicLightnessShift(
  originalKey: ThemeColorKey,
  clampKey?: ThemeColorKey,
  darkenBy = 'var(--global-darkening-amount)',
  lightenBy = 'var(--global-lightening-amount)'
) {
  if (clampKey === undefined) {
    clampKey = originalKey;
  }
  return `calc(
    var(--color-${originalKey}-l) +
      calc(clamp(
        ${darkenBy},
        calc(((var(--color-${clampKey}-l) * -1) + 50.2) * 99999),
        ${lightenBy}
      ))
  )`;
}
