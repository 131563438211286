import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Sermon',
    description: 'Show off your sermons',
    icon: 'Sermon',
  })
  .setContext('editor', {
    label: 'Sermon Section',
    description: '',
    icon: 'Sermon',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('SermonLayout1')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Desktop Sermon Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      })
      .setBlockVariantsMobile({
        label: 'Mobile Sermon Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('SermonLayout2')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Sermon Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('SermonLayout3')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Image Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('SermonLayout4')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('SermonLayout5')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Sermon Only')
      .setIcon('SermonLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
