import { SectionDataWeb, SectionTypeEnum } from '@nucleus/types/web';
import { createContext } from 'react';

type StringWithAutocompleteOptions<T extends string> = (string & {}) | T;

type MarkupKey = StringWithAutocompleteOptions<SectionTypeEnum> | `${SectionTypeEnum}`;

export type MarkupComponentMap = Record<MarkupKey, React.ComponentType>;

export interface MarkupRegistryContext {
  getComponentMap: () => MarkupComponentMap;
  register: (key: MarkupKey, markupComponent: React.ComponentType) => void;
  getByType: (key: MarkupKey) => React.ComponentType | undefined;
  sectionRenderErrorData: SectionDataWeb | undefined;
}

export const MarkupRegistryContext = createContext({} as MarkupRegistryContext);
MarkupRegistryContext.displayName = 'MarkupRegistryContext';
