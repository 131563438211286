import React, { useContext } from 'react';
import { SectionDataWeb } from '@nucleus/types/web';
import { SectionsContext, SectionsContextValue } from './SectionsContext';

interface SectionsProviderProps {
  children: React.ReactNode;
  sections: Record<SectionDataWeb['id'], SectionDataWeb>;
}
export const SectionsProvider = (props: SectionsProviderProps): JSX.Element => {
  let { sections } = useContext<SectionsContextValue>(SectionsContext);

  if (sections === undefined) {
    sections = new Map();
  }

  Object.entries(props.sections).forEach((entry) => sections.set(...entry));

  const context: SectionsContextValue = {
    sections: sections,
    getSection: (id: string) => sections.get(id),
  };

  return <SectionsContext.Provider value={context}>{props.children}</SectionsContext.Provider>;
};
