import { merge as _merge } from 'lodash';
import React, { useContext } from 'react';
import { LookConfigContext } from './LookConfigContext';

interface Props {
  children: React.ReactNode;
  value?: LookConfigContext;
}

export const LookConfigProvider = (props: Props): JSX.Element => {
  const parentContext = useContext(LookConfigContext);

  const context: LookConfigContext = _merge({}, parentContext, props.value);

  return <LookConfigContext.Provider value={context}>{props.children}</LookConfigContext.Provider>;
};
