import { Editor, Element, Node, Point, Range, Transforms } from 'slate';

/**
 * @deprecated use import { BulletedListSymbol } from '@nucleus/ncu-slate' instead
 */
export const BulletedListSymbol = Symbol('BulletListElement');

/**
 * @deprecated use import { NumberedListSymbol } from '@nucleus/ncu-slate' instead
 */
export const NumberedListSymbol = Symbol('NumberedListElement');

/**
 * @deprecated use import { ListEditor } from '@nucleus/ncu-slate' instead
 */
export interface ListEditor {
  [BulletedListSymbol]: true;
  [NumberedListSymbol]: true;
  isListActive: (type?: ListElementType) => boolean;
  toggleList: (type: ListElementType) => void;
}

type ListElementType = 'bulleted-list' | 'numbered-list';

const ListTypes = ['bulleted-list', 'numbered-list'];

/**
 * @deprecated use import { withLists } from '@nucleus/ncu-slate' instead
 */
export const withLists = <T extends Editor>(editor: T): T & ListEditor => {
  const e = editor as T & ListEditor;
  const { normalizeNode, deleteBackward, insertBreak } = e;

  e[BulletedListSymbol] = true;
  e[NumberedListSymbol] = true;

  e.normalizeNode = (entry) => {
    const [node, path] = entry;

    // Constraint: Children of list nodes must be list items.
    if (isListNode(node)) {
      for (const [child, childPath] of Node.children(e, path)) {
        if (!isListItemNode(child)) {
          Transforms.wrapNodes(e, { type: 'list-item', children: [] }, { at: childPath });
          return;
        }
      }
    }

    // Constraint: Children of list items must be inline.
    if (isListItemNode(node)) {
      for (const [child, childPath] of Node.children(e, path)) {
        if (Element.isElement(child) && Editor.isBlock(e, child)) {
          // Lift block items up and split the list
          Transforms.unwrapNodes(e, {
            at: childPath,
            match: (node) => isListNode(node) || isListItemNode(node),
            mode: 'highest',
            split: true,
          });
          return;
        }
      }
    }

    // Constraint: List items must be inside a list.
    if (isListItemNode(node)) {
      const parent = Node.parent(e, path);
      if (!isListNode(parent)) {
        Transforms.wrapNodes(e, { type: 'bulleted-list', children: [] }, { at: path });
        return;
      }
    }

    // Constraint: Sibling lists will be merged
    if (isListNode(node)) {
      const next = Editor.next(e, { at: path });

      if (next) {
        const [nextNode, nextPath] = next;
        if (isListNode(nextNode) && nextNode.type === node.type) {
          Transforms.mergeNodes(e, { at: nextPath });
          return;
        }
      }

      const previous = Editor.previous(e, { at: path });

      if (previous) {
        const [previousNode] = previous;
        if (isListNode(previousNode) && previousNode.type === node.type) {
          Transforms.mergeNodes(e, { at: path });
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  e.deleteBackward = (...args) => {
    const { selection } = e;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(e, { match: isListItemNode });

      if (match) {
        const [, path] = match;
        const start = Editor.start(e, path);

        const parent = Node.parent(e, path);
        // If at the start of a list item, and the list item is the only item in the list, remove the list
        if (Point.equals(selection.anchor, start) && parent.children.length === 1) {
          Editor.withoutNormalizing(e, () => {
            Transforms.setNodes(e, { type: 'paragraph' });
            Transforms.unwrapNodes(e, {
              at: path,
              match: (node) => isListNode(node) || isListItemNode(node),
              mode: 'highest',
              split: true,
            });
          });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  e.insertBreak = () => {
    const { selection } = e;

    if (selection) {
      const [match] = Editor.nodes(e, { match: isListItemNode });

      if (match) {
        const [node, path] = match;

        // If the list item is empty convert to paragraph
        if (
          Node.string(node) === '' &&
          !node.children.some((child) => Element.isElement(child) && Editor.isInline(e, child))
        ) {
          Editor.withoutNormalizing(e, () => {
            Transforms.setNodes(e, { type: 'paragraph' });
            Transforms.unwrapNodes(e, {
              at: path,
              match: (node) => isListNode(node) || isListItemNode(node),
              mode: 'highest',
              split: true,
            });
          });
          return;
        }
      }
    }

    insertBreak();
  };

  e.isListActive = (type?: ListElementType) => ListEditor.isListActive(e, type);
  e.toggleList = (type: ListElementType) => ListEditor.toggleList(e, type);

  return e;
};

export const ListEditor = {
  isListActive: (editor: Editor, type?: ListElementType): boolean => {
    const [match] = Editor.nodes(editor, {
      match: (node) => isListNode(node) && (!type || node.type === type),
    });

    return match !== undefined;
  },
  toggleList: (editor: Editor, type: ListElementType): void => {
    Editor.withoutNormalizing(editor, () => {
      const [match] = Editor.nodes(editor, { match: isListNode });

      // If the current selection is in a different list, convert it to the new list type
      if (match && match[0].type !== type) {
        const [, path] = match;
        Transforms.setNodes(editor, { type: type }, { at: path });
        return;
      }

      // If the current selection is in a list, convert it to a paragraph
      if (match) {
        Transforms.unwrapNodes(editor, { match: isListNode, split: true });
        Transforms.setNodes(editor, { type: 'paragraph' });
        return;
      }

      Transforms.setNodes(editor, { type: 'list-item' });
      Transforms.wrapNodes(editor, { type: type, children: [] });
    });
  },
};

/**
 * @deprecated use import { isListNode } from '@nucleus/ncu-slate' instead
 */
export const isListNode = (node: Node): node is BaseElement =>
  !Editor.isEditor(node) && Element.isElement(node) && ListTypes.includes(node.type);

/**
 * @deprecated use import { isListItemNode } from '@nucleus/ncu-slate' instead
 */
export const isListItemNode = (node: Node): node is BaseElement =>
  !Editor.isEditor(node) && Element.isElement(node) && node.type === 'list-item';
