import { createContext } from '@nucleus/react-components';
import React, { useContext } from 'react';

export interface NavigateSSRClient {
  navigateTo?: { location: string; code: number };
}

export interface NavigateSSRContext {
  ssrNavigate: (url: string, statusCode: number) => void;
}

export const getNavigateSSRClient = (): NavigateSSRClient => {
  return {
    navigateTo: undefined,
  };
};

const [NavigateSSRContextProvide, , navigateContext] = createContext<NavigateSSRContext>({
  name: 'NavigateSSR',
});

const emptyContext: NavigateSSRContext = {
  ssrNavigate: () => undefined,
};

const useNavigateSSR = (): NavigateSSRContext => {
  const context = useContext(navigateContext);

  return context ?? emptyContext;
};

export { useNavigateSSR };

interface NavigateSSRControllerProps extends UseContextProps {
  children: React.ReactNode;
}
export const NavigateSSRProvider = ({ children, ...props }: NavigateSSRControllerProps): JSX.Element => {
  return <NavigateSSRContextProvide value={useController(props)}>{children}</NavigateSSRContextProvide>;
};

interface UseContextProps {
  client: NavigateSSRClient;
}
const useController = (props: UseContextProps): NavigateSSRContext => {
  const ssrNavigate = (url: string, statusCode: number) => {
    props.client.navigateTo = { location: url, code: statusCode };
  };

  return {
    ssrNavigate: ssrNavigate,
  };
};
