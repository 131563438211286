import React from 'react';

export interface MenuContext {
  panelId: string;
}

export const MenuContext = React.createContext<MenuContext>(null!);
MenuContext.displayName = 'MenuContext';

export const useMenuContext = (): MenuContext => React.useContext(MenuContext);
