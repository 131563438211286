import { ActionItem, ActionItemApi } from '../action';
import { Prettify } from '../utility';

export const SectionActionType = {
  Email: 'email',
  File: 'file',
  Flow: 'flow',
  Giving: 'giving',
  InfoCard: 'infoCard',
  Leader: 'leader',
  None: 'none',
  Overlay: 'overlay',
  Page: 'page',
  Phone: 'phone',
  Prayer: 'prayer',
  Sermons: 'sermons',
  SignIn: 'signIn',
  Url: 'url',
} as const;

export type SectionActionType = (typeof SectionActionType)[keyof typeof SectionActionType];

export const ActionTypeLabelMap: Record<SectionActionType, string> = {
  [SectionActionType.Email]: 'Email',
  [SectionActionType.File]: 'File Download',
  [SectionActionType.Flow]: 'Flow',
  [SectionActionType.Giving]: 'Giving',
  [SectionActionType.InfoCard]: 'Info Card',
  [SectionActionType.Leader]: 'Leader',
  [SectionActionType.None]: 'None',
  [SectionActionType.Overlay]: 'Overlay',
  [SectionActionType.Page]: 'Page',
  [SectionActionType.Phone]: 'Phone',
  [SectionActionType.Prayer]: 'Prayer',
  [SectionActionType.Sermons]: 'Sermons',
  [SectionActionType.SignIn]: 'Sign In',
  [SectionActionType.Url]: 'URL',
};

interface SectionActionBase {
  openInNewTab?: boolean;
  displayAs: 'style1' | 'style2' | 'style3' | 'style4';
}

type DistributeAction<T> = T extends any ? Action<T> : never;
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type Action<T, M = {}> = Prettify<ActionItem<T> & SectionActionBase & M>;

type OverlayAction = Action<'overlay', { overlayId: string }>;

export type SectionAction = DistributeAction<Exclude<SectionActionType, 'overlay'>> | OverlayAction;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type ActionApi<T, M = {}> = Prettify<ActionItemApi<T, M> & SectionActionBase>;
type DistributeActionApi<T> = T extends any ? ActionApi<T> : never;

type FileActionApi = ActionItemApi<'file', { readUrl?: string }>;

export type SectionActionApi = DistributeActionApi<Exclude<SectionActionType, 'file'>> | FileActionApi;
