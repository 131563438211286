/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontConfig, FontPalette, FontPaletteKey, FontPaletteKeys } from '@nucleus/types/web';
import React from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  Interpolation,
  StyledComponent,
  StyledComponentPropsWithRef,
  StyledConfig,
  ThemeProps,
  css,
} from 'styled-components';

const THEME_TEXT_CLASS_PREFIX = 'theme-text-';

/** Theme specific keys, not Font Palette Keys */
const ThemeTextKey = {
  MainNavItemLabel: 'main-nav-item-label',
  MainNavSubitemLabel: 'main-nav-subitem-label',
  MainNavToggleLabel: 'main-nav-toggle-label',
  FooterBody: 'footer-body',
  FooterChurchNameLabel: 'footer-church-name-label',
  FooterNavItemLabel: 'footer-nav-item-label',
  FooterNavSubitemLabel: 'footer-nav-subitem-label',
  Button1: 'button-1',
  Button2: 'button-2',
  Button3: 'button-3',
  Button4: 'button-4',
  CalendarHeaderLabel: 'calendar-header-label',
} as const;

const ThemeTextKeys = Object.values(ThemeTextKey) as ThemeTextKey[];

type ThemeTextKey = (typeof ThemeTextKey)[keyof typeof ThemeTextKey];

type ThemeTextOrFontPaletteKey = ThemeTextKey | FontPaletteKey;

interface ThemeTextValue {
  base: FontPaletteKey;
  margin?: [string] | [string, string] | [string, string, string] | [string, string, string, string];
}

type ThemeTextConfig = Record<ThemeTextOrFontPaletteKey, ThemeTextValue>;

const THEME_TEXT_CONFIG: ThemeTextConfig = {
  headline1: {
    base: 'headline1',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  headline2: {
    base: 'headline2',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  headline3: {
    base: 'headline3',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  headline4: {
    base: 'headline4',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  headline5: {
    base: 'headline5',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  headline6: {
    base: 'headline6',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  paragraph1: {
    base: 'paragraph1',
  },
  paragraph2: {
    base: 'paragraph2',
  },
  paragraph3: {
    base: 'paragraph3',
  },
  paragraph4: {
    base: 'paragraph4',
  },
  title1: {
    base: 'title1',
  },
  title2: {
    base: 'title2',
  },
  description1: {
    base: 'description1',
  },
  description2: {
    base: 'description2',
  },
  blockquote1: {
    base: 'blockquote1',
  },
  blockquote2: {
    base: 'blockquote2',
  },
  label1: {
    base: 'label1',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  label2: {
    base: 'label2',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  label3: {
    base: 'label3',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  label4: {
    base: 'label4',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  label5: {
    base: 'label5',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  label6: {
    base: 'label6',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  navigation1: {
    base: 'navigation1',
  },
  navigation2: {
    base: 'navigation2',
  },
  navigation3: {
    base: 'navigation3',
  },
  navigation4: {
    base: 'navigation4',
  },
  navigation5: {
    base: 'navigation5',
  },
  ['main-nav-item-label']: {
    base: 'navigation1',
  },
  ['main-nav-subitem-label']: {
    base: 'navigation2',
  },
  ['main-nav-toggle-label']: {
    base: 'navigation5',
  },
  ['footer-body']: {
    base: 'paragraph3',
  },
  ['footer-church-name-label']: {
    base: 'headline6',
  },
  ['footer-nav-item-label']: {
    base: 'navigation3',
  },
  ['footer-nav-subitem-label']: {
    base: 'navigation4',
  },
  ['button-1']: {
    base: 'button1',
  },
  ['button-2']: {
    base: 'button2',
  },
  ['button-3']: {
    base: 'button3',
  },
  ['button-4']: {
    base: 'button4',
  },
  ['button1']: {
    base: 'button1',
  },
  ['button2']: {
    base: 'button2',
  },
  ['button3']: {
    base: 'button3',
  },
  ['button4']: {
    base: 'button4',
  },
  ['calendar-header-label']: {
    base: 'label4',
  },
};

interface GlobalStyleProps {
  fontPalette: FontPalette;
  baseScale?: number;
  scale?: number;
}

/**
 * Generate the CSS for the text global styles and return as a CSS Interpolation for use in styled-components.
 *
 * @param props {GlobalStyleProps}
 * @returns {Interpolation<ThemeProps<DefaultTheme>>}
 */
export const getTextGlobalStyle = ({
  fontPalette,
  baseScale: fontBaseScale,
  scale: fontScale,
}: GlobalStyleProps): Interpolation<ThemeProps<DefaultTheme>> => {
  return css`
    :root {
      /* text css variables */
      --root-font-value: 10; /* REMS are base10 :) */

      /* Known constant that is the middle of the global font size slider. */
      --global-font-base: ${() => fontBaseScale ?? 16};

      /* this comes from the DB */
      --global-font-config: ${() => fontScale ?? fontBaseScale};

      /* This is used to SCALE the size of the various fonts */
      --global-font-factor: calc(var(--global-font-config) / var(--global-font-base));

      ${buildFontPaletteCustomPropsCSS(fontPalette)}
      ${buildThemeTextCustomPropsCSS(THEME_TEXT_CONFIG)}
    }

    html {
      font-size: calc(var(--root-font-value) * 1px);
    }

    ${buildThemeTextClassNameStyles([...ThemeTextKeys, ...FontPaletteKeys])}
  `;
};

const palettePrefix = (key: string) => `palette-${key}`;

/**
 * Generate the CSS Custom Properties for the font palette and return as a CSS Interpolation
 * for use in styled-components.
 *
 * ```css
 * --palette-<key>-font-size: <font-size>;
 * --palette-<key>-line-height: <line-height>;
 * --palette-<key>-letter-spacing: <letter-spacing>;
 * --palette-<key>-font-family: <font-family>;
 * --palette-<key>-font-weight: <font-weight>;
 * --palette-<key>-font-style: <font-style>;
 * --palette-<key>-text-transform: <text-transform>;
 *```
 * @param fontPalette {FontPalette}
 * @returns {FlattenInterpolation<ThemeProps<DefaultTheme>>}
 */
const buildFontPaletteCustomPropsCSS = (fontPalette: FontPalette): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  // Magic numbers (for now) to be replaced soon. @dlosie
  const windowMinWidth = 320; // Based on the min width of the body.
  const windowMaxWidth = 1500;
  const sizeScaleUpperBound = 140;
  const sizeScaleLowerBound = 12;
  const responsiveScaleUpperBound = 76;
  const responsiveScaleLowerBound = 12;
  const upperSizeRange = sizeScaleUpperBound - sizeScaleLowerBound;
  const responsiveSizeRange = responsiveScaleUpperBound - responsiveScaleLowerBound;
  const rangeScaleFactor = responsiveSizeRange / upperSizeRange;

  function getTextTransformFromCapitalization(capitalization: FontConfig['capitalization']) {
    switch (capitalization) {
      case 'everyWord':
        return 'capitalize';
      case 'firstWord':
        return 'lowercase';
      case 'lowercase':
        return 'lowercase';
      case 'uppercase':
        return 'uppercase';
      case 'none':
      default:
        return 'none';
    }
  }

  return css`
    ${Object.entries(fontPalette).map(([key, value]) => {
      const responsiveFontSizePx =
        rangeScaleFactor * (value.fontSizePx - responsiveScaleLowerBound) + responsiveScaleLowerBound;

      const slope = (value.fontSizePx - responsiveFontSizePx) / (windowMaxWidth - windowMinWidth);

      const fontSizeCalc = `clamp(${responsiveFontSizePx}px, calc((${slope} * (100vw - ${windowMinWidth}px)) + ${responsiveFontSizePx}px), 1000rem)`;

      return css`
          --${palettePrefix(key)}-font-size: ${fontSizeCalc};
          --${palettePrefix(key)}-line-height: ${value.lineHeightEm};
          --${palettePrefix(key)}-letter-spacing: ${value.letterSpacingEm};
          --${palettePrefix(key)}-font-family: "${value.fontFamily}";
          --${palettePrefix(key)}-font-weight: ${getFontWeight(value)};
          --${palettePrefix(key)}-font-style: ${value.fontStyle};
          --${palettePrefix(key)}-text-transform: ${getTextTransformFromCapitalization(value.capitalization)};
        `;
    })}
  `;
};

/**
 * Generate the CSS Custom Properties for the text palette and return as a CSS Interpolation
 * for use in styled-components.
 *
 * ```css
 * --<key>-font-size: var(--palette-<base>-font-size);
 * --<key>-line-height: var(--palette-<base>-line-height);
 * --<key>-letter-spacing: var(--palette-<base>-letter-spacing);
 * --<key>-font-family: var(--palette-<base>-font-family);
 * --<key>-font-weight: var(--palette-<base>-font-weight);
 * --<key>-font-style: var(--palette-<base>-font-style);
 * --<key>-text-transform: var(--palette-<base>-text-transform);
 * --<key>-font-margin: <margin>;
 * ```
 * @param config {ThemeTextConfig}
 * @returns {FlattenInterpolation<ThemeProps<DefaultTheme>>}
 */
const buildThemeTextCustomPropsCSS = (config: ThemeTextConfig): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    ${Object.entries(config).map(([key, value]) => {
      return css`
          --${key}-font-size: var(--${palettePrefix(value.base)}-font-size);
          --${key}-line-height: var(--${palettePrefix(value.base)}-line-height);
          --${key}-letter-spacing: var(--${palettePrefix(value.base)}-letter-spacing);
          --${key}-font-family: var(--${palettePrefix(value.base)}-font-family);
          --${key}-font-weight: var(--${palettePrefix(value.base)}-font-weight);
          --${key}-font-style: var(--${palettePrefix(value.base)}-font-style);
          --${key}-text-transform: var(--${palettePrefix(value.base)}-text-transform);
          ${value.margin !== undefined ? `--${key}-font-margin: ${value.margin.join(' ')};` : ''}
        `;
    })}
  `;
};

/**
 * Generate the CSS for the text styles and return as a CSS Interpolation for use in styled-components.
 *
 * ```css
 * margin: var(--<key>-font-margin);
 * font-size: calc(var(--<key>-font-size) * var(--global-font-factor));
 * line-height: calc(var(--<key>-line-height) * 1em);
 * letter-spacing: calc(var(--<key>-letter-spacing) * 1em);
 * font-family: var(--<key>-font-family);
 * font-weight: var(--<key>-font-weight);
 * font-style: var(--<key>-font-style);
 * text-transform: var(--<key>-text-transform);
 * ```
 *
 * @param key {string}
 * @returns {FlattenInterpolation<ThemeProps<DefaultTheme>>}
 */
const buildThemeTextStyles = (key: string): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    margin: var(--${key}-font-margin);
    font-size: calc(var(--${key}-font-size) * var(--global-font-factor));
    line-height: calc(var(--${key}-line-height) * 1em);
    letter-spacing: calc(var(--${key}-letter-spacing) * 1em);
    font-family: var(--${key}-font-family);
    font-weight: var(--${key}-font-weight);
    font-style: var(--${key}-font-style);
    text-transform: var(--${key}-text-transform);
  `;
};

/**
 * Generate the CSS for the text class names and return as a CSS Interpolation for use in styled-components.
 *
 * @param keys {string[]}
 * @returns {FlattenInterpolation<ThemeProps<DefaultTheme>>}
 */
const buildThemeTextClassNameStyles = (
  keys: ThemeTextOrFontPaletteKey[]
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    ${keys.map(
      (key) => css`
        ${generateTextClassNameSelector(key)} {
          ${buildThemeTextStyles(key)}
        }
      `
    )}
  `;
};

const getFontWeight = (font: FontConfig) => {
  const fontWeight = parseInt(font.fontWeight);
  if (fontWeight >= 700) {
    return font.fontWeight;
  }

  return font.forceBold === true ? 'bold' : font.fontWeight;
};

const generateTextClassName = (key: ThemeTextOrFontPaletteKey): string => `${THEME_TEXT_CLASS_PREFIX}${key}`;
const generateTextClassNameSelector = (key: ThemeTextOrFontPaletteKey): string => `.${generateTextClassName(key)}`;

const TextClassNameMap = [...ThemeTextKeys, ...FontPaletteKeys].reduce(
  (acc, name) => ({ ...acc, [name]: generateTextClassName(name) }),
  {} as Record<ThemeTextOrFontPaletteKey, string>
);

const BaseLinkStyles = css`
  color: inherit;
  text-decoration: underline;
`;

const BaseTextStyles = css`
  a {
    ${BaseLinkStyles}
  }
`;

export type PropsWithFontStyle<T = object> = T & { fontStyle?: FontPaletteKey };

/**
 * Takes any component and a style and returns a styled component with the given style and class applied.
 * @param Component
 * @param style
 * @returns
 */
const withFontStyle = <C extends React.ElementType, S extends FontPaletteKey>(
  Component: C,
  style?: S,
  config?: StyledConfig<StyledComponentPropsWithRef<C>>
): StyledComponent<C, DefaultTheme, PropsWithFontStyle<StyledComponentPropsWithRef<C>>> => {
  /** Allow client to override style using props.fontStyle, fallback to paragraph1*/
  const getStyleKey = (props: PropsWithFontStyle<C>): FontPaletteKey => props.fontStyle ?? style ?? 'paragraph1';

  const WithFontStyle = styled(Component)
    .withConfig(config ?? {})
    .attrs((props) => ({ className: TextClassNameMap[getStyleKey(props)] }) as any)`
    ${BaseTextStyles}
    ${(props) => buildThemeTextStyles(getStyleKey(props))}
  `;

  WithFontStyle.displayName = `withFontStyle(${getDisplayName(Component)}${style ? `.${style}` : ''})`;

  return WithFontStyle as any;
};

const getDisplayName = (Component: React.ElementType): string =>
  (Component as React.ComponentType).displayName ?? (Component as React.ComponentType).name ?? Component;

export const Text = {
  ClassName: TextClassNameMap,
  withFontStyle: withFontStyle,
  H1: withFontStyle('h1', 'headline1'),
  H2: withFontStyle('h2', 'headline2'),
  H3: withFontStyle('h3', 'headline3'),
  H4: withFontStyle('h4', 'headline4'),
  H5: withFontStyle('h5', 'headline5'),
  H6: withFontStyle('h6', 'headline6'),
  P1: withFontStyle('p', 'paragraph1'),
  P2: withFontStyle('p', 'paragraph2'),
  P3: withFontStyle('p', 'paragraph3'),
  P4: withFontStyle('p', 'paragraph4'),
  P5: withFontStyle('p', 'description1'),
  P6: withFontStyle('p', 'description2'),
  L1: withFontStyle('div', 'label1'),
  L2: withFontStyle('div', 'label2'),
  L3: withFontStyle('div', 'label3'),
  L4: withFontStyle('div', 'label4'),
  L5: withFontStyle('div', 'label5'),
  L6: withFontStyle('div', 'label6'),
};
