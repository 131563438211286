import { NotFoundError } from '@nucleus/ncu-error';
import { isPlainObject as _isPlainObject } from 'lodash';

export const isNonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

type isNotEmptyOverload = {
  <T>(value: Array<T>): asserts value is Array<T>;
  <T extends { [key: string]: any }>(value: T): asserts value is T;
  (value: string): asserts value is string;
  <T>(value: T | null | undefined): asserts value is T;
};

export const isNotEmpty: isNotEmptyOverload = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.length > 0;
  }

  if (_isPlainObject(value)) {
    for (const _ in value) {
      // It has iterable properties so it's not empty.
      return true;
    }
    return false;
  }

  return isNonNullable(value);
};

type assertFoundOverload = {
  (thing: string, expression: boolean): asserts expression is true;
  <T>(thing: string, expression: T): asserts expression is NonNullable<T>;
};

const assertFound: assertFoundOverload = (thing: string, expression: any) => {
  if (expression === undefined || expression === null || expression === false) {
    throw new NotFoundError(`${thing} Not Found`.trim());
  }
};

export const mustFind = async <T>(thing: string, finder: T | Promise<T>): Promise<NonNullable<T>> => {
  const result = await finder;
  assertFound(thing, result);
  return result;
};
