import { useSection } from '@nucleus/web-theme';
import React from 'react';
import { css } from 'styled-components';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';
import { GlobalStyle } from './GlobalStyle';

export const SectionGlobalStyle = (): JSX.Element | null => {
  const section = useSection();

  const codeSnippets = section?.payload?.codeSnippets ?? [];
  const cssSnippets = codeSnippets.filter((snippet) => snippet.language === 'css');
  const enabledCssSnippets = useEnabledCodeSnippets(cssSnippets);

  if (section === undefined || section.id === undefined) {
    return null;
  }

  // Override the section background color if set on the section payload
  const sectionBackgroundColor = section.payload?.backgroundMedia?.color?.hex;
  const renderBackgroundColorOverride = sectionBackgroundColor !== undefined;

  return (
    <>
      {enabledCssSnippets.map((snippet) => (
        <GlobalStyle
          key={snippet.id}
          content={snippet.content}
          selector={`#${section.id}#${section.id}#${section.id}`}
        />
      ))}
      {renderBackgroundColorOverride && (
        <GlobalStyle
          content={css`
            --color-section-background: ${sectionBackgroundColor};
          `}
          selector={`#${section.id}#${section.id}#${section.id}`} // might not need 3x, but just in case
        />
      )}
    </>
  );
};
