import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import { get as _get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSermonHubPath } from '../hooks/useSermonHubPath';
import { useSpeaker } from '../hooks/useSpeaker';
import { useSpeakerPath } from '../hooks/useSpeakerPath';

interface Props {
  sermon?: SermonWeb | SermonIndexDocumentWeb;
}

export const SermonSpeakers = ({ sermon }: Props): JSX.Element | null => {
  if (sermon === undefined) {
    return null;
  }

  if (isSermonIndexDocumentWeb(sermon)) {
    return <SermonIndexSpeakers sermon={sermon} />;
  }

  if (isSermonWeb(sermon)) {
    return <SermonWebSpeakers sermon={sermon} />;
  }

  return null;
};

const SermonIndexSpeakers = ({ sermon }: { sermon: SermonIndexDocumentWeb }) => {
  const basePath = useSermonHubPath(sermon.engineId);
  const toSpeaker = useSpeakerPath(basePath);

  const speaker = _get(sermon.speakers, sermon.speakerIds[0]);
  const speakerCount = sermon.speakerIds.length;

  if (speaker === undefined) {
    return null;
  }

  return (
    <>
      <StyledLink to={toSpeaker(speaker)}>{speaker.displayName}</StyledLink>
      {speakerCount > 1 ? ` +${speakerCount - 1}` : ''}
    </>
  );
};

const SermonWebSpeakers = ({ sermon }: { sermon: SermonWeb }) => {
  const { data, isLoading, isError } = useSpeaker(sermon.engineId, sermon.speakers[0]);
  const basePath = useSermonHubPath(sermon.engineId);
  const toSpeaker = useSpeakerPath(basePath);

  if (isLoading || isError) {
    return null;
  }

  const speaker = data?.collection;

  if (speaker === undefined) {
    return null;
  }

  const speakerCount = sermon.speakers.length;

  return (
    <>
      <StyledLink to={toSpeaker(speaker)}>{speaker.displayName}</StyledLink>
      {speakerCount > 1 ? ` +${speakerCount - 1}` : ''}
    </>
  );
};

const StyledLink = styled(Link)`
  color: inherit;
`;

/** Sermon Index document has a speakers object keyed by id */
const isSermonIndexDocumentWeb = (sermon: any): sermon is SermonIndexDocumentWeb =>
  sermon.speakers && !Array.isArray(sermon.speakers);

/** Sermon web has an array of speaker ids */
const isSermonWeb = (sermon: any): sermon is SermonWeb => sermon.speakers && Array.isArray(sermon.speakers);
