export const getEnvVar = <T = undefined>(key: string, fallbackValue?: T): string | T => {
  let env;

  try {
    env = process.env[key];
  } catch (error) {
    if (error instanceof ReferenceError) {
      // process is undefined. Nothing we can do about it.
    } else {
      throw error;
    }
  }

  if (env !== undefined && env !== '') {
    return env;
  }

  // Check typeof document to see if we are in a browser environment. If we are there is no need to log the error.
  if (fallbackValue === undefined && typeof document === 'undefined') {
    console.error('Error: Missing environment variable');
    console.info(`Error Info: Missing environment variable'${key}`);
  }

  return fallbackValue as T;
};
