import { GetParameterCommand, PutParameterCommand, SSMClient } from '@aws-sdk/client-ssm';
import { ParameterStoreRepository } from '../type/repository';

export const newSSMParameterRepository = (client: SSMClient): ParameterStoreRepository => {
  const getSSMParameter = async (name: string): Promise<string | undefined> => {
    try {
      const response = await client.send(new GetParameterCommand({ Name: name }));
      return response.Parameter?.Value;
    } catch (error) {
      if (process.env['NX_VERBOSE_LOGGING'] === 'true') {
        console.error(`~~ THE FOLLOWING ERROR OCCURRED BUT IT WAS IGNORED ~~`);
        console.error(error);
      }
      return undefined;
    }
  };

  const putSSMParameter = async (name: string, value: string): Promise<void> => {
    try {
      await client.send(new PutParameterCommand({ Name: name, Value: value }));
    } catch (error) {
      if (process.env['NX_VERBOSE_LOGGING'] === 'true') {
        console.error(`~~ THE FOLLOWING ERROR OCCURRED BUT IT WAS IGNORED ~~`);
        console.error(error);
      }
    }
  };

  return {
    get: getSSMParameter,
    put: putSSMParameter,
  };
};
