import { uuid4InHex } from '@nucleus/lib-shape';
import React from 'react';
import styled from 'styled-components';
import { MenuContext } from './MenuContext';
import { MenuList } from './MenuList';
import { Popover, PopoverProps } from './Popover';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    Menu?: {
      color?: string;
      background?: string;
      boxShadow?: string;
    };
  }
}

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The element based on which the menu is positioned.
   */
  anchorEl?: PopoverProps['anchorEl'];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  isOpen?: PopoverProps['isOpen'];
  onClose?: () => void;
  transformOrigin?: PopoverProps['transformOrigin'];
  menuListComponent?: React.JSXElementConstructor<any>;
  isModal?: boolean;
}

/**
 * The Menu component is used for creating menus that can be opened and positioned relative to a specified element.
 * It uses the <Popover> component for relative positioning
 *
 * See `usePopoverState` for connecting to a trigger element
 *
 * @param props - The props for the Menu component
 * @returns A JSX element representing the menu.
 */
export const Menu = ({
  anchorEl,
  anchorOrigin,
  isModal = true,
  isOpen,
  onClose,
  transformOrigin,
  menuListComponent,
  ...props
}: MenuProps): JSX.Element => {
  const context: MenuContext = React.useMemo(() => ({ panelId: props.id ?? uuid4InHex() }), [props.id]);
  const MenuListComponent = menuListComponent!;

  return (
    <MenuContext.Provider value={context}>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        isOpen={isOpen}
        onClose={onClose}
        isModal={isModal}
      >
        <Panel {...props} id={context.panelId} {...useStopScrollPropagationProps()}>
          <MenuListComponent>{props.children}</MenuListComponent>
        </Panel>
      </Popover>
    </MenuContext.Provider>
  );
};

Menu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  menuListComponent: MenuList,
};

const Panel = styled.div`
  background: ${({ theme }) => theme._reactComponents.Menu?.background};
  color: ${({ theme }) => theme._reactComponents.Menu?.color};
  box-shadow: ${({ theme }) => theme._reactComponents.Menu?.boxShadow};
  border-radius: 12px;
  padding: 6px;
  position: relative;
  overflow: scroll;
  max-height: calc(100vh - 12px);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const useStopScrollPropagationProps = (): React.HTMLAttributes<HTMLDivElement> => {
  return {
    onScroll: (e) => e.stopPropagation(),
    onWheel: (e) => e.stopPropagation(),
    onTouchMove: (e) => e.stopPropagation(),
  };
};
