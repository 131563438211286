import { MediaWeb } from '@nucleus/types/web';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useBoundingClientRect } from '../../hooks/useBoundingClientRect';

interface BackgroundVideoPlayerProps {
  video: NonNullable<MediaWeb['video']>;
  aspectRatioWidth?: number;
  aspectRatioHeight?: number;
  className?: string;
}

export function BackgroundVideoPlayer({
  video,
  aspectRatioWidth = 16,
  aspectRatioHeight = 9,
  className,
}: BackgroundVideoPlayerProps): JSX.Element | null {
  const [playing, setPlaying] = useState(false);
  const [playerSize, setPlayerSize] = useState<PlayerSize>({
    width: undefined,
    height: undefined,
    topOffset: 0,
    leftOffset: 0,
  });

  const [containerRef, { width, height }] = useBoundingClientRect<HTMLDivElement>();

  const handleOnPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const handleOnResize = useCallback(() => {
    const size = resizeToCover(width, height, aspectRatioWidth, aspectRatioHeight);
    setPlayerSize(size);
  }, [width, height, aspectRatioWidth, aspectRatioHeight]);

  useEffect(() => {
    if (video.src === undefined) {
      return;
    }
    handleOnResize();
  }, [handleOnResize, video.src]);

  const opacity = playing ? 1 : 0;

  if (video.src === undefined) {
    return null;
  }

  return (
    <CoverVideoWrapper className={className} ref={containerRef}>
      <BackgroundVideoPlayerContainer
        width={playerSize.width}
        height={playerSize.height}
        top={playerSize.topOffset}
        left={playerSize.leftOffset}
      >
        <BackgroundVideoPlayerPosition>
          <ReactPlayer
            width="100%"
            height="100%"
            style={{ opacity: opacity }}
            url={video.src}
            playing={true}
            muted={true}
            loop={true}
            controls={false}
            config={playerConfig}
            onPlay={handleOnPlay}
          />
        </BackgroundVideoPlayerPosition>
      </BackgroundVideoPlayerContainer>
    </CoverVideoWrapper>
  );
}

const playerConfig = {
  vimeo: {
    playerOptions: {
      background: true,
      autoplay: true,
      muted: true,
      controls: false,
      playsinline: true,
    },
  },
  youtube: {
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      controls: 0,
      showinfo: 0,
      mute: 1,
    },
  },
  wistia: {
    options: {
      autoPlay: true,
      silentAutoPlay: false,
      muted: true,
      controlsVisibleOnLoad: false,
      fullscreenButton: false,
      playbar: false,
      playbackRateControl: false,
      qualityControl: false,
      volumeControl: false,
      settingsControl: false,
      smallPlayButton: false,
      playsinline: true,
      fullscreenOnRotateToLandscape: false,
    },
  },
};

type PlayerSize = {
  width: number | undefined;
  height: number | undefined;
  topOffset: number;
  leftOffset: number;
};

function resizeToCover(width = 0, height = 0, aspectRatioWidth = 16, aspectRatioHeight = 9): PlayerSize {
  // use largest scale factor of horizontal/vertical
  const scale_h = width / aspectRatioWidth;
  const scale_v = height / aspectRatioHeight;
  const scale = scale_h > scale_v ? scale_h : scale_v;

  const playerWidth = scale * aspectRatioWidth;
  const playerHeight = scale * aspectRatioHeight;

  return {
    width: playerWidth,
    height: playerHeight,
    topOffset: -(playerHeight - height) / 2,
    leftOffset: -(playerWidth - width) / 2,
  };
}

const CoverVideoWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
`;

type BackgroundVideoPlayerContainerProps = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
};

const BackgroundVideoPlayerContainer = styled.div<BackgroundVideoPlayerContainerProps>`
  width: ${(props) => (props.width ? props.width + 'px' : '')};
  height: ${(props) => (props.height ? props.height + 'px' : '')};
  top: ${(props) => (props.top ? props.top + 'px' : 0)};
  left: ${(props) => (props.left ? props.left + 'px' : 0)};
  position: relative;
  overflow: hidden;
  /* Fix weird Safari bug breaking rounded corners */
  mask-image: -webkit-radial-gradient(white, black);

  // prevent click through to video player
  cursor: normal;
  user-select: none;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
  }
`;

const BackgroundVideoPlayerPosition = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    transition: opacity ease-in 750ms;
  }
`;
