import { ISO8601Schema } from '@nucleus/src-platform/data';
import { ISO8601 } from '@nucleus/lib-datetime';
import {
  SharingMetadata,
  SharingMetadataApi,
  SharingMetadataEntityItemApi,
  SharingMetadataItem,
  SharingMetadataItemApi,
  SharingMetadataWeb,
} from '@nucleus/types/web/sharing';
import * as yup from 'yup';
import { PublishableEngine, PublishableEngineSchema } from './PublishableEngine';
import { PublishableEntity, PublishableEntitySchema } from './PublishableEntity';
import { PublishablePrivacyControls, PublishablePrivacyControlsSchema } from './PublishablePrivacy';

export type PublishableRevisionType = 'autoSave' | 'userSave' | 'publish' | 'unpublish';

const PublishableRevisionTypeSchema: yup.SchemaOf<PublishableRevisionType> = yup
  .mixed()
  .oneOf(['autoSave', 'userSave', 'publish', 'unpublish']);

const transformEmptyStringSchema = yup.string().transform((value) => (value !== '' ? value : undefined));

export const PublishableSharingMetadataSchema: yup.SchemaOf<SharingMetadataItem> = yup.object({
  enabled: yup.boolean().optional(),
  fileBondId: transformEmptyStringSchema.optional(),
  title: transformEmptyStringSchema.optional(),
  description: transformEmptyStringSchema.optional(),
  image: yup.object({ fileId: yup.string() }).optional(),
});

export const PublishableSharingMetadataApiSchema: yup.SchemaOf<SharingMetadataItemApi> =
  PublishableSharingMetadataSchema;

export const PublishableEntitySharingMetadataSchema: yup.SchemaOf<SharingMetadataEntityItemApi> =
  PublishableSharingMetadataSchema.shape({
    keywords: yup.array().of(yup.string()).optional(),
    noindex: yup.boolean().optional(),
  });

export const PublishableEntitySharingMetadataApiSchema: yup.SchemaOf<SharingMetadataEntityItemApi> =
  PublishableEntitySharingMetadataSchema;

export interface PublishableRevision {
  id: string;
  index: number;
  engineId: PublishableEngine['id'];
  entityId: PublishableEntity['id'];
  revisionType: PublishableRevisionType;
  revisionSource?: any;
  title?: string;
  abstract?: string;
  description?: string;
  privacy: PublishablePrivacyControls;
  publishedAt?: ISO8601;
  sharingMetadata?: SharingMetadata;
  excludeFromListing?: boolean;
  revertedAt?: ISO8601;
  revertedBy?: string;
  unpublishedAt?: ISO8601;
  unpublishedBy?: string;
  readonly createdAt: ISO8601;
  readonly createdBy: string;
}

export const PublishableRevisionSchema: yup.SchemaOf<PublishableRevision> = yup.object({
  id: yup.string().required(),
  index: yup.number().min(1).required().default(1),
  engineId: PublishableEngineSchema.fields.id.required(),
  entityId: PublishableEntitySchema.fields.id.required(),
  revisionType: PublishableRevisionTypeSchema.required(),
  revisionSource: yup.mixed().optional(),
  title: yup.string().optional(),
  abstract: yup.string(),
  description: transformEmptyStringSchema.optional(),
  privacy: PublishablePrivacyControlsSchema.required(),
  publishedAt: ISO8601Schema.optional(),
  sharingMetadata: yup
    .object({
      entity: PublishableEntitySharingMetadataSchema.default(undefined),
      facebook: PublishableSharingMetadataSchema.default(undefined),
      twitter: PublishableSharingMetadataSchema.default(undefined),
    })
    .default(undefined),
  excludeFromListing: yup.boolean(),
  revertedAt: ISO8601Schema,
  revertedBy: yup.string(),
  unpublishedAt: ISO8601Schema,
  unpublishedBy: yup.string(),
  createdAt: ISO8601Schema.required(),
  createdBy: yup.string().required(),
});

export type PublishableRevisionApi = Omit<PublishableRevision, 'sharingMetadata'> & {
  sharingMetadata?: SharingMetadataApi;
};

export const PublishableRevisionApiSchema: yup.SchemaOf<PublishableRevisionApi> = PublishableRevisionSchema.concat(
  yup.object({
    sharingMetadata: yup
      .object({
        entity: PublishableEntitySharingMetadataApiSchema.default(undefined),
        facebook: PublishableSharingMetadataApiSchema.default(undefined),
        twitter: PublishableSharingMetadataApiSchema.default(undefined),
      })
      .default(undefined),
  })
);

export type PublishableRevisionWeb = Omit<PublishableRevision, 'sharingMetadata'> & {
  sharingMetadata?: SharingMetadataWeb;
};
