import { LeaderWeb } from '@nucleus/leaders';
import { Menu, MenuItemButton, usePopoverState } from '@nucleus/react-components';
import { filterNotUndefined } from '@nucleus/src-platform/data';
import {
  Contact,
  ContactType,
  EmailAddressContact,
  PhoneNumberContact,
  PostalAddressContact,
  SocialMediaLinkContact,
  UrlContact,
} from '@nucleus/types/contact';
import { ColorPaletteVariation, MediaWeb, ThemeRichTextNodeArray } from '@nucleus/types/web';
import { Text, nucleusClass } from '@nucleus/web-theme-elements';
import copy from 'copy-to-clipboard';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { usePreviousState } from '../../hooks/usePreviousState';
import { useSearchParams } from '../../hooks/useSearchParams';
import { buildThemeColorsVariationClassName } from '../../utils/styles';
import { ButtonSecondaryAlt } from '../Button';
import { GridItemBlockMedia } from '../GridItemMedia';
import { IconAngleLeft, IconAngleRight, IconShare, IconX } from '../Icons';
import { Slider } from '../Slider';
import { TabNav, TabNavItems } from '../TabNav';
import { WrappedStyledRichText } from '../WrappedRichText';
import { IconSocialMap } from '../theme-icons';
import { OVERLAY_SEARCH_PARAMS, Overlay, OverlayContext, OverlayController } from './Overlay';
import { calculateTransitionType } from './lib/transitions';

const PATHS = ['item'] as const;
type Path = (typeof PATHS)[number];
const defaultPath = PATHS[0];

const SUBPATHS = ['summary', 'about', 'contact'] as const;
type Subpath = (typeof SUBPATHS)[number];
const defaultSubpath = SUBPATHS[0];

export type LeadersOverlayProps = {
  id: string;
  title?: string;
  colorPaletteVariation?: ColorPaletteVariation;
  items: Array<LeaderWeb>;
};

export type LeadersOverlayContextValue = {
  title?: string;
  items: Array<any>;
  path: string;
  previousPath: string;
  transitionType: string;
};
const LeadersOverlayContext = React.createContext({} as LeadersOverlayContextValue);

export const LeadersOverlay = (props: LeadersOverlayProps): JSX.Element => {
  const [searchParams] = useSearchParams({ include: OVERLAY_SEARCH_PARAMS });
  const isOpen = (searchParams['overlay'] as string) === props.id;
  const path = searchParams['overlayPath'] as string;
  const itemId = searchParams['overlayDataItem'] as string;

  const routes: Record<Path, React.ReactNode> = {
    item: <LeadersOverlayPanel items={props.items} itemId={itemId} />,
  };

  const availablePaths = Object.keys(routes);
  const previousPath = usePreviousState(path) ?? '';

  // only match first segment in path
  const firstPathSegment = path?.split(',')[0];
  const currentPath = (availablePaths.includes(firstPathSegment) ? firstPathSegment : defaultPath) as Path;
  const currentScreen = routes[currentPath];
  const transitionType = calculateTransitionType(previousPath, currentPath);

  const contextValue = React.useMemo(
    (): LeadersOverlayContextValue => ({
      title: props.title,
      items: props.items,
      path: path,
      previousPath: previousPath,
      transitionType: transitionType,
    }),
    [props.items, path, previousPath, transitionType]
  );

  const colorVariation = props.colorPaletteVariation ?? (isDarkMode() ? 'variation-5' : 'variation-6');

  return (
    <OverlayController currentPath={currentPath} defaultPath={defaultPath}>
      <LeadersOverlayContext.Provider value={contextValue}>
        <Overlay
          show={isOpen}
          className={['overlay-leaders', buildThemeColorsVariationClassName(colorVariation)].join(' ')}
          id={'overlay-' + props.id}
          contentWidth="100%"
          backdropColor="var(--color-section-background)"
          hideInlineBanners
        >
          <AnimatePresence exitBeforeEnter initial={false}>
            <MotionDiv key={currentPath} initial="in" animate="center" exit="out" variants={animationVariants}>
              {currentScreen}
            </MotionDiv>
          </AnimatePresence>
        </Overlay>
      </LeadersOverlayContext.Provider>
    </OverlayController>
  );
};

const isDarkMode = () =>
  typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const animationVariants = {
  in: {
    opacity: 0,
  },
  center: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export type LeadersOverlayPanelProps = {
  items: Array<LeaderWeb>;
  itemId: string;
};

export const LeadersOverlayPanel = (props: LeadersOverlayPanelProps): JSX.Element => {
  const { title } = useContext(LeadersOverlayContext);
  const { navigateBack, navigateTo } = React.useContext(OverlayContext);

  const itemId = props.itemId;
  const itemIndex = props.items.findIndex((item) => item.id === itemId);

  if (itemIndex === -1) {
    console.error('[Leader] Item not found');
  }

  const currentItem = props.items[itemIndex] ?? {};

  const handlePrev = () => {
    const prevItemIndex = (((itemIndex - 1) % props.items.length) + props.items.length) % props.items.length;
    const prevItem = props.items[prevItemIndex];
    navigateTo('', { overlayPath: 'item,summary', overlayDataItem: prevItem.id });
  };

  const handleNext = () => {
    const nextItemIndex = (((itemIndex + 1) % props.items.length) + props.items.length) % props.items.length;
    const nextItem = props.items[nextItemIndex];
    navigateTo('', { overlayPath: 'item,summary', overlayDataItem: nextItem.id });
  };

  const handleClose = () => {
    navigateBack();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();
  };

  const handlePrevClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handlePrev();
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleNext();
  };

  const renderPrevNext = props.items?.length > 1;

  return (
    <Overlay.Panel>
      <Overlay.KeyboardControls onClose={handleClose} onPrev={handlePrev} onNext={handleNext} />
      <Overlay.PanelContent>
        <Overlay.PanelHeader
          backgroundColor="var(--color-section-background)"
          left={<StyledButton shape="square" size="medium" icon={<IconX />} onClick={handleCloseClick} />}
          center={
            <TitleContainer>
              {renderPrevNext && (
                <Counter>
                  {itemIndex + 1} / {props.items.length}
                </Counter>
              )}
              {title && <OverlayTitle>{title}</OverlayTitle>}
            </TitleContainer>
          }
          right={
            <ButtonGroup>
              <LeadersActionMenu items={props.items} itemId={itemId} />
              {renderPrevNext && (
                <PrevNextButtons>
                  <StyledButton shape="square" size="medium" icon={<IconAngleLeft />} onClick={handlePrevClick} />
                  <StyledButton shape="square" size="medium" icon={<IconAngleRight />} onClick={handleNextClick} />
                </PrevNextButtons>
              )}
            </ButtonGroup>
          }
        />
        <Overlay.PanelBody>
          <AnimatePresence exitBeforeEnter initial={false}>
            <MotionDiv key={currentItem.id} initial="in" animate="center" exit="out" variants={animationVariants}>
              <LeaderDetails leader={currentItem} />
            </MotionDiv>
          </AnimatePresence>
        </Overlay.PanelBody>
      </Overlay.PanelContent>
    </Overlay.Panel>
  );
};

interface LeadersActionMenuProps {
  items: Array<LeaderWeb>;
  itemId: string;
}

const LeadersActionMenu = (props: LeadersActionMenuProps) => {
  const { popoverProps, triggerProps } = usePopoverState();

  const [isCopied, setIsCopied] = useState(false);

  const handleShare = () => {
    const shareLink = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    copy(shareLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const itemId = props.itemId;
  const itemIndex = props.items.findIndex((item) => item.id === itemId);
  const currentItem = props.items[itemIndex] ?? {};

  const handleShareSheet = () => {
    const shareLink = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    if (navigator.share) {
      navigator.share({
        text: `${currentItem.name?.firstName} ${currentItem.name?.lastName}`,
        url: shareLink,
      });
    }
  };

  return (
    <>
      <StyledButton shape="square" size="medium" icon={<IconShare />} {...triggerProps} />
      <Menu {...popoverProps}>
        <MenuItemButton onClick={handleShare}>{isCopied ? 'Copied!' : 'Copy Link'}</MenuItemButton>
        {navigator.share !== undefined && <MenuItemButton onClick={handleShareSheet}>More Options</MenuItemButton>}
      </Menu>
    </>
  );
};

const MotionDiv = styled(motion.div)`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OverlayTitle = styled.span`
  color: var(--color-section-text);
  font-size: 1.8rem;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.4rem;
`;

const PrevNextButtons = styled(ButtonGroup)`
  gap: 1.2rem;

  @media (max-width: 600px) {
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    gap: 1.8rem;
    z-index: 1;
  }
`;

const StyledButton = styled(ButtonSecondaryAlt)`
  background-color: var(--color-section-background);
`;

const Counter = styled.span`
  font-size: 1.6rem;
  color: var(--color-section-text);
  padding: 0 1.2rem;
  opacity: 0.5;
`;

const LeaderDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  color: var(--color-section-text);
`;

const tabNavItems: TabNavItems = [
  {
    label: 'Summary',
    to: 'summary',
  },
  {
    label: 'About',
    to: 'about',
  },
  {
    label: 'Contact',
    to: 'contact',
  },
];

const LeaderDetails = ({ leader }: { leader: LeaderWeb }) => {
  const { navigateTo } = React.useContext(OverlayContext);
  const { path } = useContext(LeadersOverlayContext);

  const handleTabClick = (to: string) => {
    navigateTo('', { overlayPath: `item,${to}`, overlayDataItem: leader.id });
  };

  const routes: Record<Subpath, React.ReactNode> = {
    summary: <LeaderSummary leader={leader} />,
    about: <LeaderAbout leader={leader} />,
    contact: <LeaderContact leader={leader} />,
  };

  const availablePaths = Object.keys(routes);

  // only match second segment in path
  const secondPathSegment = path?.split(',')[1];
  const currentPath = (availablePaths.includes(secondPathSegment) ? secondPathSegment : defaultSubpath) as Subpath;

  const media: MediaWeb[] = filterNotUndefined([
    leader.images?.headshot,
    leader.images?.alternate,
    leader.images?.family,
  ]);

  return (
    <LeaderDetailsContainer className={nucleusClass('leader-detail')} key={leader.id}>
      <ContentContainer>
        <ContentMedia>
          <Slider>
            {media.map((media) => (
              <GridItemBlockMedia key={media.image?.src} media={media} aspectRatio={[1, 1]} />
            ))}
          </Slider>
        </ContentMedia>
        <ContentInfo>
          <ContentScroll>
            <StyledTabNav isSticky items={tabNavItems} activeItem={currentPath} onItemClick={handleTabClick} />
            <Section style={{ marginTop: 'calc(var(--unit-length) * 1' }}>
              <Name>
                {leader.name?.firstName} {leader.name?.lastName}
              </Name>
              <Title>{leader.title}</Title>
            </Section>
            <AnimatePresence exitBeforeEnter initial={false}>
              <MotionDiv key={currentPath} initial="in" animate="center" exit="out" variants={animationVariants}>
                {routes[currentPath]}
              </MotionDiv>
            </AnimatePresence>
          </ContentScroll>
        </ContentInfo>
      </ContentContainer>
    </LeaderDetailsContainer>
  );
};

const StyledTabNav = styled(TabNav)`
  background: var(--color-section-background);

  @media (min-width: 900px) {
    margin-top: calc(var(--unit-length) * 1);
  }
`;

const LeaderSummary = ({ leader }: { leader: LeaderWeb }) => {
  const contacts = leader.contactDetails ?? [];
  const emailContacts = contacts.filter(isEmailContact);
  const phoneContacts = contacts.filter(isPhoneContact);
  const socialContacts = contacts.filter(isSocialContact);
  const postalAddressContacts = contacts.filter(isPostalAddressContact);

  const availableContactTypes = contacts.reduce((acc, contact) => {
    if (acc.includes(contact.contactType) !== true) {
      acc.push(contact.contactType);
    }
    return acc;
  }, [] as Array<ContactType>);

  // ordered list of contact types to show in summary, until admin can set this
  const orderedContactTypes: Array<ContactType> = [
    ContactType.EmailAddress,
    ContactType.PhoneNumber,
    ContactType.SocialMediaLink,
  ];
  const contactTypes: Array<ContactType> = orderedContactTypes.filter((contactType) =>
    availableContactTypes.includes(contactType)
  );

  // pick only some contacts to show in summary
  const summaryEmailContacts = [emailContacts[0]];
  const summaryPhoneContacts = [phoneContacts[0]];
  const summaryPostalAddressContacts = [postalAddressContacts[0]];
  const summarySocialContacts = socialContacts.slice(0, 3);

  return (
    <SummaryContent>
      <BioShort>{leader.biographyShort}</BioShort>
      <ContactList>
        {contactTypes.map((contactType) => {
          switch (contactType) {
            case ContactType.EmailAddress:
              return (
                <dl key={contactType}>
                  {summaryEmailContacts.map((contact) => (
                    <>
                      <dt key={`${contact.id}-label`}>{contact.label ?? contact.emailType}:</dt>
                      <dd key={contact.id}>
                        <EmailAddress>
                          <EmailAddressLink href={`mailto:${contact.emailAddress}`}>
                            {contact.emailAddress}
                          </EmailAddressLink>
                        </EmailAddress>
                      </dd>
                    </>
                  ))}
                </dl>
              );
            case ContactType.PhoneNumber:
              return (
                <dl key={contactType}>
                  {summaryPhoneContacts?.map((contact) => (
                    <>
                      <dt key={`${contact.id}-label`}>{contact.label ?? contact.phoneType}:</dt>
                      <dd key={contact.id}>
                        <PhoneNumber>
                          <PhoneNumberLink href={formatPhoneNumberContactHref(contact)}>
                            {formatPhoneNumberContact(contact)}
                          </PhoneNumberLink>
                        </PhoneNumber>
                      </dd>
                    </>
                  ))}
                </dl>
              );
            case ContactType.PostalAddress:
              return (
                <dl key={contactType}>
                  {summaryPostalAddressContacts?.map((contact) => (
                    <>
                      <dt key={`${contact.id}-label`}>{contact.label ?? contact.postalAddressType}:</dt>
                      <dd key={contact.id}>
                        <FormattedStreetAddressContact contact={contact} />
                      </dd>
                    </>
                  ))}
                </dl>
              );
            case ContactType.SocialMediaLink:
              return (
                <dl key={contactType}>
                  <SocialIcons key={contactType}>
                    {summarySocialContacts?.map((contact) => (
                      <dd key={contact.id}>
                        <SocialIconLink
                          title={contact.label}
                          href={contact.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {IconSocialMap[contact.platform]}
                        </SocialIconLink>
                      </dd>
                    ))}
                  </SocialIcons>
                </dl>
              );
            default:
              return null;
          }
        })}
      </ContactList>
    </SummaryContent>
  );
};

const LeaderAbout = ({ leader }: { leader: LeaderWeb }) => {
  return (
    <>
      <BioLong nodes={leader.biographyLong as ThemeRichTextNodeArray} />
    </>
  );
};

const LeaderContact = ({ leader }: { leader: LeaderWeb }) => {
  const contacts = leader.contactDetails ?? [];
  const emailContacts = contacts.filter(isEmailContact);
  const phoneContacts = contacts.filter(isPhoneContact);
  const socialContacts = contacts.filter(isSocialContact);
  const postalAddressContacts = contacts.filter(isPostalAddressContact);
  const urlContacts = contacts.filter(isUrlContact);

  const availableContactTypes = contacts.reduce((acc, contact) => {
    if (acc.includes(contact.contactType) !== true) {
      acc.push(contact.contactType);
    }
    return acc;
  }, [] as Array<ContactType>);

  // ordered list of contact types to show in summary, until admin can set this
  const orderedContactTypes: Array<ContactType> = [
    ContactType.EmailAddress,
    ContactType.PhoneNumber,
    ContactType.SocialMediaLink,
    ContactType.Url,
    ContactType.PostalAddress,
  ];
  const contactTypes: Array<ContactType> = orderedContactTypes.filter((contactType) =>
    availableContactTypes.includes(contactType)
  );

  return (
    <ContactList>
      {contactTypes.map((contactType) => {
        switch (contactType) {
          case ContactType.EmailAddress:
            return (
              <dl key={contactType}>
                {emailContacts?.map((contact) => (
                  <>
                    <dt key={`${contact.id}-label`}>{contact.label ?? contact.emailType}:</dt>
                    <dd key={contact.id}>
                      <EmailAddress>
                        <EmailAddressLink href={`mailto:${contact.emailAddress}`}>
                          {contact.emailAddress}
                        </EmailAddressLink>
                      </EmailAddress>
                    </dd>
                  </>
                ))}
              </dl>
            );
          case ContactType.PhoneNumber:
            return (
              <dl key={contactType}>
                {phoneContacts?.map((contact) => (
                  <>
                    <dt key={`${contact.id}-label`}>{contact.label ?? contact.phoneType}:</dt>
                    <dd key={contact.id}>
                      <PhoneNumber>
                        <PhoneNumberLink href={formatPhoneNumberContactHref(contact)}>
                          {formatPhoneNumberContact(contact)}
                        </PhoneNumberLink>
                      </PhoneNumber>
                    </dd>
                  </>
                ))}
              </dl>
            );
          case ContactType.PostalAddress:
            return (
              <dl key={contactType}>
                {postalAddressContacts?.map((contact) => (
                  <>
                    <dt key={`${contact.id}-label`}>{contact.label ?? contact.postalAddressType}:</dt>
                    <dd key={contact.id}>
                      <FormattedStreetAddressContact contact={contact} />
                    </dd>
                  </>
                ))}
              </dl>
            );
          case ContactType.SocialMediaLink:
            return (
              <dl key={contactType}>
                {socialContacts?.map((contact) => (
                  <>
                    <dt key={`${contact.id}-label`}>
                      {contact.label !== undefined && contact.label.trim().length > 0
                        ? contact.label
                        : contact.platform}
                      :
                    </dt>
                    <dd key={contact.id}>
                      <SocialTextLink href={contact.url} target="_blank" rel="noopener noreferrer">
                        {contact.username ?? contact.url}
                      </SocialTextLink>
                    </dd>
                  </>
                ))}
              </dl>
            );
          case ContactType.Url:
            return (
              <dl key={contactType}>
                {urlContacts.map((contact) => (
                  <>
                    <dt key={`${contact.id}-label`}>{contact.label}:</dt>
                    <dd key={contact.id}>
                      <a href={contact.url} target="_blank" rel="noopener noreferrer">
                        {contact.url}
                      </a>
                    </dd>
                  </>
                ))}
              </dl>
            );
          default:
            return null;
        }
      })}
    </ContactList>
  );
};

const ContentMedia = styled.div`
  align-self: flex-start;
  height: 100%;

  & > div {
    position: sticky;
    top: 0;
  }
`;

const ContentInfo = styled.div`
  height: 100%;
`;

const ContentScroll = styled.div`
  padding-bottom: 8rem;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content;
  grid-gap: var(--unit-length);
  padding: 0 var(--overlay-padding);

  @media (min-width: 900px) {
    grid-template-rows: auto;
    grid-template-columns: 50% 50%;
  }
`;

const Name = styled(Text.H3)`
  max-width: 700px;
`;

const Title = styled(Text.L2)``;

const Section = styled.div`
  margin: 36px 0;
`;

const StyledBio = styled(Text.P3)`
  && {
    margin-top: 1em;
  }
`;

const BioShort = styled(StyledBio)`
  max-width: 440px;
  margin: 3.6rem 0;
`;

const BioLong = styled(WrappedStyledRichText(StyledBio))`
  max-width: 480px;
`;

const ContactList = styled(Text.P3)`
  && {
    margin: 0;
  }

  dl {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 2.4rem;
    padding-right: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  dt {
    font-weight: bold;
  }

  dd,
  dt {
    display: inline;
  }

  dd::before {
    content: ' ';
  }

  dd::after {
    content: '';
    display: block;
    margin: 6px;
  }
`;

function isEmailContact(contact: Contact): contact is EmailAddressContact {
  return contact.contactType === ContactType.EmailAddress;
}

function isPhoneContact(contact: Contact): contact is PhoneNumberContact {
  return contact.contactType === ContactType.PhoneNumber;
}

function isSocialContact(contact: Contact): contact is SocialMediaLinkContact {
  return contact.contactType === ContactType.SocialMediaLink;
}

function isPostalAddressContact(contact: Contact): contact is PostalAddressContact {
  return contact.contactType === ContactType.PostalAddress;
}

const isUrlContact = (contact: Contact): contact is UrlContact => contact.contactType === ContactType.Url;

const FormattedStreetAddressContact = ({ contact }: { contact: PostalAddressContact }) => {
  const { streetAddress, streetAddress2, city, state, postalCode, country } = contact;

  return (
    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
      {streetAddress && (
        <div>
          {streetAddress}
          {streetAddress2 && `, ${streetAddress2}`}
        </div>
      )}
      {(city || state || postalCode) && (
        <div>{`${city || ''}${city && state ? ', ' : ''}${state || ''}${state && postalCode ? ' ' : ''}${
          postalCode || ''
        }`}</div>
      )}
      {country && <div>{country}</div>}
    </div>
  );
};

function formatPhoneNumberContact(contact: PhoneNumberContact) {
  const { phoneNumber, countryCode, extension } = contact;
  let formattedPhone = phoneNumber;
  // Add the country code to the beginning of the formatted phone number if it exists
  if (countryCode) {
    formattedPhone = `+${countryCode} ${formattedPhone}`;
  }
  // Add the extension to the end of the formatted phone number if it exists
  if (extension) {
    formattedPhone += ` x${extension}`;
  }
  return formattedPhone;
}

function formatPhoneNumberContactHref(contact: PhoneNumberContact) {
  const { phoneNumber, countryCode, extension } = contact;
  return `tel:${countryCode ?? ''}${phoneNumber}${extension ? ';ext=' + extension : ''}`;
}

const PhoneNumber = styled.span``;

const PhoneNumberLink = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const EmailAddress = styled.span``;

const EmailAddressLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const SocialTextLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const SocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin: 1em 0;

  & > * {
    width: 4.6rem;
    height: 4.6rem;
  }
`;

const SocialIconLink = styled.a`
  color: inherit;
`;

const SummaryContent = styled.div`
  ${ContactList} {
    dl {
      margin: 0.5em 0;
    }
  }
`;
