import { objectKeys } from '@nucleus/lib-shape';
import React, { createContext } from 'react';
import { getFunction } from '@nucleus/lib-function';
import { filterNotUndefined } from '@nucleus/src-platform/data';

type WebFontDefinition = Record<string, any>;

const DefaultGoogleFontApi = 'https://fonts.googleapis.com/css';

export type ServerFontContextValue = {
  setDefinitions: (definition: WebFontDefinition) => void;
};

export const ServerFontContext = createContext<ServerFontContextValue>(null as unknown as ServerFontContextValue);

export interface ServerFontLoaderOptions {
  api?: string;
}

const googleSafe = (value: string) => value.replace(/ /g, '+');

export const ServerFontLoader = ({ api = DefaultGoogleFontApi }: ServerFontLoaderOptions = {}): {
  collectFonts: (children: React.ReactNode) => JSX.Element;
  getFontUrls: () => Array<string>;
} => {
  let definitions: WebFontDefinition = {};

  const collectFonts = (children: React.ReactNode): JSX.Element => {
    const context: ServerFontContextValue = {
      setDefinitions: (newDefinitions: WebFontDefinition) => {
        definitions = newDefinitions;
      },
    };

    return <ServerFontContext.Provider value={context}>{children}</ServerFontContext.Provider>;
  };

  // TODO: find a way to use `webfontloader` to generate urls... currently not exported from the package
  const getGoogleFontUrl = (families: Array<string>) => {
    if (families.length === 0) {
      return;
    }

    const fontImport = definitions.google.families.map(googleSafe).join('%7C');

    return `${api}?family=${fontImport}`;
  };

  const fontLinkMap = {
    google: getGoogleFontUrl,
  };

  const getFontUrls = () => {
    const tags = objectKeys(definitions).map((key) => getFunction(fontLinkMap, key)(definitions[key]));

    return filterNotUndefined(tags);
  };

  return {
    collectFonts: collectFonts,
    getFontUrls: getFontUrls,
  };
};
