import { ThemeImageElement as ImageElement, ThemeLinkElement as LinkElement } from '@nucleus/types/web';
import React from 'react';
import {
  RichTextCustomRichTextComponentMap,
  RichTextElementComponentMap,
  RichTextRegistryProvider,
} from './RichTextRegistry';

const Image = ({ node }: { children: React.ReactNode; node: ImageElement }) => {
  const srcSet = node.srcSet
    ?.reduce((acc: Array<string>, cur) => {
      const item = [cur.src, cur.dimensions.width + 'w', cur.dimensions.height + 'h']
        .filter((item) => item !== undefined)
        .join(' ');

      acc.push(item);

      return acc;
    }, [])
    .join(', ');

  return <img src={node.src} srcSet={srcSet} width={node.dimensions?.width} height={node.dimensions?.height} />;
};

const Link = ({ children, node }: { children: React.ReactNode; node: LinkElement }) => {
  return <a href={node.href}>{children}</a>;
};

const DefaultElementRegistry: RichTextElementComponentMap = {
  action: React.Fragment,
  image: Image,
  parameter: React.Fragment,
  link: Link,
  'heading-one': 'h1',
  'heading-two': 'h2',
  'heading-three': 'h3',
  'heading-four': 'h4',
  'heading-five': 'h5',
  'heading-six': 'h6',
  div: 'div',
  paragraph: 'p',
  span: 'span',
  'block-quote': 'blockquote',
  'bulleted-list': 'ul',
  'numbered-list': 'ol',
  'list-item': 'li',
};

const DefaultTextRegistry: RichTextCustomRichTextComponentMap = {
  bold: 'b',
  italic: 'i',
  underline: 'u',
  strike: 's',
  code: 'code',
  highlight: 'mark',
};

interface RichTextDefaultContextProps {
  children: React.ReactNode;
}

export const RichTextDefaultProvider = (props: RichTextDefaultContextProps): JSX.Element => {
  return (
    <RichTextRegistryProvider registerElements={DefaultElementRegistry} registerText={DefaultTextRegistry}>
      {props.children}
    </RichTextRegistryProvider>
  );
};
