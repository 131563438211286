/* eslint-disable react-hooks/rules-of-hooks */
// TODO: Remove eslint-disable once the issue is resolved
import { useEventListener } from '@nucleus/react-components/src/hooks/useEventListener';
import { PageWeb } from '@nucleus/types/web';
import { RichTextParameterContext } from '@nucleus/web-theme-elements';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthState } from '../../../lib/auth';
import { fetchWithAuth } from '../../../lib/fetch';
import { SiteContext } from '../../site/SiteContext';
import { DataResourceContext, DataResourceProviderProps } from '../dataResourceContext';

declare global {
  interface ReactEventMap {
    NucleusUserSignedIn: CustomEvent<unknown>;
    NucleusUserSignedOut: CustomEvent<unknown>;
  }
}

interface DynamicResourceDataOptions {
  prefix?: string;
}

interface DynamicResourceData {
  pages: Array<PageWeb>;
}

const redirectStatuses = [301, 302];

const isLocationBody = (body: unknown): body is { location: string } =>
  typeof body === 'object' && body !== null && 'location' in body;

export const DynamicResourceProvider = (
  preloadedData: DynamicResourceData,
  options: DynamicResourceDataOptions = {}
): ((props: DataResourceProviderProps) => JSX.Element) => {
  const pageMap = new Map<string, PageWeb>();

  preloadedData.pages.forEach((page) => {
    pageMap.set(page.slug, page);
  });

  const resourceProvider = (props: DataResourceProviderProps): JSX.Element => {
    const navigate = useNavigate();
    const slug = props.path.replace(/^\/+/, '');
    const { getSite } = useContext(SiteContext);
    const site = getSite();

    const [page, setPage] = useState<PageWeb | undefined>(pageMap.get(slug));
    const [loading, setLoading] = useState(page === undefined);
    const [authStatePromise, setAuthStatePromise] = useState(getAuthState());

    useEffect(() => {
      setLoading(true);
      let cancelled = false;

      const fetchPage = async (slug: string): Promise<void> => {
        const fetchMap = {
          authenticated: () => fetchWithAuth(`${options.prefix ?? ''}/_api/private/pages?slug=${slug}`),
          unauthenticated: () => fetch(`${options.prefix ?? ''}/_api/public/pages?slug=${slug}`),
        };
        const authState = await authStatePromise;
        const response = await fetchMap[authState]();
        const data = (await response.json()) as { page: PageWeb };

        if (redirectStatuses.includes(response.status) && isLocationBody(data)) {
          navigate(data.location, { replace: true });
          return;
        }

        setPage(data.page);
        pageMap.set(data.page.slug, data.page);

        if (cancelled === false) {
          setLoading(false);
        }
      };

      fetchPage(slug);

      return () => {
        cancelled = true;
      };
    }, [slug, site.id, authStatePromise]);

    useEventListener('NucleusUserSignedIn', () => setAuthStatePromise(getAuthState()));

    const context: DataResourceContext = {
      page: page,
      loading: loading,
    };

    return (
      <RichTextParameterContext parameters={page?.parameters}>
        <DataResourceContext.Provider value={context}>{props.children}</DataResourceContext.Provider>
      </RichTextParameterContext>
    );
  };

  return resourceProvider;
};
