import { ISO8601 } from '../iso8601';
import { Image } from '../media/image';
import { PresetMedia, PresetMediaKeys } from './media';

type AnyObject = Record<string, unknown>;

export interface Style extends Record<string, unknown> {
  theme: AnyObject;
}

// COLORS
export interface ColorConfig {
  hex: string;
  h: number;
  s: number;
  l: number;
  a?: number;
}

export const ColorPaletteKey = {
  Color1: 'color1',
  Color2: 'color2',
  Color3: 'color3',
  Color4: 'color4',
  Color5: 'color5',
  Color6: 'color6',
  ColorLight: 'colorLight',
  ColorDark: 'colorDark',
} as const;

export type ColorPaletteKey = (typeof ColorPaletteKey)[keyof typeof ColorPaletteKey];

export const ColorPaletteKeys = Object.values(ColorPaletteKey);

export type ColorPaletteColors = Record<ColorPaletteKey, ColorConfig>;

export interface ColorPaletteConfig {
  createdBy?: string;
  lightMode: ColorPaletteColors;
  sourcePalette?: string;
  updatedAt?: ISO8601;
}

export interface ColorPalette {
  description?: string;
  title?: string;
  readonly createdAt?: ISO8601;
  readonly createdBy?: string;
  readonly id?: string;
  lightMode: ColorPaletteColors;
  readonly ownerId?: string;
  updatedAt?: ISO8601;
  updatedBy?: string;
}

// FONTS
export interface FontConfig {
  capitalization: 'none' | 'everyWord' | 'firstWord' | 'lowercase' | 'uppercase';
  decoration: 'none' | 'underline';
  fontFamily: string;
  fontSizePx: number;
  fontStyle: 'normal' | 'italic';
  fontWeight: string;
  forceBold: boolean;
  letterSpacingEm: number;
  lineHeightEm: number;
  source: string;
}

export const FontPaletteKey = {
  Headline1: 'headline1',
  Headline2: 'headline2',
  Headline3: 'headline3',
  Headline4: 'headline4',
  Headline5: 'headline5',
  Headline6: 'headline6',
  Paragraph1: 'paragraph1',
  Paragraph2: 'paragraph2',
  Paragraph3: 'paragraph3',
  Paragraph4: 'paragraph4',
  Title1: 'title1',
  Title2: 'title2',
  Description1: 'description1',
  Description2: 'description2',
  Blockquote1: 'blockquote1',
  Blockquote2: 'blockquote2',
  Label1: 'label1',
  Label2: 'label2',
  Label3: 'label3',
  Label4: 'label4',
  Label5: 'label5',
  Label6: 'label6',
  Nav1: 'navigation1',
  Nav2: 'navigation2',
  Nav3: 'navigation3',
  Nav4: 'navigation4',
  Nav5: 'navigation5',
  Button1: 'button1',
  Button2: 'button2',
  Button3: 'button3',
  Button4: 'button4',
} as const;

export type FontPaletteKey = (typeof FontPaletteKey)[keyof typeof FontPaletteKey];

export const FontPaletteKeys = Object.values(FontPaletteKey);

export type FontPalette = Record<FontPaletteKey, FontConfig>;

export interface FontPaletteConfig {
  baseScale: number;
  scale: number;
  id: string;
  styles: FontPalette;
  updatedAt: ISO8601;
}

export interface FontPack {
  baseScale: number;
  body: string;
  emoji?: string;
  featured: boolean;
  headline: string;
  nucleusOptimized: boolean;
  readonly createdAt?: ISO8601;
  readonly createdBy?: string;
  readonly id: string;
  readonly ownerId?: string;
  scale?: number;
  styles: Record<FontPaletteKey, Partial<FontConfig>>;
  updatedAt?: ISO8601;
  updatedBy?: string;
}

// BUTTONS
export const ButtonPaletteKey = {
  Button1: 'button1',
  Button2: 'button2',
  Button3: 'button3',
  Button4: 'button4',
} as const;

export const ButtonCornerStyleMap = {
  CornerStyle1: 'cornerStyle1',
  CornerStyle2: 'cornerStyle2',
  CornerStyle3: 'cornerStyle3',
  CornerStyle4: 'cornerStyle4',
};

export type ButtonCornerStyle = (typeof ButtonCornerStyleMap)[keyof typeof ButtonCornerStyleMap];

export const ButtonCornerStyles = Object.values(ButtonCornerStyleMap);

export interface ButtonConfig {
  cornerStyle: ButtonCornerStyle;
}

export type ButtonPaletteKey = (typeof ButtonPaletteKey)[keyof typeof ButtonPaletteKey];

export const ButtonPaletteKeys = Object.values(ButtonPaletteKey);

export type ButtonPalette = Record<ButtonPaletteKey, ButtonConfig | undefined>;

export interface ButtonPaletteConfig {
  styles: ButtonPalette;
}

// LOOK CONFIG
export interface LookConfig {
  colors?: ColorPaletteConfig;
  buttons?: ButtonPaletteConfig;
  fonts?: FontPaletteConfig;
  placeholders?: Record<string, Image>;
  presetMedia?: Record<PresetMediaKeys, PresetMedia>;
}

export type LookConfigs = Record<Exclude<string, undefined>, LookConfig>;
