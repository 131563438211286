import { Editor, Element, Node, Path, Transforms } from 'slate';
import { BodyElement, ParagraphElement, TitleElement } from '../types/CustomElement';

export const withForcedLayout = (editor: Editor): Editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length <= 1 && Editor.string(editor, [0, 0]) === '') {
        createTitleElement(editor, path.concat(0));
      }

      if (editor.children.length < 2) {
        createBodyElement(editor, path.concat(1));
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        const slateIndex = childPath[0];

        switch (slateIndex) {
          case 0:
            if (Element.isElement(child) && child.type !== 'title') {
              Transforms.removeNodes(editor, { at: childPath });
              createTitleElement(editor, childPath);
            }
            break;
          case 1:
            if (Element.isElement(child) && child.type !== 'body') {
              Transforms.removeNodes(editor, { at: childPath });
              createBodyElement(editor, childPath);
            }
            break;
          default:
            Transforms.removeNodes(editor, { at: childPath });
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const createTitleElement = (editor: Editor, path: Path) => {
  const title: TitleElement = {
    type: 'title',
    children: [{ text: '' }],
    placeholder: 'Add a title…?',
  };
  Transforms.insertNodes(editor, title, {
    at: path,
    select: true,
  });
};

const createBodyElement = (editor: Editor, path: Path) => {
  const paragraph: ParagraphElement = {
    type: 'paragraph',
    children: [{ text: '' }],
  };

  const body: BodyElement = {
    type: 'body',
    children: [paragraph],
    placeholder:
      'You can type as much or as little as you want. And when you’re done, you can email the notes to yourself, download them, or share.',
  };
  Transforms.insertNodes(editor, body, { at: path });
};
