/**
 * Convert single items to a one item array and return arrays unchanged.
 * This is different from Array.from in that it will not blow up your strings and other iterables.
 * Null will be converted to an array with a single null in it.
 * Undefined will be converted to an empty array.
 *
 * @example
 * safeToArray('Hello')
 * // => ['Hello']
 *
 * @example
 * safeToArray(null)
 * // => [null]
 *
 * @example
 * safeToArray(undefined)
 * // => []
 *
 * @example
 * safeToArray([1, undefined, 2])
 * // => [1, undefined, 2]
 *
 * @example
 * safeToArray({greeting: 'Ciao'});
 * // => [{greeting: 'Ciao'}]
 */
export const safeToArray = <T>(value: T | Array<T> | undefined): Array<T> => {
  if (value === undefined) {
    return [];
  }

  if (Array.isArray(value) !== true) {
    value = [value as T];
  }

  return value as Array<T>;
};
