import React, { useContext } from 'react';
import { FontProvider } from './components/fonts/FontProvider';
import { LauncherLoader } from './components/launcher/LauncherLoader';
import { DataResourceSeoTags } from './components/markup/PageSeoTags';
import { RichTextDefaultProvider } from './components/markup/RichTextDefaultContext';
import { Routes } from './components/routes/Routes';
import { SectionsProvider } from './components/sections/SectionsProvider';
import { SiteContext } from './components/site/SiteContext';
import { SiteProvider } from './components/site/SiteProvider';
import { StacksProvider } from './components/stacks/StacksProvider';
import { LookConfigProvider } from './components/lookconfig/LookConfigProvider';
import { UserProvider } from './components/user/UserProvider';
import { useAuthBroadcast } from './hooks/useAuthentication';
import { usePageSections } from './hooks/usePageSections';

type WebThemeProps = {
  children: React.ReactNode;
};

export const WebTheme = (props: WebThemeProps): JSX.Element => {
  useAuthBroadcast();

  return (
    <UserProvider>
      <SiteProvider>
        <LauncherLoader />
        <SiteStructure>
          <>{props.children}</>
        </SiteStructure>
      </SiteProvider>
    </UserProvider>
  );
};

const SiteStructure = (props: WebThemeProps) => {
  const { getSite } = useContext(SiteContext);
  const site = getSite() ?? {};
  const { stacks, lookConfig } = site;

  const stackSections = stacks.reduce((acc, stack) => ({ ...acc, ...stack.sections }), {});

  return (
    <LookConfigProvider /* site */ value={lookConfig}>
      <FontProvider>
        <StacksProvider stacks={stacks}>
          <SectionsProvider sections={stackSections}>
            <Routes>
              <PageStructure>
                <>{props.children}</>
              </PageStructure>
            </Routes>
          </SectionsProvider>
        </StacksProvider>
      </FontProvider>
    </LookConfigProvider>
  );
};

const PageStructure = (props: WebThemeProps) => {
  const sections = usePageSections();

  // TODO: Build theme context value for the page
  const lookConfig = {};

  return (
    <LookConfigProvider /* site + page */ value={lookConfig}>
      <DataResourceSeoTags />
      <SectionsProvider /*sections data*/ sections={sections}>
        <RichTextDefaultProvider>
          <>{props.children}</>
        </RichTextDefaultProvider>
      </SectionsProvider>
    </LookConfigProvider>
  );
};
