import { isNonNullable } from '@nucleus/lib-shape';
import { Menu, MenuButton, MenuItem, MenuItemButton, usePopoverState } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    Select?: {
      background?: string;
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
    };
  }
}

interface OptionProps<T = string> {
  children: string;
  value: T;
}

export interface SelectProps<T extends string = string>
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  label?: string;
  value?: T;
  onChange: (value: T) => void;
  children?: React.ReactNode | React.ReactElement<OptionProps<T>> | React.ReactElement<OptionProps<T>>[];
}

export const Select = <T extends string>(props: SelectProps<T>): JSX.Element => {
  const { triggerProps, popoverProps } = usePopoverState();

  const handleOptionClick = (value: T) => {
    props.onChange?.(value);
    popoverProps.onClose();
  };

  const optionLabel = getOptionLabel(props.children, props.value);

  const renderLabel = props.label !== undefined;
  const renderValue = optionLabel !== null;

  return (
    <>
      <Container {...triggerProps} className={props.className}>
        <div>
          {renderLabel && (
            <Label>
              {props.label}
              {renderValue && <Value>: </Value>}
            </Label>
          )}
          {renderValue && (
            <Value>
              <ValueText>{optionLabel}</ValueText>
            </Value>
          )}
        </div>
        <MenuButton activated={triggerProps.activated} />
      </Container>
      <Menu
        {...popoverProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {React.Children.map(props.children, (child) => {
          if (React.isValidElement(child)) {
            return <Option onClick={() => handleOptionClick(child.props.value)}>{child.props.children}</Option>;
          }

          if (typeof child === 'number' || typeof child === 'string') {
            return <Option onClick={() => handleOptionClick(child as T)}>{child}</Option>;
          }

          return null;
        })}
      </Menu>
    </>
  );
};

const Option = (props: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  return (
    <MenuItem>
      <MenuItemButton {...props} />
    </MenuItem>
  );
};

// Extracted to fix highlighting issues with multi-line attrs method.
const ContainerPropFactory = (props: any) => ({
  className: [props.theme._reactComponents.Select?.className, props.className].filter(isNonNullable).join(' '),
});

const Container = styled.div.attrs(ContainerPropFactory)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1em;
  font-size: ${({ theme }) => theme._reactComponents.Select?.fontSize};
  line-height: ${({ theme }) => theme._reactComponents.Select?.lineHeight};
  letter-spacing: -0.5;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme._reactComponents.Select?.background};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '25px',
  })};
  color: ${({ theme }) => theme._reactComponents.Select?.color};
  margin: 0;
  padding: 0 12px 0 18px;
  gap: 6px;
  height: 50px;
  cursor: pointer;
`;

const Label = styled.span.attrs(() => ({ className: 'SelectLabel' }))``;

const Value = styled.span.attrs(() => ({ className: 'SelectValue' }))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ValueText = styled.span.attrs(() => ({ className: 'SelectValueText' }))`
  opacity: 0.8;
`;

const getOptionLabel = (
  children: React.ReactNode | React.ReactElement<OptionProps> | React.ReactElement<OptionProps>[],
  value?: string
): React.ReactNode => {
  let label: React.ReactNode = null;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.props.value === value) {
      label = child.props.children;
    }
  });
  return label;
};
