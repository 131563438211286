import type { CalendarRecurrence } from '@nucleus/calendar';
import { filterNotUndefined } from '@nucleus/src-platform/data';
import { getFunction } from '@nucleus/lib-function';
import { objectKeys } from '@nucleus/lib-shape';
import { pick as _pick } from 'lodash';

type OptionalKeyMap<T, R> = {
  [K in keyof T]?: R;
};

const addNumberSuffix = (number: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const mod100 = number % 100;

  return [number, suffixes[(mod100 - 20) % 10] || suffixes[mod100] || suffixes[0]].join('');
};

const dayMap: Record<string, string> = {
  SU: 'Sunday',
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
};

const intervalMap = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
};

const joinItems = (items: string[]): string => {
  if (items.length > 1) {
    const [last, secondToLast] = [...items].reverse();
    const end = `${secondToLast} and ${last}`;
    items = [...items.slice(0, items.length - 2), end];
  }

  return items.join(', ');
};

const byDay = (recurring: CalendarRecurrence): string | undefined => {
  const { byDay } = recurring;
  if (byDay === undefined) {
    return undefined;
  }

  byDay.sort((a, b) => {
    const aIndex = objectKeys(dayMap).indexOf(a);
    const bIndex = objectKeys(dayMap).indexOf(b);
    return aIndex - bIndex;
  });

  const dayReturn = byDay.map((dayKey) => {
    const number = dayKey.length > 2 ? addNumberSuffix(Number(dayKey.slice(0, -2))) : undefined;
    const day = dayMap[dayKey.slice(-2)];
    return filterNotUndefined([number, day]).join(' ');
  });

  return joinItems(dayReturn);
};

const byMonthDay = (recurring: CalendarRecurrence): string | undefined => {
  const { byMonthDay } = recurring;
  if (byMonthDay === undefined) {
    return undefined;
  }

  const numbers = byMonthDay.sort().map((day) => addNumberSuffix(Number(day)));

  return joinItems(numbers);
};

const byFunctionMap: OptionalKeyMap<CalendarRecurrence, (recurring: CalendarRecurrence) => string | undefined> = {
  byDay: byDay,
  byMonthDay: byMonthDay,
};

const prefixed = (value: string): string => `Yes, ${value}`;

export const recurringToReadable = (recurring: CalendarRecurrence): string => {
  if (recurring === undefined) {
    return 'No';
  }

  let frequency = recurring.frequency;

  if (recurring.interval !== undefined) {
    const suffix = recurring.interval > 1 ? 's' : '';
    const interval = recurring.interval > 1 ? recurring.interval : undefined;
    frequency = filterNotUndefined(['every', interval, `${intervalMap[recurring.frequency]}${suffix}`]).join(' ');
  }

  const processable = _pick(recurring, ...objectKeys(byFunctionMap));

  const by = filterNotUndefined(
    objectKeys(processable).reduce((acc: Array<string | undefined>, key) => {
      if (key === undefined) {
        return acc;
      }
      return [...acc, getFunction(byFunctionMap, String(key), () => '')(recurring)];
    }, [])
  );

  if (by.length === 0) {
    return prefixed(frequency);
  }

  return prefixed(`${frequency} on ${by.join(', ')}`);
};
