import { nucleusClass } from '@nucleus/web-theme-elements';
import { media, positionToFlexStyles, Text } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Body, Byline, Headline, Label, Labels, Overline } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { BlockProps } from '../types/component';
import { InfoContent, InfoSizePartition, InfoPosition, InfoSizeGroup, SizingByElementAndWidth } from './Base';

export const BlockCover = (props: BlockProps): JSX.Element => {
  const headlineMaxWidth = props.blockHeadlineMaxWidth ?? 'medium';
  const bodyMaxWidth = props.blockBodyMaxWidth ?? headlineMaxWidth;
  const buttonsAlignment = props.blockButtonsAlignment;
  const buttonsMaxWidth = props.blockButtonsMaxWidth ?? headlineMaxWidth;
  const buttonsLayout = props.blockButtonsLayout;
  const buttonsWidth = props.blockButtonsWidth;

  const renderButtons = props.block?.buttons !== undefined && props.block.buttons.length > 0;
  const renderByline = props.block?.byline !== undefined;
  const renderHeadline = props.block?.headline !== undefined;
  const renderLabels = props.block?.labels !== undefined;
  const renderOverline = props.block?.overline !== undefined;
  const renderBody = props.block?.body !== undefined;

  const renderChildren = props.children !== undefined;
  const renderHeadlineElements = renderByline === true || renderHeadline === true || renderLabels === true;

  return (
    <InfoContent
      className={classNames([props.className, nucleusClass('block-cover')])}
      headlineAlignment={props.block?.headlineAlignment ?? props.blockHeadlineAlignment}
      bodyAlignment={props.block?.bodyAlignment ?? props.blockBodyAlignment}
    >
      <InfoPosition blockInfoPosition={props.blockInfoPosition}>
        <ContentLayout>
          <StyledInfoSizePartition sizingConfiguration={SizingByElementAndWidth.Headline[headlineMaxWidth]}>
            <CoverSideLeft>
              {renderHeadlineElements && (
                <InfoSizeGroup
                  alignment={props.block?.headlineAlignment ?? props.blockHeadlineAlignment}
                  parentSizingConfiguration={SizingByElementAndWidth.Headline[headlineMaxWidth]}
                  sizingConfiguration={SizingByElementAndWidth.Headline[headlineMaxWidth]}
                >
                  <Headline nodes={props.block?.headline} />
                  <Byline nodes={props.block?.byline} />
                  {props.block?.labels && (
                    <Labels>
                      {props.block?.labels?.map((label, index) => <Label key={index}>{label.title}</Label>)}
                    </Labels>
                  )}
                </InfoSizeGroup>
              )}
            </CoverSideLeft>
            <CoverSideRight>
              {(renderOverline || renderBody) && (
                <InfoSizeGroup
                  alignment={props.block?.bodyAlignment ?? props.blockBodyAlignment}
                  parentSizingConfiguration={SizingByElementAndWidth.Body[bodyMaxWidth]}
                  sizingConfiguration={SizingByElementAndWidth.Body[bodyMaxWidth]}
                >
                  {renderOverline && <Overline nodes={props.block?.overline} />}
                  {renderBody && <Body nodes={props.block?.body} />}
                </InfoSizeGroup>
              )}
              {renderButtons && (
                <InfoSizeGroup
                  alignment={buttonsAlignment}
                  parentSizingConfiguration={SizingByElementAndWidth.Button[buttonsMaxWidth]}
                  sizingConfiguration={SizingByElementAndWidth.Button[buttonsMaxWidth]}
                >
                  <InfoButtons
                    buttons={props.block?.buttons}
                    buttonsLayout={buttonsLayout}
                    buttonsWidth={buttonsWidth}
                    buttonsMaxWidth={buttonsMaxWidth}
                    buttonsAlignment={buttonsAlignment}
                  />
                </InfoSizeGroup>
              )}
            </CoverSideRight>
          </StyledInfoSizePartition>
          {renderChildren && (
            <StyledInfoSizePartition sizingConfiguration={SizingByElementAndWidth.Headline[headlineMaxWidth]}>
              {props.children}
            </StyledInfoSizePartition>
          )}
        </ContentLayout>
      </InfoPosition>
    </InfoContent>
  );
};

const CoverSide = styled.div`
  flex: 0 1 auto;
`;

const CoverSideLeft = styled(CoverSide)`
  justify-items: end;

  .${Text.ClassName.headline1},
    .${Text.ClassName.headline2},
    .${Text.ClassName.headline3},
    .${Text.ClassName.headline4},
    .${Text.ClassName.headline5},
    .${Text.ClassName.headline6} {
    margin-top: 0;
  }
`;

const CoverSideRight = styled(CoverSide)`
  margin-top: 2rem; // this is an arbitrary value to make them align.

  & > div:first-child {
    div:first-of-type${Overline}, div:first-of-type${Body}, div:first-of-type${InfoButtons} {
      margin-top: 0;
    }
  }

  .${Text.ClassName.label1},
    .${Text.ClassName.label2},
    .${Text.ClassName.label3},
    .${Text.ClassName.label4},
    .${Text.ClassName.label5},
    .${Text.ClassName.label6} {
    margin-top: 0;
  }
`;

const StyledInfoSizePartition = styled(InfoSizePartition)`
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  align-items: flex-start;
  justify-content: center;

  ${CoverSideRight} {
    width: 100%;
    max-width: max(50%, 24rem);
  }

  ${media.smallDesktopAndUp`
    flex-direction: row;
    gap: 6rem;

    ${CoverSideLeft} {
      max-width: calc(60% - (6rem * 0.51));
    }
    ${CoverSideRight} {
      width: 100%;
      max-width: min(calc(40% - (6rem * 0.51)), 50rem);
    }
  `}
`;

const ContentLayout = styled.div`
  ${() => positionToFlexStyles('center', true)};

  display: flex;
  flex-direction: column;
`;
