import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import styled, { css } from 'styled-components';

const isStuck = booleanPropHelperFactory('isStuck');

interface NavbarProps {
  backgroundBehavior?: 'auto' | 'on' | 'off';
  isSticky?: boolean;
  isStuck?: boolean;
}

export const Navbar = styled.div<NavbarProps>`
  padding: var(--unit-length);
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  color: var(--color-section-text);
  transition-property: background, box-shadow;
  transition-duration: 100ms, 150ms;
  transition-timing-function: ease-in;

  ${({ backgroundBehavior }) => {
    if (backgroundBehavior === 'on') {
      return css<NavbarProps>`
        position: ${({ isSticky }) => (isSticky ? 'absolute' : 'static')};
        background: var(--color-navigation-background);
        color: var(--color-section-text);
      `;
    }

    if (backgroundBehavior === 'off') {
      return css`
        background: none;
      `;
    }
  }}

  ${isStuck(css`
    --color-logo: var(--color-logo-navigation);
    position: fixed;
    background: var(--color-navigation-background);
    color: var(--color-section-text);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  `)}

  ${({ backgroundBehavior }) => {
    if (backgroundBehavior === 'off') {
      return css`
        background: none;
        box-shadow: none;
      `;
    }
  }}
`;

export const NavbarLeft = styled.div`
  display: flex;
  position: relative;
  flex: 1 0 33.33%;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
`;

export const NavbarRight = styled.div`
  display: flex;
  position: relative;
  flex: 1 0 66.66%;
  max-width: 66.66%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  color: inherit;
`;
