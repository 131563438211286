import {
  getSSMClient,
  newParameterStoreService,
  newParameterValueService,
  newSSMParameterRepository,
} from '@nucleus/lib-parameter-store';
import { NameGeneratorFactory } from './stage';

const repository = newSSMParameterRepository(getSSMClient());

const proxyParameterStoreService = newParameterStoreService(repository, {
  nameGenerator: NameGeneratorFactory('NucleusProxy'),
  allowNotFound: true,
});

/**
 * SSM Parameter /${STAGE}/NucleusProxy/CertsTable
 * can be undefined as it's set by the https://github.com/foyyay/NucleusProxy deployment
 */
export const CertificateTableName = newParameterValueService(proxyParameterStoreService, 'CertsTable');

/**
 * /${STAGE}/NucleusProxy/RedirectServer
 * can be undefined as it's set by the https://github.com/foyyay/NucleusProxy deployment
 */
export const RedirectServerIp = newParameterValueService(proxyParameterStoreService, 'RedirectServer');
