import { SermonHub } from '@nucleus/hub-sermons';
import { ContentData, ContentType } from '@nucleus/types/web';
import React, { useContext } from 'react';
import { SiteContext } from '../site/SiteContext';
import { ContentLoaderProps } from './ContentLoader';
import { RichTextParameterContext } from '@nucleus/web-theme-elements';

const HubMap: Record<string, (props: any) => JSX.Element | null> = {
  sermon: SermonHub,
};

type HubContent = Extract<ContentData, { type: 'hub' }>;
export const isHub = (data: ContentLoaderProps['data']): data is HubContent => data.type === ContentType.Hub;

interface ContentHubLoaderProps {
  data: Extract<ContentData, { type: typeof ContentType.Hub }>;
}
export const ContentHubLoader = ({ data }: ContentHubLoaderProps): JSX.Element | null => {
  const { getSite } = useContext(SiteContext);
  const site = getSite();

  const hub = site.hubs.find((hub) => hub.id === data.payload.hubId);

  if (hub === undefined) {
    console.error('Hub not found', data.payload.hubId);
    return null;
  }

  const Component = HubMap[hub.type];
  if (Component === undefined) {
    console.error('Hub component not found', hub);
    return null;
  }

  return (
    <RichTextParameterContext parameters={hub.parameters}>
      <Component {...hub} />
    </RichTextParameterContext>
  );
};
