import {
  Menu,
  MenuItem,
  MenuItemButton,
  MenuList,
  useIsomorphicLayoutEffect,
  usePopoverState,
} from '@nucleus/react-components';
import { SectionAction, SectionActionType } from '@nucleus/types/web';
import { NavigationItemHosted } from '@nucleus/web-hosting';
import { useAuthentication } from '@nucleus/web-theme';
import { SIZE } from '@nucleus/web-theme-elements';
import { DropdownMenu } from '@nucleus/web-theme-elements/src/components/themes/life/v1/DropdownMenu';
import { Auth } from 'aws-amplify';
import { sum as _sum } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useActionProps } from '../hooks/useActionProps';
import { HeaderBlockLayoutProps } from '../sections/Header/HeaderLayout';
import { ButtonNavPrimary, ButtonNavSecondary } from './Button';
import { IconHamburgerMenu, IconX } from './Icons';

const MobileBreakpoint = 600;

export type NavigationTraditionalProps = HeaderBlockLayoutProps;

/**
 * Renders a Traditional Styled Navigation component, featuring a list of Nav Items and Action Items,
 * with a responsive design that collapses into a mobile menu.
 *
 * Nav > NavList > ListItem > NavItem > NavMenuButton ?> NavMenu > MenuItem
 *
 * @param props {NavigationTraditionalProps}
 * @returns {JSX.Element | null}
 */
export const NavigationTraditional = (props: NavigationTraditionalProps): JSX.Element | null => {
  if (props.navigation === undefined || props.navigation.items.length < 0) {
    return null;
  }

  return (
    <Nav>
      <NavList actions={props.blocks[0].buttons} items={props.navigation.items} />
    </Nav>
  );
};

interface NavListProps {
  actions?: SectionAction[];
  items: NavigationItemHosted[];
}

/**
 * Renders a list of Navigation Items, with a responsive design that collapses into a mobile menu.
 * @param props {NavListProps}
 * @returns {JSX.Element}
 */
const NavList = (props: NavListProps): JSX.Element => {
  const [maxItems, ref] = useResponsiveNavRef(props.items);

  const showMoreButton = maxItems === Infinity || (maxItems !== 0 && props.items.length > maxItems);
  const showMobileMenu = maxItems === Infinity || maxItems === 0;

  return (
    <UnorderedList ref={ref}>
      {props.items.slice(0, maxItems).map((item) => (
        <ListItem key={item.id}>
          <NavItem {...item} />
        </ListItem>
      ))}
      {showMoreButton && (
        <ListItem>
          <NavMenuButton label="More">
            {props.items.slice(maxItems).map((item) => (
              <NavMenuItem key={item.id} item={item} />
            ))}
          </NavMenuButton>
        </ListItem>
      )}
      {props.actions?.map((action) => (
        <ListItem key={action.id}>
          <ActionItem action={action} />
        </ListItem>
      ))}
      {showMobileMenu && (
        <ListItem>
          <NavMenuPrimaryButton>
            {props.items.map((item) => (
              <NavMenuItem key={item.id} item={item} />
            ))}
          </NavMenuPrimaryButton>
        </ListItem>
      )}
    </UnorderedList>
  );
};

const useResponsiveNavRef = (items: NavigationItemHosted[]) => {
  const [maxItems, setMaxItems] = useState(Infinity);
  const elementRef = useRef<HTMLUListElement>(null);
  const childrenWidths = useRef<number[]>([]);

  const measureChildren = useCallback(() => {
    if (elementRef.current === null) {
      return;
    }

    const children = [...elementRef.current.children];

    children.forEach((element, index) => {
      childrenWidths.current[index] = element.getBoundingClientRect().width;
    });
  }, []);

  const calculateMaxItems = useCallback(() => {
    if (elementRef.current === null) {
      return;
    }

    if (window.innerWidth <= MobileBreakpoint) {
      setMaxItems(0);
      return;
    }

    const parentWidth = elementRef.current.getBoundingClientRect().width;
    const itemsWidth = _sum(childrenWidths.current.slice(0, items.length));
    const moreElementWidth = childrenWidths.current[items.length];
    const additionalElementsWidth = _sum(childrenWidths.current.slice(items.length + 1));
    const availableSpace = parentWidth - additionalElementsWidth;

    if (itemsWidth < availableSpace) {
      setMaxItems(items.length);
      return;
    }

    let sum = 0;
    let count = 0;

    for (const width of childrenWidths.current.slice(0, items.length)) {
      sum += width;
      if (sum > availableSpace - moreElementWidth) {
        if (count < items.length) {
          break;
        }
      }
      count++;
    }

    setMaxItems(count);
  }, []);

  useIsomorphicLayoutEffect(() => {
    measureChildren();
    calculateMaxItems();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculateMaxItems);
    return () => window.removeEventListener('resize', calculateMaxItems);
  }, []);

  return [maxItems, elementRef] as const;
};

type NavItemProps = NavigationItemHosted;

/**
 * Renders a Navigation Item with or without a Menu
 * @param props {NavItemProps}
 * @returns {JSX.Element}
 */
const NavItem = (props: NavItemProps): JSX.Element => {
  if (props.items && props.items.length > 0) {
    return (
      <NavMenuButton label={props.title} {...getButtonProps(props)}>
        {props.items!.map((item) => (
          <NavMenuItem key={item.id} item={item} />
        ))}
      </NavMenuButton>
    );
  }

  return <ButtonNavSecondary {...getButtonProps(props)}>{props.title}</ButtonNavSecondary>;
};

interface NavMenuButtonProps {
  children?: React.ReactNode;
  label: React.ReactNode;
}

/**
 * Renders a Secondary style Nav Button with a Dropdown Menu that opens on hover
 * @param props {NavMenuButtonProps}
 * @returns {JSX.Element}
 */
const NavMenuButton = ({ children, label, ...buttonProps }: NavMenuButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return (
    <div onMouseLeave={handleClose}>
      <ButtonNavSecondary
        style={{ display: 'inline-block' }}
        iconRight={<DropdownMenu.Indicator isOpen={isOpen} />}
        onClick={(e) => {
          const { currentTarget } = e;
          setAnchorEl(currentTarget);

          const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

          // Prevent default behavior on touch devices when the menu is closed
          // This is to allow the user to open the menu, when the button is a link
          if (isTouchDevice && !isOpen) {
            e.preventDefault();
          }
        }}
        onMouseOver={(e) => setAnchorEl(e.currentTarget)}
        {...buttonProps}
      >
        {label}
      </ButtonNavSecondary>
      <Menu anchorEl={anchorEl} isModal={false} isOpen={isOpen} onClose={handleClose}>
        {children}
      </Menu>
    </div>
  );
};

interface NavMenuPrimaryButtonProps {
  children?: React.ReactNode;
}

/**
 * Renders a Primary style Nav Button with a Hamburger Menu Icon and a Dropdown Menu
 * @param props {NavMenuPrimaryButtonProps}
 * @returns {JSX.Element}
 */
const NavMenuPrimaryButton = ({ children, ...buttonProps }: NavMenuPrimaryButtonProps): JSX.Element => {
  const { popoverProps, triggerProps } = usePopoverState();

  return (
    <>
      <ButtonNavPrimary {...triggerProps} style={{ width: '5rem' }} icon={<MenuIndicator />} {...buttonProps} />
      <Menu {...popoverProps}>{children}</Menu>
    </>
  );
};

/** Returns props for ButtonNavSecondary */
const getButtonProps = (item: NavigationItemHosted): any => {
  switch (item.type) {
    case 'page':
      return {
        forwardedAs: Link,
        to: item.payload.destination,
        title: item.title,
      };
    case 'none':
      return {
        title: item.title,
      };
    default:
      return {
        forwardedAs: 'a',
        href: item.payload.destination,
        title: item.title,
        target: item.payload.openInNewTab === true ? '_blank' : undefined,
      };
  }
};

interface NavMenuItemProps {
  item: NavigationItemHosted;
}

/**
 * Renders a Dropdown Menu Item with or without Sub Menu Items
 * @param props {NavMenuItemProps}
 * @returns {JSX.Element}
 */
const NavMenuItem = ({ item }: NavMenuItemProps): JSX.Element => {
  const hasItems = item.items !== undefined && item.items.length > 0;

  if (hasItems) {
    return (
      <MenuItem key={item.id}>
        <MenuItemButton>{item.title}</MenuItemButton>
        <MenuList>
          <MenuItem>
            <MenuItemButton {...getMenuItemButtonProps(item)}>{item.title}</MenuItemButton>
          </MenuItem>
          {item.items?.map((subItem) => <NavMenuItem key={subItem.id} item={subItem} />)}
        </MenuList>
      </MenuItem>
    );
  }

  return (
    <MenuItem key={item.id}>
      <MenuItemButton {...getMenuItemButtonProps(item)}>{item.title}</MenuItemButton>
    </MenuItem>
  );
};

/** Returns props for MenuItemButton */
const getMenuItemButtonProps = (item: NavigationItemHosted): any => {
  switch (item.type) {
    case 'page':
      return {
        as: Link,
        to: item.payload.destination,
      };
    case 'none':
      return {};
    default:
      return {
        as: 'a',
        href: item.payload.destination,
        target: item.payload.openInNewTab === true ? '_blank' : undefined,
      };
  }
};

interface ActionItemProps {
  action: SectionAction;
}

/**
 * Renders an Action Nav Item
 * @param props {ActionItemProps}
 * @returns {JSX.Element}
 */
const ActionItem = (props: ActionItemProps): JSX.Element => {
  const [isLoggedIn] = useAuthentication();

  const handleLogout = useCallback((): void => {
    Auth.signOut();
  }, []);

  const actionProps = useActionProps(props.action);

  if (props.action.type === SectionActionType.SignIn && isLoggedIn === true) {
    return <ButtonNavPrimary onClick={handleLogout}>Sign Out</ButtonNavPrimary>;
  }

  return <ButtonNavPrimary {...actionProps}>{props.action.title}</ButtonNavPrimary>;
};

const MenuIndicator = (props: { isOpen?: boolean }) => {
  if (props.isOpen) {
    return <IconX />;
  }

  return <IconHamburgerMenu />;
};

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${SIZE[1]};
  width: 100%;
`;

const UnorderedList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  width: 100%;

  & > * {
    padding-right: ${SIZE[1]};
  }

  & > *:last-child {
    padding-right: 0;
  }
`;

const ListItem = styled.li`
  list-style: none;
  position: relative;
`;
