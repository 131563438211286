import { SectionDataWeb } from '@nucleus/types/web';
import React from 'react';
import { MarkupRegistryProvider } from './components/markup/MarkupRegistryProvider';
import { MarkupComponentMap } from './components/markup/MarkupRegistryContext';

export const createMarkupRegistry = (
  sections: MarkupComponentMap,
  options: { sectionRenderErrorData?: SectionDataWeb } = {}
): {
  Provider: (props: { children: React.ReactNode }) => JSX.Element;
} => {
  const Provider = (props: { children: React.ReactNode }) => {
    return (
      <MarkupRegistryProvider sectionMap={sections} sectionRenderErrorData={options.sectionRenderErrorData}>
        {props.children}
      </MarkupRegistryProvider>
    );
  };

  return {
    Provider: Provider,
  };
};
