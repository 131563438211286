import React, { useImperativeHandle, useMemo } from 'react';
import { Descendant, Editor, Selection } from 'slate';
import { Slate } from 'slate-react';
import { useCustomEditor } from '../hooks/useCustomEditor';
import { NormalizeInitialValueEditor, withNormalizeInitialValue } from '../plugins/withNormalizeInitialValue';
import { Plugin } from '../types/plugin';

export interface CustomEditorProps<T extends Editor = Editor> {
  children: React.ReactNode;
  onChange?: ((value: Descendant[]) => void) | undefined;
  onSelectionChange?: ((selection: Selection) => void) | undefined;
  onValueChange?: ((value: Descendant[]) => void) | undefined;
  plugins?: Plugin<T>[];
  initialValue: Descendant[];
}

interface CustomEditor extends React.ForwardRefExoticComponent<CustomEditorProps> {
  <T extends Editor = Editor>(props: CustomEditorProps<T> & React.RefAttributes<T>): JSX.Element;
}

/**
 * A custom editor component that wraps the Slate editor and provides a custom editor instance.
 * Accepts an array of plugins to apply to the editor.
 * @deprecated use import { CustomEditor } from '@nucleus/lib-slate' instead
 */
export const CustomEditor = React.forwardRef<Editor, CustomEditorProps>(
  ({ plugins = [], ...props }, ref): JSX.Element => {
    const editor = useCustomEditor([withNormalizeInitialValue, ...plugins]);
    useImperativeHandle(ref, () => editor, [editor]);
    const initialValue = useMemo(() => NormalizeInitialValueEditor.getInitialValue(editor, props.initialValue), []);
    return <Slate editor={editor} {...props} initialValue={initialValue} />;
  }
) as CustomEditor;

CustomEditor.displayName = 'CustomEditor';
