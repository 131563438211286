import { SSMClient, SSMClientConfig } from '@aws-sdk/client-ssm';

const instancesMap: Map<string, SSMClient> = new Map();

export const getSSMClient = (config: SSMClientConfig = {}): SSMClient => {
  const key = JSON.stringify(config);

  const client = instancesMap.get(key);

  if (client !== undefined) {
    return client;
  }

  const newClient = new SSMClient(config);

  instancesMap.set(key, newClient);

  return newClient;
};
