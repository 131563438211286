import {
  ThemeCustomElement as CustomElement,
  FontPaletteKey,
  ThemeImageElement as ImageElement,
  ThemeLinkElement as LinkElement,
  ThemeParameterElement as ParameterElement,
  ThemeLinkElement,
} from '@nucleus/types/web';
import {
  RichTextCustomRichTextComponentMap,
  RichTextElementComponentMap,
  RichTextRegistryProvider,
  useRichTextParameters,
} from '@nucleus/web-theme';
import { Text, WebImage, buildTransparentColorFromCssVariable } from '@nucleus/web-theme-elements';
import React from 'react';
import { Link as NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { formatSrcSet } from '../lib/richtext';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type PropsWithNode<T = {}> = T & { node: CustomElement };

const withNodeFontStyleProp = <C extends React.ComponentType<any>>(
  Component: C
): C extends React.ComponentType<infer P> ? React.ComponentType<PropsWithNode<P>> : C => {
  const WithNodeFontStyleProp = ({ node, ...props }: PropsWithNode) => {
    return <Component fontStyle={node.fontStyle} {...props} />;
  };

  WithNodeFontStyleProp.displayName = `withNodeFontStyleProp(${getDisplayName(Component)})`;

  return WithNodeFontStyleProp as any;
};

const createStyledElement = <C extends React.ElementType, S extends FontPaletteKey>(Component: C, style?: S) =>
  withNodeFontStyleProp(Text.withFontStyle(Component, style));

const getDisplayName = (Component: React.ElementType): string =>
  (Component as React.ComponentType).displayName ?? (Component as React.ComponentType).name ?? Component;

const Image = ({ node }: { children: React.ReactNode; node: ImageElement & { link: Partial<ThemeLinkElement> } }) => {
  if (node.link?.href !== undefined && node.link?.href !== '') {
    return (
      <Link node={node?.link as ThemeLinkElement}>
        <WebImage
          blurHash={node.blurHash}
          dimensions={node.dimensions}
          size={node.size}
          src={node.src}
          srcSet={formatSrcSet(node.srcSet)}
        />
      </Link>
    );
  }

  return (
    <WebImage
      blurHash={node.blurHash}
      dimensions={node.dimensions}
      size={node.size}
      src={node.src}
      srcSet={formatSrcSet(node.srcSet)}
    />
  );
};

const Parameter = ({ node }: { node: ParameterElement }) => {
  const parameters = useRichTextParameters();
  return <>{parameters?.[node.parameter] ?? node.fallback}</>;
};

const Link = ({ children, node }: { children: React.ReactNode; node: LinkElement }) => {
  const anchorProps = node.openInNewTab === true ? { target: '_blank', rel: 'noreferrer' } : {};

  if (typeof window === 'undefined' || isUrl(node.href)) {
    return (
      <a href={node.href} {...anchorProps}>
        {children}
      </a>
    );
  }

  if (node?.href?.includes('_api/file')) {
    return (
      <a href={node.href} {...anchorProps}>
        {children}
      </a>
    );
  }

  return (
    <NavLink {...anchorProps} to={node?.href}>
      {children}
    </NavLink>
  );
};

const isUrl = (maybeUrl: string): boolean => {
  try {
    const url = new URL(maybeUrl);
    return url.toString() !== undefined;
  } catch (error) {
    return false;
  }
};

const StyledMark = styled.mark`
  border-radius: 1em 0 1em 0;
  background-color: transparent;
  background-image: linear-gradient(
    -100deg,
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.3)},
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.7)} 95%,
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.1)}
  );
  color: inherit;

  &:before,
  &:after {
    content: ' ';
    display: inline;
    white-space: pre;
  }
`;

const StyledUl = styled.ul`
  padding-left: 1.5em;

  p + & {
    margin-top: 0.5em;
  }
`;

const StyledOl = styled.ol`
  padding-left: 1.5em;

  p + & {
    margin-top: 0.5em;
  }
`;

const StyledLi = styled.li`
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Mark = (props: React.PropsWithChildren) => <StyledMark>{props.children}</StyledMark>;

const ElementRegistry: RichTextElementComponentMap = {
  action: React.Fragment,
  image: Image,
  parameter: Parameter,
  link: Link,
  'heading-one': createStyledElement('h1'),
  'heading-two': createStyledElement('h2'),
  'heading-three': createStyledElement('h3'),
  'heading-four': createStyledElement('h4'),
  'heading-five': createStyledElement('h5'),
  'heading-six': createStyledElement('h6'),
  div: createStyledElement('div'),
  paragraph: createStyledElement('p'),
  span: createStyledElement('span'),
  'block-quote': createStyledElement('blockquote'),
  'bulleted-list': createStyledElement(StyledUl),
  'numbered-list': createStyledElement(StyledOl),
  'list-item': createStyledElement(StyledLi),
};

const TextRegistry: RichTextCustomRichTextComponentMap = {
  bold: 'b',
  italic: 'i',
  underline: 'u',
  strike: 's',
  code: 'code',
  highlight: Mark,
};

interface RichTextContextProps {
  children: React.ReactNode;
}

export const RichTextProvider = (props: RichTextContextProps): JSX.Element => {
  return (
    <RichTextRegistryProvider registerElements={ElementRegistry} registerText={TextRegistry}>
      {props.children}
    </RichTextRegistryProvider>
  );
};
