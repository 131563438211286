import { sortBy as _sortBy } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { IconRadioWaves } from '../icons/IconRadioWaves';
import { IconListeningMusic } from '../icons/IconListeningMusic';
import { IconPlay } from '../icons/IconPlay';
import { SermonMediaItemWeb } from '@nucleus/sermons/types/Sermon';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    MediaToggle?: {
      className?: string;
      background?: string;
      fontSize?: string;
      lineHeight?: string;
    };
    MediaToggleButton?: {
      color?: string;
      background?: string;
      activated?: {
        color?: string;
        background?: string;
      };
      hover?: {
        background?: string;
      };
    };
  }
}

interface Props {
  availableMedia?: SermonMediaItemWeb['type'][];
  className?: string;
  style?: React.CSSProperties;
  value?: SermonMediaItemWeb['type'];
  onChange?: (value: SermonMediaItemWeb['type']) => void;
}

/**
 * Toggle the Sermon Media Player between audio, live, and video players
 */
export const MediaToggle = ({
  availableMedia = ['file/audio', 'liveStream/link', 'embed/video'],
  value = 'embed/video',
  onChange,
  ...props
}: Props): JSX.Element | null => {
  if (availableMedia.length < 1) {
    return (
      <Container className={props.className} style={props.style}>
        <Text>No Media</Text>
      </Container>
    );
  }

  return (
    <Container className={props.className} style={props.style}>
      {prioritySort(availableMedia).map((media) => (
        <Button key={media} checked={value === media} onClick={() => onChange?.(media)}>
          {MediaIconMap[media]}
        </Button>
      ))}
      <Text>{MediaLabelMap[value]}</Text>
    </Container>
  );
};

const PriorityOrder: Record<SermonMediaItemWeb['type'], number> = {
  'liveStream/link': 1,
  'file/audio': 2,
  'embed/video': 3,
};

const prioritySort = (mediaTypes: SermonMediaItemWeb['type'][]): SermonMediaItemWeb['type'][] =>
  _sortBy(mediaTypes, (type) => PriorityOrder[type]);

const StyledIconPlay = styled(IconPlay)``;

const MediaIconMap = {
  ['file/audio']: <IconListeningMusic height="20px" width="17px" />,
  ['liveStream/link']: <IconRadioWaves height="20px" width="26px" />,
  ['embed/video']: <StyledIconPlay height="20px" width="16px" />,
};

const MediaLabelMap = {
  ['file/audio']: 'Audio',
  ['liveStream/link']: 'Live',
  ['embed/video']: 'Video',
};

const Container = styled.div.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.MediaToggle?.className,
}))`
  background: ${({ theme }) => theme._sermonThemeElements.MediaToggle?.background};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '25px',
  })};
  font-size: ${({ theme }) => theme._sermonThemeElements.MediaToggle?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.MediaToggle?.lineHeight};
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 5px;
  margin: 0;
  gap: 4px;
`;

const Button = styled.button<{ checked: boolean }>`
  outline: none;
  color: ${({ checked, theme }) =>
    checked
      ? theme._sermonThemeElements.MediaToggleButton?.activated?.color
      : theme._sermonThemeElements.MediaToggleButton?.color};
  background: ${({ checked, theme }) =>
    checked
      ? theme._sermonThemeElements.MediaToggleButton?.activated?.background
      : theme._sermonThemeElements.MediaToggleButton?.background};
  border: none;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '20px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  padding: 0;
  margin: 0;

  :hover {
    background: ${({ theme }) => theme._sermonThemeElements.MediaToggleButton?.hover?.background};
  }

  ${cornerStyleInterpolationFactory({
    pill: css`
      /* Visually offset the IconPlay within the circle */
      ${StyledIconPlay} {
        transform: translateX(2.1px);
      }
    `,
  })}
`;

const Text = styled.div`
  margin: 0;
  padding: 0 5px 0 4px;
  min-width: 65px;
`;
