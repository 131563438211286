import {
  ThemeCustomRichText as CustomRichText,
  ThemeRichTextElement as RichTextElement,
  ThemeRichTextNode as RichTextNode,
  ThemeRichTextNodeArray as RichTextNodeArray,
  ThemeRichTextNodeArray,
} from '@nucleus/types/web';
import { upgradeNodes } from '@nucleus/web-rich-text-upgrade';
import React from 'react';
import { useRichTextRegistryProvider } from './RichTextRegistry';

const isElement = (node: RichTextNode): node is RichTextElement => (node as RichTextElement).type !== undefined;

interface RichTextProps {
  nodes: RichTextNodeArray | string;
}

export const RichText = (props: RichTextProps): JSX.Element => {
  if (typeof props.nodes === 'string') {
    return <>{props.nodes}</>;
  }

  return <RenderNodes nodes={upgradeNodes(props.nodes)} />;
};

interface RenderNodesProps {
  nodes: RichTextNodeArray;
}

const RenderNodes = (props: RenderNodesProps): JSX.Element => {
  return (
    <>
      {props.nodes.map((node, index) => {
        if (isElement(node)) {
          return <Element key={index} node={node} />;
        }

        return <Leaf key={index} node={node} siblings={props.nodes} />;
      })}
    </>
  );
};

interface ElementProps {
  node: RichTextElement;
}

const Element = ({ node: element }: ElementProps): JSX.Element | null => {
  const { getElementByType } = useRichTextRegistryProvider();

  const ElementComponent = getElementByType(element.type);

  if (ElementComponent === undefined) {
    return null;
  }

  return (
    <ElementComponent node={element}>
      <RenderNodes nodes={element.children} />
    </ElementComponent>
  );
};

interface LeafProps {
  node: CustomRichText;
  siblings: ThemeRichTextNodeArray | string;
}

const Leaf = ({ node: leaf, siblings }: LeafProps): JSX.Element => {
  const { getTextByType } = useRichTextRegistryProvider();

  if (leaf.text === undefined) {
    return <></>;
  }

  const isEmptyText = leaf.text === '';
  const isOnlyLeaf = siblings.length === 1;
  if (isEmptyText && isOnlyLeaf) {
    return <br />;
  }

  let textElement = (
    <>
      {leaf.text.split('\n').map((text, index) => {
        if (index === 0) {
          return <React.Fragment key={index}>{text}</React.Fragment>;
        }

        return (
          <React.Fragment key={index}>
            <br />
            {text}
          </React.Fragment>
        );
      })}
    </>
  );

  if (leaf.bold === true) {
    const Bold = getTextByType('bold');
    textElement = <Bold>{textElement}</Bold>;
  }

  if (leaf.italic === true) {
    const Italic = getTextByType('italic');
    textElement = <Italic>{textElement}</Italic>;
  }

  if (leaf.underline === true) {
    const Underline = getTextByType('underline');
    textElement = <Underline>{textElement}</Underline>;
  }

  if (leaf.strike === true) {
    const Strike = getTextByType('strike');
    textElement = <Strike>{textElement}</Strike>;
  }

  if (leaf.highlight !== undefined) {
    const Highlight = getTextByType('highlight');
    textElement = <Highlight>{textElement}</Highlight>;
  }

  return textElement;
};
