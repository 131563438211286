import { LookConfigContext, useSiteCssSnippets } from '@nucleus/web-theme';
import { getTextGlobalStyle } from '@nucleus/web-theme-elements';
import React from 'react';
import { DefaultTheme, Interpolation, ThemeProps, createGlobalStyle, css } from 'styled-components';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';
import { getColorGlobalStyle } from './Color';

export const ThemeGlobalStyle = (): JSX.Element => {
  const theme = React.useContext(LookConfigContext);
  const cssSnippets = useSiteCssSnippets();
  const enabledCssSnippets = useEnabledCodeSnippets(cssSnippets);

  return (
    <OrderedGlobalStyles
      interpolations={[
        getBaseGlobalStyle(),
        getColorGlobalStyle({ colorPalette: theme.colors?.lightMode }),
        getTextGlobalStyle({
          fontPalette: theme.fonts?.styles,
          baseScale: theme.fonts?.baseScale,
          scale: theme.fonts?.scale,
        }),
        enabledCssSnippets.map(
          (snippet) => css`
            ${snippet.content}
          `
        ),
      ]}
    />
  );
};

const OrderedGlobalStyles = createGlobalStyle<{ interpolations: Interpolation<ThemeProps<DefaultTheme>>[] }>`
${({ interpolations }) => interpolations.map((child) => child)}
`;

const getBaseGlobalStyle = () => {
  return css`
    :root {
      --min-unit-length: 12px;
      --max-unit-length: 45px;

      --the-unit-length: 3vw;

      --unit-length: clamp(var(--min-unit-length), var(--the-unit-length), var(--max-unit-length));

      --section-padding-multiplier: 1;
    }

    html {
      --header-content-height: max(calc(4.6 * var(--min-unit-length)), calc(1.9 * var(--unit-length)));

      box-sizing: border-box;
      font-size: 10px;
      min-width: 320px;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    #root {
      background: #fff;
    }
  `;
};
