import { ColorPaletteColors } from '@nucleus/types/web';
import { objectToCssClass, objectToCssRules } from '@nucleus/web-theme-elements';
import { Interpolation, ThemeProps, css } from 'styled-components';
import { buildCssVariablesForColorPaletteAndVariations, buildThemeColorsVariationClassName } from '../utils/styles';
import { DefaultTheme } from 'styled-components';

export const getColorGlobalStyle = ({
  colorPalette,
}: {
  colorPalette: ColorPaletteColors;
}): Interpolation<ThemeProps<DefaultTheme>> => {
  const [globalCssVariables, colorCssVariables, colorCssVariablesByVariation] =
    buildCssVariablesForColorPaletteAndVariations(colorPalette);

  return css`
    :root {
      ${() => objectToCssRules(globalCssVariables)}

      ${() => objectToCssRules(colorCssVariables)}

      ${() => objectToCssRules(colorCssVariablesByVariation['variation-1'])}
    }

    /* output css variables grouped by theme colors variation class */
    ${() =>
      Object.entries(colorCssVariablesByVariation)
        .map(([key, value]) => objectToCssClass(buildThemeColorsVariationClassName(key), value))
        .join('')}

    .nucleus-section-header {
      &:has(+ .theme-colors-variation-1) {
        ${() => objectToCssRules(colorCssVariablesByVariation['variation-1'])}
      }

      &:has(+ .theme-colors-variation-2) {
        ${() => objectToCssRules(colorCssVariablesByVariation['variation-2'])}
      }

      &:has(+ .theme-colors-variation-3) {
        ${() => objectToCssRules(colorCssVariablesByVariation['variation-3'])}
      }

      &:has(+ .theme-colors-variation-4) {
        ${() => objectToCssRules(colorCssVariablesByVariation['variation-4'])}
      }
    }
  `;
};
