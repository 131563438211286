/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

export const usePopoverState = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev ? null : currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    triggerProps: {
      activated: isOpen,
      onClick: handleClick,
    },
    popoverProps: {
      anchorEl: anchorEl,
      isOpen: isOpen,
      onClose: handleClose,
    },
  };
};
