import { ListCollectionsWebEndpoint, ReadCollectionWebEndpoint } from '@nucleus/web-hosting';
import axios from 'axios';

const getBaseUrl = (isUserAuthenticated = false): string => {
  return isUserAuthenticated ? '/_api/private/sermon-hub' : '/_api/public/sermon-hub';
};

export const queryCollections = async <T>(
  engineId: string | undefined,
  collection: 'playlists' | 'tags' | 'speakers' | 'books' = 'playlists',
  jwtToken?: string,
  params?: ListCollectionsWebEndpoint<T>['queryStringParameters']
): Promise<ListCollectionsWebEndpoint<T>['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<ListCollectionsWebEndpoint<T>['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/collections/${collection}`,
    params: params,
    headers: {
      Authorization: jwtToken,
    },
  });

  return response.data;
};

export const queryCollection = async <T>(
  engineId: string | undefined,
  collection: 'playlists' | 'tags' | 'speakers' | 'books' = 'playlists',
  slugOrId: string,
  jwtToken?: string
): Promise<ReadCollectionWebEndpoint<T>['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<ReadCollectionWebEndpoint<T>['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/collections/${collection}/${slugOrId}`,
    headers: {
      Authorization: jwtToken,
    },
  });

  return response.data;
};
