import { StackWeb } from '@nucleus/types/web';
import React, { useContext } from 'react';
import { StacksContext, StacksContextValue } from './StacksContext';

interface StacksProviderProps {
  children: React.ReactNode;
  stacks: Array<StackWeb>;
}
export const StacksProvider = (props: StacksProviderProps): JSX.Element => {
  let { stacks } = useContext<StacksContextValue>(StacksContext);

  if (stacks === undefined) {
    stacks = new Map();
  }

  props.stacks?.forEach((stack) => stacks.set(stack.id, stack));

  const context: StacksContextValue = {
    stacks: stacks,
    getStack: (id: string) => stacks.get(id),
  };

  return <StacksContext.Provider value={context}>{props.children}</StacksContext.Provider>;
};
