import React from 'react';
import styled from 'styled-components';
import { Portal } from './Portal';
import { FadeUp } from '@nucleus/lib-animation';
import { animated } from 'react-spring';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    Popover?: {
      zIndex?: number;
    };
  }
}

export interface PopoverOrigin {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
}

export interface PopoverProps {
  /**
   * The element to which the popover is anchored.
   */
  anchorEl?: HTMLElement | null;
  /**
   * The origin point of the anchor element used for positioning the Popover
   */
  anchorOrigin?: PopoverOrigin;
  className?: string;
  children?: React.ReactNode;
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
  /**
   * The origin point of the Container element used for positioning the Popover
   */
  transformOrigin?: PopoverOrigin;
}

/**
 * The Popover component is used to create a container for rendering content that appears as a popover.
 * It can be anchored to a specified element and provides animations for entrance and exit.
 *
 * Inspired by [material-ui/popover](https://mui.com/material-ui/api/popover/)
 *
 * @param props - The props for the Popover component.
 * @returns A JSX element representing the popover.
 */
export const Popover = (props: PopoverProps): JSX.Element | null => {
  const { ref, style } = usePositionedElementProps(props);

  return (
    <FadeUp items={props.isOpen ? [1] : []}>
      {(springValues) => (
        <Portal>
          <ModalRoot>
            <Backdrop interactive={props.isModal} onClick={props.onClose} />
            <Container
              as={animated.div}
              className={props.className}
              style={{ ...props.style, ...style, ...springValues }}
              ref={ref}
            >
              {props.children}
            </Container>
          </ModalRoot>
        </Portal>
      )}
    </FadeUp>
  );
};

const ModalRoot = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme._reactComponents.Popover?.zIndex ?? 9000};
  inset: 0px;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

const Backdrop = styled.div<{ interactive?: boolean }>`
  position: fixed;
  inset: 0px;
  pointer-events: ${({ interactive }) => (interactive ? 'auto' : 'none')};
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 6px;
`;

/**
 * Calculates and positions an element according to an anchorElement
 */
const usePositionedElementProps = ({
  anchorEl,
  anchorOrigin = { horizontal: 'center', vertical: 'top' },
  transformOrigin = { horizontal: 'center', vertical: 'bottom' },
  isOpen,
  onClose,
}: PopoverProps) => {
  const [element, setElement] = React.useState<HTMLDivElement | null>(null);
  const [style, setStyle] = React.useState({
    top: '0px',
    left: '0px',
  });

  const calculatePosition = React.useCallback(() => {
    if (anchorEl && element) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      let top =
        anchorRect.top +
        anchorRect.height * (anchorOrigin.vertical === 'center' ? 0.5 : anchorOrigin.vertical === 'bottom' ? 1 : 0) -
        elemRect.height * (transformOrigin.vertical === 'center' ? 0.5 : transformOrigin.vertical === 'bottom' ? 1 : 0);

      let left =
        anchorRect.left +
        anchorRect.width * (anchorOrigin.horizontal === 'center' ? 0.5 : anchorOrigin.horizontal === 'right' ? 1 : 0) -
        elemRect.width *
          (transformOrigin.horizontal === 'center' ? 0.5 : transformOrigin.horizontal === 'right' ? 1 : 0);

      if (top + elemRect.height > window.innerHeight) {
        top = anchorRect.top - elemRect.height;
      }

      if (top <= 0) {
        top = 0;
      }

      if (left <= 0) {
        left = 0;
      }

      if (left + elemRect.width >= window.innerWidth) {
        left = window.innerWidth - elemRect.width;
      }

      setStyle({ top: `${top}px`, left: `${left}px` });
    }
  }, [anchorEl, element]);

  React.useEffect(() => {
    if (isOpen !== true) {
      return;
    }
    return addEventListeners(['resize'], calculatePosition);
  }, [isOpen, calculatePosition]);

  React.useEffect(() => {
    if (isOpen !== true) {
      return;
    }
    const handler = () => onClose?.();
    return addEventListeners(['scroll', 'wheel', 'resize', 'touchmove'], handler);
  }, [isOpen]);

  React.useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(() => calculatePosition());
      observer.observe(element);
      return () => observer.disconnect();
    }
  }, [element]);

  React.useLayoutEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  return {
    ref: (node: HTMLDivElement) => setElement(node),
    style: style,
  };
};

function addEventListeners(events: string[], callback: () => void, target = window, passive = true) {
  events.forEach((event) => target.addEventListener(event, callback, { passive: passive }));
  return () => events.forEach((event) => target.removeEventListener(event, callback));
}
