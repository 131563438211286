import { createPrefixedKey } from '@nucleus/lib-shape';
import {
  Alignment,
  BlockLayout,
  BuildSectionConfig,
  CalendarItem,
  CalendarSection,
  CardItemApi,
  CardSection,
  EmbedSection,
  Height,
  InfoButtonsLayout,
  InfoButtonsWidth,
  InfoMaxWidth,
  InfoSection,
  LeaderSection,
  ListItemApi,
  ListSection,
  LookConfig,
  Media,
  MediaOptions,
  Position,
  PresetMedia,
  PresetMediaKeys,
  SectionAction,
  SectionLayout,
  SectionPayloadDefaults as TSectionPayloadDefaults,
  SectionTypeEnum,
  SermonSection,
} from '@nucleus/types/web';
import { merge as _merge, omit as _omit } from 'lodash';
import { cardBackground } from '../config/presetmedia/cardbackground';
import { cardMediaFull } from '../config/presetmedia/cardmediafull';
import { cardMediaInline } from '../config/presetmedia/cardmediainline';
import { leaderCardMediaFull } from '../config/presetmedia/leadercardmediafull';
import { leaderCardMediaInline } from '../config/presetmedia/leadercardmediainline';
import { listMedia } from '../config/presetmedia/listmedia';
import { sectionBackground } from '../config/presetmedia/sectionbackground';
import { sectionMedia } from '../config/presetmedia/sectionmedia';
import { buildRichTextNodeArray } from './utils/richtext';

const KEY_PREFIX_ACTIONITEM = 'actionitem';

const blockMediaOptionsDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('sectionMedia', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

const cardMediaOptionsBackgroundDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('cardMediaFull', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

const cardMediaOptionsInlineDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('cardMediaInline', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

const listMediaOptionsInlineDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('listMedia', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

export const SectionPayloadDefaults: TSectionPayloadDefaults = {
  [SectionTypeEnum.Info]: (config) => {
    return {
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...InfoSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.List]: (config) => {
    return {
      // section settings
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...ListSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.Gallery]: (config) => {
    return {
      // section settings
      name: config.title,
      layout: SectionLayout.Full,
      backgroundMedia: buildPresetMedia('sectionBackground', config.lookConfig),
      colorPaletteVariation: 'variation-1',
      height: Height.Medium,
      // block settings
      blocks: [{}],
      blockLayout: config.layout,
      blockLayoutVariant: 'variant1',
      blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
      blockInfoPosition: Position.Center,
      blockHeadlineAlignment: Alignment.Left,
      blockHeadlineMaxWidth: InfoMaxWidth.Medium,
      blockBodyAlignment: Alignment.Left,
      blockButtonsAlignment: Alignment.Left,
      blockButtonsLayout: InfoButtonsLayout.Vertical,
      blockButtonsWidth: InfoButtonsWidth.Auto,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...InfoSectionPayloadDefaultsByBlockLayout[config.layout],
    };
  },
  [SectionTypeEnum.Card]: (config) => {
    return {
      // section settings
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      cardType: 'grid',
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...CardSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.Leader]: (config) => {
    return {
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...LeaderSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.Embed]: (config) => {
    return {
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...EmbedSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.Calendar]: (config) => {
    return {
      // section settings
      name: config.title,
      layout: SectionLayout.Full,
      height: Height.Medium,
      blockLayout: config.layout,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...CalendarSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
  [SectionTypeEnum.Header]: (config) => {
    return {
      // section settings
      name: config.title,
      layout: SectionLayout.Full,
      backgroundMedia: buildPlaceholderMedia({
        useImage: true,
        image: config.lookConfig?.placeholders?.LightBokeh,
      }),
      colorPaletteVariation: 'variation-1',
      height: Height.Medium,
      // block settings
      blocks: [
        {
          behavior: 'fixed',
          backgroundBehavior: 'auto',
          brandIdentity: {
            useLogo: false,
            useText: true,

            text: '',
          },
          actions: [
            {
              id: createPrefixedKey(KEY_PREFIX_ACTIONITEM),
              type: 'signIn',
              title: 'Sign In',
              displayAs: 'style1',
              openInNewTab: false,
            },
          ],
          navigation: undefined,
        },
      ],
      blockLayout: config.layout,
      blockLayoutVariant: 'variant1',
      blockEnabledInfo: {
        actions: true,
        brandIdentity: true,
        navigation: true,
      },
      blockInfoPosition: Position.Center,
      blockHeadlineAlignment: Alignment.Left,
      blockHeadlineMaxWidth: InfoMaxWidth.Medium,
      blockBodyAlignment: Alignment.Left,
      blockButtonsAlignment: Alignment.Left,
      blockButtonsLayout: InfoButtonsLayout.Vertical,
      blockButtonsWidth: InfoButtonsWidth.Auto,
      blockMediaOptions: {
        // TODO: change this to use preset media when ui is ready
        useColor: false,
        useImage: true,
        useVideo: false,
        image: {
          scale: 'cover',
          tile: 'none',
        },
        blending: {
          opacity: 1,
          blendMode: 'none',
          fade: 'none',
        },
      },
    };
  },
  [SectionTypeEnum.Footer]: (config) => {
    return {
      // section settings
      name: config.title,
      layout: SectionLayout.Full,
      backgroundMedia: buildPlaceholderMedia(),
      colorPaletteVariation: 'variation-2',
      height: Height.Medium,
      // block settings
      blocks: [
        {
          brandIdentity: {
            useLogo: false,
            useText: true,

            text: '',
          },
          actions: [],
          contacts: [],
          navigation: undefined,
          legal: [
            {
              type: 'paragraph',
              fontStyle: 'paragraph1',
              children: [
                { text: 'Copyright ' },
                { type: 'parameter', fallback: '', children: [{ text: '' }], parameter: 'churchName' },
                { text: ' ' },
                { type: 'parameter', fallback: '', children: [{ text: '' }], parameter: 'currentYear' },
                { text: '' },
              ],
            },
          ],
        },
      ],
      blockLayout: config.layout,
      blockLayoutVariant: 'variant1',
      blockEnabledInfo: {
        actions: true,
        body: true,
        brandIdentity: true,
        contacts: true,
        legal: true,
        navigation: true,
      },
      blockInfoPosition: Position.Center,
      blockHeadlineAlignment: Alignment.Left,
      blockHeadlineMaxWidth: InfoMaxWidth.Medium,
      blockBodyAlignment: Alignment.Left,
      blockButtonsAlignment: Alignment.Left,
      blockButtonsLayout: InfoButtonsLayout.Vertical,
      blockButtonsWidth: InfoButtonsWidth.Auto,
      blockMediaOptions: {
        // TODO: change this to use preset media when ui is ready
        useColor: false,
        useImage: true,
        useVideo: false,
        image: {
          scale: 'cover',
          tile: 'none',
        },
        blending: {
          opacity: 1,
          blendMode: 'none',
          fade: 'none',
        },
      },
    };
  },
  [SectionTypeEnum.Sermon]: (config) => {
    return {
      name: config.title,
      blockLayout: config.layout,
      layout: SectionLayout.Full,
      blockMediaOptions: blockMediaOptionsDefault(config.lookConfig),
      ...SermonSectionPayloadDefaultsByBlockLayout[config.layout](config),
    };
  },
};

const DEFAULT_OVERLINE = 'Overline Text';
const DEFAULT_HEADLINE_SHORT = 'Here’s a short headline.';
const DEFAULT_HEADLINE_SHORT_2 = 'Another short headline.';
const DEFAULT_HEADLINE_LONG =
  'Here’s a longer headline that helps you see what this area looks like with some longer text.';
const DEFAULT_BODY_SHORT = 'This short paragraph is here to help visualize this unique section.';
const DEFAULT_BODY_STANDARD =
  'A lot of times it’s hard to see what a section might look like on your website. This paragraph is to help give you a feeling for it. Replace this text with your own.';
const DEFAULT_BODY_LONG =
  'A lot of times it’s hard to see what a section might look like on your website. This longer paragraph is here to help. Replace this text with your own. The rest of this longer paragraph is also filler text. So don’t use this on your real website or people might be confused. It’s always best to write your own.';
const DEFAULT_BODY_LONG_2 =
  'That being said, sometimes it’s necessary to use filler words to say something that you don’t quite have the words for yet. That makes it more helpful to make decisions on content length.';
const DEFAULT_BODY_LONG_3 =
  'So, you’re welcome for this, but we should stop now. You can take it from here and make your bold move!';

const defaultText = {
  overline: {
    standard: DEFAULT_OVERLINE,
  },
  headline: {
    short: DEFAULT_HEADLINE_SHORT,
    short2: DEFAULT_HEADLINE_SHORT_2,
    long: DEFAULT_HEADLINE_LONG,
  },
  body: {
    short: DEFAULT_BODY_SHORT,
    standard: DEFAULT_BODY_STANDARD,
    long: DEFAULT_BODY_LONG,
    long2: DEFAULT_BODY_LONG_2,
    long3: DEFAULT_BODY_LONG_3,
  },
};

type BlockLayoutDefaultsConfig = BuildSectionConfig;

const InfoSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<InfoSection['payload'], 'title' | 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: false, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Stretch,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short2,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
};

const buildCalendarItem = (): CalendarItem => ({
  id: createPrefixedKey('calendar'),
  feedUrl: '',
  displayData: ['description', 'date', 'location'],
  displayBy: 'week',
  limit: 5,
});

const CalendarSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<CalendarSection['payload'], 'title' | 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        actions: [buildPlaceholderAction()],
        body: buildRichTextNodeArray([{ text: defaultText.body.standard, type: 'paragraph', fontStyle: 'paragraph2' }]),
        displayType: 'accordion',
        headline: buildRichTextNodeArray([
          { text: defaultText.headline.short, type: 'heading-three', fontStyle: 'headline3' },
        ]),
        items: [buildCalendarItem()],
        mediaItems: [buildPlaceholderMedia({ useImage: true, image: lookConfig?.placeholders?.LightBokeh })],
        type: 'div',
        overline: buildRichTextNodeArray([{ text: defaultText.overline.standard, type: 'div', fontStyle: 'label3' }]),
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: false, headline: false, byline: false, body: false },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        actions: [],
        body: buildRichTextNodeArray([{ text: defaultText.body.standard, type: 'paragraph', fontStyle: 'paragraph2' }]),
        displayType: 'accordion',
        headline: buildRichTextNodeArray([
          { text: defaultText.headline.short, type: 'heading-two', fontStyle: 'headline2' },
        ]),
        items: [buildCalendarItem()],
        mediaItems: [buildPlaceholderMedia({ useImage: true, image: lookConfig?.placeholders?.LightBokeh })],
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: false, headline: false, byline: false, body: false },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        actions: [],
        body: buildRichTextNodeArray([{ text: defaultText.body.standard, type: 'paragraph', fontStyle: 'paragraph2' }]),
        displayType: 'accordion',
        headline: buildRichTextNodeArray([
          { text: defaultText.headline.short, type: 'heading-three', fontStyle: 'headline3' },
        ]),
        items: [buildCalendarItem()],
        mediaItems: [
          buildPlaceholderMedia({ useImage: true, image: lookConfig?.placeholders?.LightBokeh }),
          buildPlaceholderMedia({ useImage: true, image: lookConfig?.placeholders?.LightBokeh }),
          buildPlaceholderMedia({ useImage: true, image: lookConfig?.placeholders?.LightBokeh }),
        ],
        type: 'div',
        overline: buildRichTextNodeArray([{ text: defaultText.overline.standard, type: 'div', fontStyle: 'label3' }]),
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        actions: [],
        body: buildRichTextNodeArray([
          { text: defaultText.body.long, type: 'paragraph', fontStyle: 'paragraph2' },
          { text: '', type: 'paragraph', fontStyle: 'paragraph2' },
          { text: defaultText.body.long2, type: 'paragraph', fontStyle: 'paragraph2' },
          { text: '', type: 'paragraph', fontStyle: 'paragraph2' },
          { text: defaultText.body.long3, type: 'paragraph', fontStyle: 'paragraph2' },
        ]),
        displayType: 'accordion',
        headline: buildRichTextNodeArray([
          { text: defaultText.headline.short, type: 'heading-three', fontStyle: 'headline3' },
        ]),
        items: [buildCalendarItem()],
        mediaItems: [],
        type: 'div',
        overline: buildRichTextNodeArray([{ text: defaultText.overline.standard, type: 'div', fontStyle: 'label3' }]),
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        actions: [],
        body: buildRichTextNodeArray([{ text: defaultText.body.short, type: 'paragraph', fontStyle: 'paragraph2' }]),
        displayType: 'accordion',
        headline: buildRichTextNodeArray([
          { text: defaultText.headline.long, type: 'heading-three', fontStyle: 'headline3' },
        ]),
        items: [buildCalendarItem()],
        mediaItems: [],
        type: 'div',
        overline: buildRichTextNodeArray([{ text: defaultText.overline.standard, type: 'div', fontStyle: 'label1' }]),
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Stretch,
    blocks: [
      {
        displayType: 'accordion',
        items: [buildCalendarItem()],
      },
    ],
  }),
};

const CardSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<CardSection['payload'], 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: false,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: cardMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'swipe',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'background',
        itemMediaOptions: cardMediaOptionsBackgroundDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: false,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: cardMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: cardMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'swipe',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'background',
        itemMediaOptions: cardMediaOptionsBackgroundDefault(lookConfig),
        itemSpacing: 'full',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('cardBackground', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'top-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: cardMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
};

const leaderMediaOptionsBackgroundDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('leaderCardMediaFull', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

const leaderMediaOptionsInlineDefault = (lookConfig?: LookConfig): MediaOptions =>
  buildPresetMedia('leaderCardMediaInline', lookConfig, {
    useColor: false,
    useImage: true,
    useVideo: false,
  });

const LeaderSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<LeaderSection['payload'], 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: false,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: leaderMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'swipe',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'background',
        itemMediaOptions: leaderMediaOptionsBackgroundDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: false,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: leaderMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: false },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: leaderMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'swipe',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
      blending: {
        opacity: 0.25,
        blendMode: 'none',
        fade: 'none',
      },
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'background',
        itemMediaOptions: leaderMediaOptionsBackgroundDefault(lookConfig),
        itemSpacing: 'full',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'standard',
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemAlignment: 'left',
        itemBackgroundMedia: buildPresetMedia('listMedia', lookConfig),
        itemEnabledInfo: {
          mediaItems: true,
          overline: true,
          headline: true,
          body: true,
          buttons: true,
        },
        itemInfoPosition: 'bottom-left',
        itemMediaDisplayType: 'inline',
        itemMediaOptions: leaderMediaOptionsInlineDefault(lookConfig),
        itemSpacing: 'constrained',
        gridAlignment: 'center',
        gridTypeDesktop: 'standard',
        gridTypeMobile: 'swipe',
      },
    ],
  }),
};

const EmbedSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<EmbedSection['payload'], 'title' | 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: false, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
      blending: {
        opacity: 0.25,
        blendMode: 'none',
        fade: 'none',
      },
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: false, headline: false, byline: false, body: false },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
      },
    ],
  }),
};

const ListSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<ListSection['payload'], 'title' | 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: {
      overline: true,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        byline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: false,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'accordion',
      },
    ],
  }),
  layout2: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: true,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'stacked',
      },
    ],
  }),
  layout3: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: false,
      headline: false,
      byline: false,
      body: false,
    },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: false,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'stacked',
      },
    ],
  }),
  layout4: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: true,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: false,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'accordion',
      },
    ],
  }),
  layout5: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
      blending: {
        opacity: 0.15,
        blendMode: 'none',
        fade: 'none',
      },
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Small,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: true,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Bottom,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: false,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'accordion',
      },
    ],
  }),
  layout6: ({ lookConfig }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: true,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Stretch,
    blocks: [
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: true,
          headline: true,
          byline: true,
          body: true,
          buttons: true,
          mediaItems: true,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'stacked',
      },
      {
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short2,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [buildPlaceholderAction()],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
        itemBodyAlignment: Alignment.Left,
        itemButtonAlignment: Alignment.Left,
        itemButtonsLayout: InfoButtonsLayout.Horizontal,
        itemButtonsWidth: InfoButtonsWidth.Auto,
        itemEnabledInfo: {
          overline: false,
          headline: true,
          byline: false,
          body: true,
          buttons: true,
          mediaItems: true,
        },
        itemHeadlineAlignment: Alignment.Left,
        itemMediaOptions: listMediaOptionsInlineDefault(lookConfig),
        listType: 'stacked',
      },
    ],
  }),
};

const SermonSectionPayloadDefaultsByBlockLayout: Record<
  BlockLayout,
  (config: BlockLayoutDefaultsConfig) => Omit<SermonSection['payload'], 'title' | 'layout' | 'blockLayout'>
> = {
  layout1: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-1',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout2: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant2',
    blockEnabledInfo: {
      overline: false,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Horizontal,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-two',
            fontStyle: 'headline2',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout3: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: {
      overline: false,
      headline: true,
      byline: false,
      body: true,
    },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Small,
    blockBodyAlignment: Alignment.Center,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
  layout4: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-2',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Center,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockBodyMaxColumns: 2,
    blockButtonsAlignment: Alignment.Center,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label3',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.long,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long2,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: '',
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
          {
            text: defaultText.body.long3,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
      },
    ],
  }),
  layout5: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig, {
      useImage: true,
      image: lookConfig?.placeholders?.LightBokeh,
    }),
    colorPaletteVariation: 'variation-2',
    height: Height.Large,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Center,
    blockHeadlineAlignment: Alignment.Right,
    blockHeadlineMaxWidth: InfoMaxWidth.Medium,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label1',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.long,
            type: 'heading-three',
            fontStyle: 'headline3',
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.short,
            type: 'paragraph',
            fontStyle: 'paragraph2',
          },
        ]),
        actions: [],
        mediaItems: [],
      },
    ],
  }),
  layout6: ({ lookConfig, sermonEngineId }) => ({
    // section settings
    backgroundMedia: buildPresetMedia('sectionBackground', lookConfig),
    colorPaletteVariation: 'variation-3',
    height: Height.Medium,
    // block settings
    blockLayoutVariant: 'variant1',
    blockEnabledInfo: { overline: true, headline: true, byline: false, body: true },
    blockInfoPosition: Position.Left,
    blockHeadlineAlignment: Alignment.Left,
    blockHeadlineMaxWidth: InfoMaxWidth.Large,
    blockBodyAlignment: Alignment.Left,
    blockButtonsAlignment: Alignment.Left,
    blockButtonsLayout: InfoButtonsLayout.Vertical,
    blockButtonsWidth: InfoButtonsWidth.Auto,
    blocks: [
      {
        engineId: sermonEngineId,
        overline: buildRichTextNodeArray([
          {
            text: defaultText.overline.standard,
            type: 'div',
            fontStyle: 'label5',
          },
        ]),
        headline: buildRichTextNodeArray([
          {
            text: defaultText.headline.short,
            type: 'heading-four',
            fontStyle: 'headline4',
            styles: ['bold'],
          },
        ]),
        body: buildRichTextNodeArray([
          {
            text: defaultText.body.standard,
            type: 'paragraph',
            fontStyle: 'paragraph3',
          },
        ]),
        actions: [],
        mediaItems: [
          buildPlaceholderMedia({
            useImage: true,
            image: lookConfig?.placeholders?.LightBokeh,
          }),
        ],
      },
    ],
  }),
};

export const CardItemDefaults: Partial<CardItemApi> = {
  overline: buildRichTextNodeArray([
    {
      text: '',
      type: 'div',
      fontStyle: 'label6',
    },
  ]),
  headline: buildRichTextNodeArray([
    {
      text: '',
      type: 'heading-six',
      fontStyle: 'headline6',
    },
  ]),
  body: buildRichTextNodeArray([
    {
      text: '',
      type: 'paragraph',
      fontStyle: 'paragraph4',
    },
  ]),
  actions: [],
  mediaItems: [],
};

export const ListItemDefaults: Partial<ListItemApi> = {
  overline: buildRichTextNodeArray([
    {
      text: '',
      type: 'div',
      fontStyle: 'label6',
    },
  ]),
  headline: buildRichTextNodeArray([
    {
      text: '',
      type: 'heading-five',
      fontStyle: 'headline5',
    },
  ]),
  byline: buildRichTextNodeArray([
    {
      text: '',
      type: 'div',
      fontStyle: 'label3',
    },
  ]),
  body: buildRichTextNodeArray([
    {
      text: '',
      type: 'paragraph',
      fontStyle: 'paragraph3',
    },
  ]),
  actions: [],
  mediaItems: [],
};

function buildPlaceholderAction(
  text = 'Link this Button',
  displayAs: SectionAction['displayAs'] = 'style1'
): SectionAction {
  return {
    id: createPrefixedKey(KEY_PREFIX_ACTIONITEM),
    title: text,
    type: 'none',
    displayAs: displayAs,
    openInNewTab: false,
  };
}

const presetMediaMap: Record<PresetMediaKeys, PresetMedia> = {
  cardBackground: cardBackground,
  cardMediaFull: cardMediaFull,
  cardMediaInline: cardMediaInline,
  leaderCardMediaFull: leaderCardMediaFull,
  leaderCardMediaInline: leaderCardMediaInline,
  listMedia: listMedia,
  sectionBackground: sectionBackground,
  sectionMedia: sectionMedia,
};

function buildPresetMedia(
  presetMediaKey: PresetMediaKeys,
  lookConfig?: LookConfig,
  mediaItem: Partial<Media> | undefined = {}
): Media {
  let preset = lookConfig?.presetMedia?.[presetMediaKey];
  if (preset === undefined) {
    preset = presetMediaMap[presetMediaKey];
  }

  return _merge(
    {
      presetMediaKey: presetMediaKey,
    },
    mediaItem,
    // Note: Omit Media Preset Image files
    _omit(preset.mediaOptions, 'image.fileId', 'video.image.fileId')
  );
}

function buildPlaceholderMedia(mediaItem: Partial<Media> | undefined = {}): Media {
  return _merge(
    {},
    {
      useColor: false,
      useImage: false,
      useVideo: false,
      image: {
        scale: 'cover',
        tile: 'none',
      },
      blending: {
        opacity: 1,
        blendMode: 'none',
        fade: 'none',
      },
    },
    {
      ...mediaItem,
      image: mediaItem.image,
    }
  );
}
