/* eslint-disable @typescript-eslint/no-namespace */
import { traverse } from '@nucleus/src-platform/lib/richtext';
import { ActionItem } from '@nucleus/types/action';
import { ImageWeb } from '@nucleus/types/media/image';
import { FontPaletteKey } from '@nucleus/types/web/style';
import { Element } from 'slate';

/** Namespace representing the old Web Rich Text types. */
export namespace Old {
  export interface ThemeCustomRichText {
    text: string;
    bold?: boolean;
    code?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
    highlight?: boolean;
  }

  export type ThemeActionElement = {
    id: string;
    type: 'action';
    actionType: string;
    destination?: string;
    openInNewTab?: boolean;
    metadata?: Record<string, unknown>;
    children: ThemeRichTextNodeArray;
  };

  type ImageActionType =
    | 'email'
    | 'file'
    | 'flow'
    | 'giving'
    | 'infoCard'
    | 'leader'
    | 'none'
    | 'page'
    | 'phone'
    | 'prayer'
    | 'sermons'
    | 'signIn'
    | 'url';

  export interface ThemeImageElement extends ImageWeb {
    action?: Pick<ActionItem<ImageActionType>, 'id' | 'destination' | 'type' | 'openInNewTab'>;
    type: 'image';
    alt?: string;
    src: string;
    href?: string;
    fileId?: string;
    size?: number;
    children: ThemeRichTextNodeArray;
  }

  export interface ThemeParameterElement {
    type: 'parameter';
    parameter: 'churchName' | 'currentYear' | 'firstName' | 'lastName' | 'name' | string;
    fallback: string;
    children: ThemeRichTextNodeArray;
  }

  export interface ThemeLinkElement {
    type: 'link';
    href: string;
    openInNewTab?: boolean;
    children: ThemeRichTextNodeArray;
  }

  // TODO: Remove these old headline types once they've been fully migrated
  type DeprecatedHeadlineKey =
    | 'headlineOne'
    | 'headlineTwo'
    | 'headlineThree'
    | 'headlineFour'
    | 'headlineFive'
    | 'headlineSix';

  export type ThemeCustomElementType =
    | FontPaletteKey
    | 'bulleted-list'
    | 'numbered-list'
    | 'list-item'
    | 'paragraph'
    | 'span'
    | DeprecatedHeadlineKey;

  export interface ThemeCustomElement {
    type: ThemeCustomElementType;
    children: ThemeRichTextNodeArray;
  }

  export type ThemeRichTextElement =
    | ThemeActionElement
    | ThemeImageElement
    | ThemeParameterElement
    | ThemeLinkElement
    | ThemeCustomElement;

  export type ThemeRichTextNode = ThemeRichTextElement | ThemeCustomRichText;

  export type ThemeRichTextNodeArray = Array<ThemeRichTextNode>;
}

/** Namespace for the new Rich Text types */
export namespace New {
  export interface ThemeCustomRichText {
    text: string;
    bold?: boolean;
    code?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
    highlight?: boolean;
  }

  export type ThemeActionElement = {
    id: string;
    type: 'action';
    actionType: string;
    destination?: string;
    openInNewTab?: boolean;
    metadata?: Record<string, unknown>;
    children: ThemeRichTextNodeArray;
  };

  type ImageActionType =
    | 'email'
    | 'file'
    | 'flow'
    | 'giving'
    | 'infoCard'
    | 'leader'
    | 'none'
    | 'page'
    | 'phone'
    | 'prayer'
    | 'sermons'
    | 'signIn'
    | 'url';

  export interface ThemeImageElement extends ImageWeb {
    action?: Pick<ActionItem<ImageActionType>, 'id' | 'destination' | 'type' | 'openInNewTab'>;
    type: 'image';
    alt?: string;
    src: string;
    href?: string;
    fileId?: string;
    size?: number;
    children: ThemeRichTextNodeArray;
  }

  export interface ThemeParameterElement {
    type: 'parameter';
    parameter: 'churchName' | 'currentYear' | 'firstName' | 'lastName' | 'name' | string;
    fallback: string;
    children: ThemeRichTextNodeArray;
  }

  export interface ThemeLinkElement {
    type: 'link';
    href: string;
    openInNewTab?: boolean;
    children: ThemeRichTextNodeArray;
  }

  export type ThemeCustomElementType =
    | 'heading-one'
    | 'heading-two'
    | 'heading-three'
    | 'heading-four'
    | 'heading-five'
    | 'heading-six'
    | 'div'
    | 'paragraph'
    | 'span'
    | 'block-quote'
    | 'bulleted-list'
    | 'numbered-list'
    | 'list-item';

  export interface ThemeCustomElement {
    type: ThemeCustomElementType;
    fontStyle?: FontPaletteKey;
    children: ThemeRichTextNodeArray;
  }

  export type ThemeRichTextElement =
    | ThemeActionElement
    | ThemeImageElement
    | ThemeParameterElement
    | ThemeLinkElement
    | ThemeCustomElement;

  export type ThemeRichTextNode = ThemeRichTextElement | ThemeCustomRichText;

  export type ThemeRichTextNodeArray = Array<ThemeRichTextNode>;
}

/** Map of old Element types to new Element types */
const DeprecatedElementMap: Record<
  Exclude<Old.ThemeCustomElementType, New.ThemeCustomElementType>,
  New.ThemeCustomElementType
> = {
  headlineOne: 'heading-one',
  headlineTwo: 'heading-two',
  headlineThree: 'heading-three',
  headlineFour: 'heading-four',
  headlineFive: 'heading-five',
  headlineSix: 'heading-six',
  headline1: 'heading-one',
  headline2: 'heading-two',
  headline3: 'heading-three',
  headline4: 'heading-four',
  headline5: 'heading-five',
  headline6: 'heading-six',
  paragraph1: 'paragraph',
  paragraph2: 'paragraph',
  paragraph3: 'paragraph',
  paragraph4: 'paragraph',
  title1: 'div',
  title2: 'div',
  description1: 'div',
  description2: 'div',
  blockquote1: 'block-quote',
  blockquote2: 'block-quote',
  label1: 'div',
  label2: 'div',
  label3: 'div',
  label4: 'div',
  label5: 'div',
  label6: 'div',
  navigation1: 'div',
  navigation2: 'div',
  navigation3: 'div',
  navigation4: 'div',
  navigation5: 'div',
  button1: 'div',
  button2: 'div',
  button3: 'div',
  button4: 'div',
};

/** Map of Old Element Type to FontPaletteKey */
const FontPaletteMap: Record<Exclude<Old.ThemeCustomElementType, New.ThemeCustomElementType>, FontPaletteKey> = {
  headlineOne: 'headline1',
  headlineTwo: 'headline2',
  headlineThree: 'headline3',
  headlineFour: 'headline4',
  headlineFive: 'headline5',
  headlineSix: 'headline6',
  headline1: 'headline1',
  headline2: 'headline2',
  headline3: 'headline3',
  headline4: 'headline4',
  headline5: 'headline5',
  headline6: 'headline6',
  paragraph1: 'paragraph1',
  paragraph2: 'paragraph2',
  paragraph3: 'paragraph3',
  paragraph4: 'paragraph4',
  title1: 'title1',
  title2: 'title2',
  description1: 'description1',
  description2: 'description2',
  blockquote1: 'blockquote1',
  blockquote2: 'blockquote2',
  label1: 'label1',
  label2: 'label2',
  label3: 'label3',
  label4: 'label4',
  label5: 'label5',
  label6: 'label6',
  navigation1: 'navigation1',
  navigation2: 'navigation2',
  navigation3: 'navigation3',
  navigation4: 'navigation4',
  navigation5: 'navigation5',
  button1: 'button1',
  button2: 'button2',
  button3: 'button3',
  button4: 'button4',
};

type DeprecatedElementType = keyof typeof DeprecatedElementMap;

/** Upgrade an Old ThemeCustomElement by transforming the deprecated type into a valid type, and applying the old type as a fontStyle */
export const upgradeElement = (element: Old.ThemeCustomElement): New.ThemeCustomElement => {
  if (DeprecatedElementMap[element.type as DeprecatedElementType] === undefined) {
    return element as New.ThemeCustomElement;
  }

  return {
    ...element,
    type: DeprecatedElementMap[element.type as DeprecatedElementType],
    fontStyle: FontPaletteMap[element.type as DeprecatedElementType],
  } as New.ThemeCustomElement;
};

export const upgradeNode = (node: Old.ThemeRichTextNode): New.ThemeRichTextNode => {
  if (Element.isElement(node)) {
    return upgradeElement(node as Old.ThemeCustomElement) as New.ThemeRichTextNode;
  }
  return node as New.ThemeRichTextNode;
};

/**
 * Function that takes an array of RichTextNodes with deprecated FontPaletteKey types and upgrades them to
 * standard RichText Types. This solves an issue in web, where Font Style and Element Type are not separated.
 *
 * By separating them we solve several issues:
 * 1. Font styles may be applied to any element type
 * 2. By creating a standard set of types, we may copy and paste nodes between different editors
 * 3. We also make it easier to add new element types in the future, and support existing font styles
 *
 * @param nodes
 * @returns nodes
 */
export const upgradeNodes = (nodes: Old.ThemeRichTextNodeArray): New.ThemeRichTextNodeArray => {
  return traverse<any>(nodes, upgradeNode) as New.ThemeRichTextNodeArray;
};
