import { formatRelative } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { merge as _merge } from 'lodash';
import { parseIsoToDate } from './iso';
import { ISO8601 } from './type/iso8601';

interface FormatRelativeOptions {
  asOf?: ISO8601 | Date;
  locale?: Partial<RelativeLocal>;
}

interface RelativeLocal {
  lastWeek: string;
  yesterday: string;
  today: string;
  tomorrow: string;
  nextWeek: string;
  other: string;
}

const formatRelativeLocale: RelativeLocal = {
  lastWeek: "'Last' eeee 'at' hh:mmaaaaa'm'",
  yesterday: "'Yesterday' 'at' hh:mmaaaaa'm'",
  today: "'Today' 'at' hh:mmaaaaa'm'",
  tomorrow: "'Tomorrow' 'at' hh:mmaaaaa'm'",
  nextWeek: "'Next' eeee 'at' hh:mmaaaaa'm'",
  other: "M/d/yyyy 'at' hh:mmaaaaa'm'",
};

export const formatDateTimeRelative = (date?: ISO8601 | Date, options: FormatRelativeOptions = {}): string => {
  const mergedOptions = _merge({}, { asOf: new Date() }, options);

  if (date === undefined) {
    return '';
  }

  const localFormatter: RelativeLocal = _merge({}, formatRelativeLocale, mergedOptions.locale);

  return formatRelative(parseIsoToDate(date), parseIsoToDate(mergedOptions.asOf), {
    locale: _merge({
      ...enUS,
      formatRelative: (token: keyof RelativeLocal) => localFormatter[token],
    }),
  });
};

const formatRelativeLocaleDate: RelativeLocal = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today' 'at' hh:mmaaaaa'm'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'M/d/yyyy',
};

export const formatDateRelative = (date?: ISO8601 | Date, options: FormatRelativeOptions = {}): string =>
  formatDateTimeRelative(date, _merge({}, { locale: formatRelativeLocaleDate }, options));

export const formatDateToUnixTimestamp = (date: ISO8601 | Date): number =>
  Math.floor(parseIsoToDate(date).getTime() / 1000);
