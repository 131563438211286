import { ReadSermonHubEndpoint } from '@nucleus/web-hosting';
import axios from 'axios';

const getBaseUrl = (isUserAuthenticated = false): string => {
  return isUserAuthenticated ? '/_api/private/sermon-hub' : '/_api/public/sermon-hub';
};

export const querySermonPage = async (
  engineId: string,
  slug: string,
  jwtToken?: string
): Promise<ReadSermonHubEndpoint['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<ReadSermonHubEndpoint['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/page/${slug}`,
    validateStatus: (status) => [200, 401, 403, 404].includes(status),
    headers: {
      Authorization: jwtToken,
    },
  });

  return response.data;
};
