import { MediaWeb } from '@nucleus/types/web';
import { MediaQuery, nucleusClass } from '@nucleus/web-theme-elements';
import { inverseAspectRatioAsPercentFromImage } from '@nucleus/web-theme/src/lib/utilities';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { MediaItemBackground } from '../components/Background';

export type MediaProps = MediaWeb;

const MediaShapes = ['square', 'rounded', 'circle', 'original'] as const;
export type MediaShape = (typeof MediaShapes)[number];

interface MediaContainerProps {
  $image?: MediaProps;
  $shape: MediaShape;
  isSticky?: boolean;
}

const MEDIA_SHAPE = {
  circle: css`
    border-radius: 50%;
    height: 0;
    padding-bottom: 100%;
  `,
  square: css`
    height: 0;
    padding-bottom: 100%;
  `,
  rounded: css`
    border-radius: 2.4rem;
  `,
  original: css``,
};

const MediaContainer = styled.div<MediaContainerProps>`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${({ $shape }) => MEDIA_SHAPE[$shape]}

  ${MediaQuery.smallDesktopAndUp} {
    ${({ isSticky }) =>
      isSticky &&
      css`
        max-height: 100vh;
        position: sticky;
        top: 0;
      `}
  }
`;

interface MediaInnerProps {
  $image?: MediaProps;
}

const MediaInner = styled.div<MediaInnerProps>`
  width: 100%;
  padding-top: calc(min(100%, ${({ $image }) => inverseAspectRatioAsPercentFromImage($image?.image)}));
  position: relative;
  min-height: 100%;
`;

interface BlockMediaProps {
  media?: MediaProps;
  shape?: MediaShape;
  className?: string;
  content?: ReactNode;
  isSticky?: boolean;
}

export const BlockMedia = styled(({ media, content, shape = 'original', ...props }: BlockMediaProps) => {
  return (
    <MediaContainer {...props} className={classNames([props.className, nucleusClass('block-media')])} $shape={shape}>
      <MediaInner $image={media}>
        <MediaItemBackground background={media} />
        {content && <MediaContentOverlay>{content}</MediaContentOverlay>}
      </MediaInner>
    </MediaContainer>
  );
})``;

const MediaContentOverlay = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
`;
