import { Position } from '@nucleus/types/web';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCardGrid, StackedGrid } from '../../blocks/BlockCardGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CardBlockLayoutProps, getPositionRow } from './CardLayout';

export const CardBlockLayoutHorizontal = (props: CardBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const cardsOnTheLeft = props.blockLayoutVariant === 'variant1';
  const mobileMediaPosition = getMobileMediaPosition(props.blockLayoutVariantMobile);
  const row = getPositionRow(props.blockInfoPosition);
  const swipeDesktop = props.blocks[0]?.gridTypeDesktop === 'swipe';
  const padInfo = swipeDesktop === true && row !== Position.Bottom && props.height === 'large';

  return (
    <StyledBlockLayout
      {...props}
      cardsOnTheLeft={cardsOnTheLeft}
      mobileMediaPosition={mobileMediaPosition}
      row={row}
      padInfo={padInfo}
    >
      <StyledInfoColumn>
        <BlockInfo {...innerProps} block={props.blocks[0]} />
      </StyledInfoColumn>
      <StyledCardColumn>
        <SpacerTop />
        <StyledBlockCardGrid {...innerProps} block={props.blocks[0]} />
      </StyledCardColumn>
    </StyledBlockLayout>
  );
};

const getMobileMediaPosition = (blockLayoutVariantMobile?: string) => {
  switch (blockLayoutVariantMobile) {
    case 'variant1':
      return 'top';
    case 'variant2':
      return 'bottom';
    default:
      return;
  }
};

const cardsOnTheLeft = booleanPropHelperFactory('cardsOnTheLeft');

const StyledBlockCardGrid = styled(BlockCardGrid)``;
const StyledHorizontalColumn = styled.div``;
const StyledInfoColumn = styled(StyledHorizontalColumn)``;
const StyledCardColumn = styled(StyledHorizontalColumn)``;

const StyledBlockLayout = styled(BlockLayout)<{
  cardsOnTheLeft: boolean;
  mobileMediaPosition?: 'top' | 'bottom';
  row: Position;
  padInfo: boolean;
}>`
  flex-direction: row;
  flex-wrap: wrap;

  ${StyledHorizontalColumn} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    flex: 1 1 auto;
  }

  ${StyledCardColumn} {
    justify-content: center;

    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockCardGrid} {
    position: relative;
    z-index: 1;

    background-color: transparent;
  }

  ${StackedGrid} {
    padding: var(--unit-length);
  }

  ${cardsOnTheLeft(css`
    ${StyledInfoColumn} {
      order: 2;
    }
    ${StyledCardColumn} {
      order: 1;
    }
  `)}

  /* NOTE: media.tabletLandscape */
  @media (max-width: 799px) {
    ${StyledInfoColumn} {
      min-height: ${(props) => {
        switch (props.height) {
          case 'medium':
            return '40vh';
          case 'large':
            return '70vh';
          default:
            return undefined;
        }
      }};
    }

    ${({ mobileMediaPosition }) => {
      switch (mobileMediaPosition) {
        case 'top':
          return css`
            ${StyledInfoColumn} {
              order: 2;
            }
            ${StyledCardColumn} {
              order: 1;
            }
          `;
        case 'bottom':
          return css`
            ${StyledInfoColumn} {
              order: 1;
            }
            ${StyledCardColumn} {
              order: 2;
            }
          `;
        default:
          return;
      }
    }}
  }

  /* NOTE: media.tabletLandscape */
  @media (min-width: 800px) {
    flex-wrap: nowrap;
    ${StyledHorizontalColumn} {
      width: 50%;
    }

    ${StyledInfoColumn} {
      padding-bottom: ${(props) => (props.padInfo === true ? 'calc(2.25 * var(--unit-length))' : undefined)};
    }

    ${StyledCardColumn} {
      ${SpacerTop} {
        display: block;
      }

      justify-content: ${(props) => {
        switch (props.row) {
          case Position.Top:
            return 'flex-start';
          case Position.Center:
            return 'center';
          case Position.Bottom:
            return 'flex-end';
          default:
            return;
        }
      }};
    }
  }
`;
