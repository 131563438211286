import { CodeSnippet } from '@nucleus/sites';
import { CodeSnippetWeb } from '@nucleus/sites/src/types/CodeSnippet';
import { Handler } from 'aws-lambda';
import { ISO8601 } from '../iso8601';
import { Alignment } from './alignment';
import { BaseBlock, BaseBlockApi, BaseBlockWeb, BlockEnabledInfo, BlockLayout, BlockLayoutVariant } from './block';
import { ColorPaletteVariation } from './colors';
import { Height } from './height';
import { InfoButtonsLayout, InfoButtonsWidth, InfoMaxWidth } from './info';
import { LabelPayload } from './label';
import { Media, MediaApi, MediaOptions, MediaWeb } from './media';
import { Overlay } from './overlay';
import { Position } from './position';
import { CalendarSection, CalendarSectionApi, CalendarSectionWeb } from './sections/CalendarSection';
import { CardSection, CardSectionApi, CardSectionWeb } from './sections/CardSection';
import { EmbedSection, EmbedSectionApi, EmbedSectionWeb } from './sections/EmbedSection';
import { GallerySection, GallerySectionApi, GallerySectionWeb } from './sections/GallerySection';
import { InfoSection, InfoSectionApi, InfoSectionWeb } from './sections/InfoSection';
import { LeaderSection, LeaderSectionApi, LeaderSectionWeb } from './sections/LeaderSection';
import { ListSection, ListSectionApi, ListSectionWeb } from './sections/ListSection';
import { SermonMediaSection, SermonMediaSectionApi, SermonMediaSectionWeb } from './sections/SermonMediaSection';
import {
  SermonSearchRequestSection,
  SermonSearchRequestSectionApi,
  SermonSearchRequestSectionWeb,
} from './sections/SermonSearchRequestSection';
import {
  SermonSearchResultsSection,
  SermonSearchResultsSectionApi,
  SermonSearchResultsSectionWeb,
} from './sections/SermonSearchResultsSection';
import { SermonSection, SermonSectionApi, SermonSectionWeb } from './sections/SermonSection';
import { FooterSection, FooterSectionApi, FooterSectionWeb } from './sections/footerSection';
import { HeaderSection, HeaderSectionApi, HeaderSectionWeb } from './sections/headerSection';
import { Spacing } from './spacing';
import { LookConfig } from './style';

export const enum SectionTypeEnum {
  Calendar = 'calendar',
  Card = 'card',
  Embed = 'embed',
  Footer = 'footer',
  Gallery = 'gallery',
  Header = 'header',
  Info = 'info',
  Leader = 'leader',
  List = 'list',
  Sermon = 'sermon',
  SermonActions = 'sermonActions',
  SermonMedia = 'sermonMedia',
  SermonSearchRequest = 'sermonSearchRequest',
  SermonSearchResults = 'sermonSearchResults',
}

export const SectionLayout = {
  Full: 'full',
  Constrained: 'constrained',
  Info: 'info',
} as const;

export type SectionLayout = (typeof SectionLayout)[keyof typeof SectionLayout];

type Auth = {
  requestAccess: boolean;
  showLogin: boolean;
  // connection types in backend
};

interface Filter {
  type: string;
  payload: any;
}

interface Public extends Filter {
  type: 'public';
  payload: undefined;
}

type Filters = Public;

export type AuthorizedSectionData<T extends BaseSection<SectionTypeEnum, any>> = T & {
  filters?: Array<Filters>;
  lookConfig?: LookConfig;
};

export type PendingAuthorizationSectionData<T extends BaseSection<SectionTypeEnum, any>> = Omit<T, 'payload'> & {
  auth: Auth;
};

export interface BaseSectionPayload<TBlock> {
  name?: string;
  labels?: Array<LabelPayload>;

  // Section.
  backgroundMedia: Media;
  codeSnippets?: Array<CodeSnippet>;
  colorPaletteVariation: ColorPaletteVariation;
  height?: Height;
  spacing?: Spacing;

  // Blocks.
  insetBlock?: BaseBlock;
  blocks: Array<TBlock>;

  layout: SectionLayout;
  blockLayout: BlockLayout;
  blockLayoutVariant: BlockLayoutVariant;
  blockLayoutVariantMobile?: BlockLayoutVariant;

  blockEnabledInfo: BlockEnabledInfo;
  blockInfoPosition: Position;
  blockHeadlineAlignment: Alignment;
  blockHeadlineMaxWidth: InfoMaxWidth;
  blockBodyAlignment: Alignment;
  blockBodyMaxWidth?: InfoMaxWidth; // theme uses headline max-width, if not set
  blockBodyMaxColumns?: number;
  blockButtonsAlignment: Alignment;
  blockButtonsMaxWidth?: InfoMaxWidth; // theme uses headline max-width, if not set
  blockButtonsLayout: InfoButtonsLayout; // stacking behavior within the buttons container
  blockButtonsWidth: InfoButtonsWidth; // behavior for each button's width
  blockMediaOptions?: MediaOptions;
}

export interface BaseSectionPayloadApi<TBlockApi> {
  name?: string;
  labels?: Array<LabelPayload>;

  // Section.
  backgroundMedia?: MediaApi;
  codeSnippets?: Array<CodeSnippet>;
  colorPaletteVariation: ColorPaletteVariation;
  height?: Height;
  spacing?: Spacing;

  // Blocks.
  insetBlock?: BaseBlockApi;
  blocks: Array<TBlockApi>;

  layout: SectionLayout;
  blockLayout: BlockLayout;
  blockLayoutVariant: BlockLayoutVariant;
  blockLayoutVariantMobile?: BlockLayoutVariant;

  blockEnabledInfo: BlockEnabledInfo;
  blockInfoPosition: Position;
  blockHeadlineAlignment: Alignment;
  blockHeadlineMaxWidth: InfoMaxWidth;
  blockBodyAlignment: Alignment;
  blockBodyMaxWidth?: InfoMaxWidth;
  blockBodyMaxColumns?: number;
  blockButtonsAlignment: Alignment;
  blockButtonsMaxWidth?: InfoMaxWidth;
  blockButtonsLayout: InfoButtonsLayout;
  blockButtonsWidth: InfoButtonsWidth;
  blockMediaOptions?: MediaOptions;
}

export interface BaseSectionPayloadWeb<TBlockWeb> {
  name?: string;
  labels?: Array<LabelPayload>;

  backgroundMedia?: MediaWeb;
  codeSnippets?: Array<CodeSnippetWeb>;
  colorPaletteVariation: ColorPaletteVariation;
  height?: Height;
  spacing?: Spacing;

  insetBlock?: BaseBlockWeb;
  blocks: Array<TBlockWeb>;

  layout: SectionLayout;
  blockLayout: BlockLayout;
  blockLayoutVariant: BlockLayoutVariant;
  blockLayoutVariantMobile?: BlockLayoutVariant;

  blockInfoPosition: Position;
  blockHeadlineAlignment: Alignment;
  blockHeadlineMaxWidth: InfoMaxWidth;
  blockBodyAlignment: Alignment;
  blockBodyMaxWidth?: InfoMaxWidth;
  blockBodyMaxColumns?: number;
  blockButtonsAlignment: Alignment;
  blockButtonsMaxWidth?: InfoMaxWidth;
  blockButtonsLayout: InfoButtonsLayout;
  blockButtonsWidth: InfoButtonsWidth;
  blockMediaOptions?: MediaOptions;

  overlays?: Record<string, Overlay>;
}

export interface BaseSection<
  T extends SectionTypeEnum,
  P extends BaseSectionPayload<any> | BaseSectionPayloadApi<any> | BaseSectionPayloadWeb<any>,
> {
  id: string;
  type: T;
  payload: P;
  lookConfig?: LookConfig;
  templateKey?: string;
  updatedAt?: ISO8601;
}

export type SectionData =
  | CalendarSection
  | CardSection
  | EmbedSection
  | FooterSection
  | GallerySection
  | HeaderSection
  | InfoSection
  | LeaderSection
  | ListSection
  | SermonMediaSection
  | SermonSearchRequestSection
  | SermonSearchResultsSection
  | SermonSection;

export type SectionDataApi =
  | CalendarSectionApi
  | CardSectionApi
  | EmbedSectionApi
  | FooterSectionApi
  | GallerySectionApi
  | HeaderSectionApi
  | InfoSectionApi
  | LeaderSectionApi
  | ListSectionApi
  | SermonMediaSectionApi
  | SermonSearchRequestSectionApi
  | SermonSearchResultsSectionApi
  | SermonSectionApi;

export type SectionDataWeb =
  | CalendarSectionWeb
  | CardSectionWeb
  | EmbedSectionWeb
  | FooterSectionWeb
  | GallerySectionWeb
  | HeaderSectionWeb
  | InfoSectionWeb
  | LeaderSectionWeb
  | ListSectionWeb
  | SermonMediaSectionWeb
  | SermonSearchRequestSectionWeb
  | SermonSearchResultsSectionWeb
  | SermonSectionWeb;

type ExtractBlocks<T> = T extends BaseSection<
  any,
  BaseSectionPayload<infer B> | BaseSectionPayloadApi<infer B> | BaseSectionPayloadWeb<infer B>
>
  ? B
  : never;

export type SectionBlock = ExtractBlocks<SectionData>;

export type SectionBlockWeb = ExtractBlocks<SectionDataWeb>;

export const KEY_PREFIX_SECTION = 'section';

type ExtractPayload<T extends SectionTypeEnum> = Extract<SectionData, { type: T }> extends BaseSection<T, infer P>
  ? P
  : never;

export interface BuildSectionConfig {
  layout: BlockLayout;
  title: string;
  lookConfig?: LookConfig;
  sermonEngineId?: string;
}

export type SectionPayloadDefaults = {
  [Type in SectionTypeEnum]: (config: BuildSectionConfig) => ExtractPayload<Type>;
};

export type SectionPayloadBuilderRequest<T extends SectionTypeEnum> = BuildSectionConfig & {
  sectionType: T;
};

export type SectionPayloadBuilderResponse<T extends SectionTypeEnum> = {
  payload: ExtractPayload<T>;
};

export type SectionPayloadBuilderHandler<T extends SectionTypeEnum = SectionTypeEnum> = Handler<
  SectionPayloadBuilderRequest<T>,
  SectionPayloadBuilderResponse<T>
>;
