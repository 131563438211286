import { cloneDeep as _cloneDeep } from 'lodash';
import { Descendant, Editor } from 'slate';

/**
 * @deprecated use import { NormalizeInitialValueEditor } from '@nucleus/lib-slate' instead
 */
export interface NormalizeInitialValueEditor {
  normalizeInitialValue: (initialValue: Descendant[]) => Descendant[];
}

declare module '../types/CustomEditor' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
  interface CustomEditor extends NormalizeInitialValueEditor {}
}

/**
 * A Slate plugin to normalize the initial value of the editor.
 * @deprecated use import { withNormalizeInitialValue } from '@nucleus/lib-slate' instead
 */
export const withNormalizeInitialValue = <T extends Editor = Editor>(editor: T): T & NormalizeInitialValueEditor => {
  editor.normalizeInitialValue = (initialValue) => {
    if (initialValue === undefined) {
      return [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
    }

    if (typeof initialValue === 'string') {
      return [
        {
          type: 'paragraph',
          children: [{ text: initialValue }],
        },
      ];
    }

    if (initialValue.length === 0) {
      return [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
    }

    return initialValue;
  };

  return editor;
};

/**
 * @deprecated use import { NormalizeInitialValueEditor } from '@nucleus/lib-slate' instead
 */
export const NormalizeInitialValueEditor = {
  getInitialValue: (editor: Editor, initialValue: Descendant[]): Descendant[] => {
    let normalizedValue = _cloneDeep(initialValue);
    normalizedValue = editor.normalizeInitialValue(normalizedValue);
    return normalizedValue;
  },
};
