import React from 'react';
import { Transition, TransitionComponentProps } from 'react-spring';

export type FadeUpProps<T> = TransitionComponentProps<T>

/**
 * Animates an elements opacity and translateY, fading in and translating up
 */
export const FadeUp = <T,>(props: FadeUpProps<T>): JSX.Element => {
  return (
    <Transition
      from={{ opacity: 0, y: -20, pointerEvents: 'none' }}
      enter={{ opacity: 1, y: 0, pointerEvents: 'auto' }}
      leave={{ opacity: 0, y: -20, pointerEvents: 'none' }}
      config={{ mass: 0.5, tension: 1000, friction: 55 }}
      {...props}
    />
  );
};
