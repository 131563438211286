import { ISO8601Schema } from '@nucleus/src-platform/data/date';
import { yupKeyStringSchema } from '@nucleus/lib-shape';
import { ISO8601 } from '@nucleus/lib-datetime';
import * as yup from 'yup';

export interface PublishableEngine {
  id: string;
  ownerId: string;
  readonly category?: string;
  name?: string;
  readonly createdAt: ISO8601;
  readonly createdBy: string;
  updatedAt: ISO8601;
  updatedBy: string;
  archivedAt?: ISO8601;
  archivedBy?: string;
}

export const PublishableEngineSchema: yup.SchemaOf<PublishableEngine> = yup.object({
  id: yupKeyStringSchema.trim().required(),
  ownerId: yupKeyStringSchema.trim().required(),
  category: yup.string().trim(),
  name: yup.string().trim(),
  createdAt: ISO8601Schema.required(),
  createdBy: yup.string().trim().required(),
  updatedAt: ISO8601Schema.required(),
  updatedBy: yup.string().trim().required(),
  archivedAt: ISO8601Schema,
  archivedBy: yup.string().trim(),
});
