import { flow as _flow } from 'lodash';
import { useMemo } from 'react';
import { Editor, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { Plugin } from '../types/plugin';

/**
 * A hook that creates a custom editor instance with the specified plugins.
 * @deprecated use import { useCustomEditor } from '@nucleus/lib-slate' instead
 * @param plugins
 * @returns <Editor>
 */
export const useCustomEditor = <T extends Editor = Editor>(plugins: Plugin<T>[] = []): Editor => {
  return useMemo<Editor>(() => _flow(withHistory, withReact, ...plugins)(createEditor()), []);
};
