import { MediaWeb } from '@nucleus/types/web';
import {
  inverseAspectRatioAsPercent,
  inverseAspectRatioAsPercentFromImage,
} from '@nucleus/web-theme/src/lib/utilities';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { MediaItemBackground } from './Background';

const MediaShapes = ['square', 'rounded', 'circle', 'original'] as const;
export type MediaShape = (typeof MediaShapes)[number];

interface MediaContainerProps {
  $image: MediaWeb;
  $shape: MediaShape;
}

const MEDIA_SHAPE = {
  circle: css`
    border-radius: 50%;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
  `,
  square: css`
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
  `,
  rounded: css`
    overflow: hidden;
    border-radius: 2.4rem;
  `,
  original: css``,
};

const MediaContainer = styled.div<MediaContainerProps>`
  width: 100%;
  ${({ $shape }) => MEDIA_SHAPE[$shape]}
  overflow: hidden;
`;

interface MediaInnerProps {
  $image: MediaWeb;
  $aspectRatio?: [number, number];
}

const MediaInner = styled.div<MediaInnerProps>`
  background-color: var(--color-dark);
  width: 100%;
  padding-top: ${(props) =>
    props.$aspectRatio
      ? inverseAspectRatioAsPercent(props.$aspectRatio[0], props.$aspectRatio[1])
      : inverseAspectRatioAsPercentFromImage(props.$image.image)};
  position: relative;
`;

interface GridItemBlockMediaProps {
  media: MediaWeb;
  shape?: MediaShape;
  aspectRatio?: [number, number];
  className?: string;
  content?: ReactNode;
}

export const GridItemBlockMedia = styled(
  ({ media, content, shape, aspectRatio, ...props }: GridItemBlockMediaProps) => {
    return (
      <MediaContainer {...props} $image={media} $shape={shape ?? 'original'}>
        <MediaInner $image={media} $aspectRatio={aspectRatio}>
          <MediaItemBackground background={media} fallbackColor="transparent" />
          {content && <MediaContentOverlay>{content}</MediaContentOverlay>}
        </MediaInner>
      </MediaContainer>
    );
  }
)``;

const MediaContentOverlay = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
`;
