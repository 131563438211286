import {
  ThemeCustomElementType as CustomElementType,
  FontPaletteKey,
  ThemeRichTextNode as RichTextNode,
  ThemeRichTextNodeArray as RichTextNodeArray,
} from '@nucleus/types/web';

export function buildRichTextNodeArray(
  nodes: {
    text: string;
    type: CustomElementType;
    fontStyle: FontPaletteKey;
    styles?: Array<string>;
  }[]
): RichTextNodeArray {
  return nodes.map((node) => buildRichTextNode(node.text, node.type, node.fontStyle));
}

function buildRichTextNode(text: string, elementType: CustomElementType, fontStyle: FontPaletteKey): RichTextNode {
  return {
    type: elementType,
    fontStyle: fontStyle,
    children: [
      {
        text: text,
      },
    ],
  };
}
