import React from 'react';

const changedArray = (a: Array<unknown> = [], b: Array<unknown> = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

export interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

interface ErrorBoundaryBase {
  onResetKeysChange?: (prevResetKeys: Array<unknown> | undefined, resetKeys: Array<unknown> | undefined) => void;
  onReset?: (...args: Array<unknown>) => void;
  onError?: (error: Error, info: React.ErrorInfo, reset: () => void) => void;
  resetKeys?: Array<unknown>;
}

export interface ErrorBoundaryPropsWithComponent extends ErrorBoundaryBase {
  fallback?: never;
  FallbackComponent: React.ComponentType<FallbackProps>;
  fallbackRender?: never;
}

declare function FallbackRender(
  props: FallbackProps
): React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;

export interface ErrorBoundaryPropsWithRender extends ErrorBoundaryBase {
  fallback?: never;
  FallbackComponent?: never;
  fallbackRender: typeof FallbackRender;
}

export interface ErrorBoundaryPropsWithFallback extends ErrorBoundaryBase {
  fallback?: JSX.Element | null;
  FallbackComponent?: never;
  fallbackRender?: never;
}

export type ErrorBoundaryProps =
  | ErrorBoundaryPropsWithFallback
  | ErrorBoundaryPropsWithComponent
  | ErrorBoundaryPropsWithRender;

type ErrorBoundaryState = { error: Error | null };

const initialState: ErrorBoundaryState = { error: null };

export class ErrorBoundary extends React.Component<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error: error };
  }

  state = initialState;
  resetErrorBoundary = (...args: Array<unknown>): void => {
    this.props.onReset?.(...args);
    this.reset();
  };

  reset(): void {
    this.setState(initialState);
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.props.onError?.(error, info, this.resetErrorBoundary);
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
    const { error } = this.state;
    const { resetKeys } = this.props;

    // There's an edge case where if the thing that triggered the error
    // happens to *also* be in the resetKeys array, we'd end up resetting
    // the error boundary immediately. This would likely trigger a second
    // error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call
    // of cDU after the error is set

    if (error !== null && prevState.error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  render(): React.ReactNode {
    const { error } = this.state;

    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (error === null) {
      return this.props.children;
    }

    const props = {
      error: error,
      resetErrorBoundary: this.resetErrorBoundary,
    };

    if (typeof fallbackRender === 'function') {
      return fallbackRender(props);
    }

    if (FallbackComponent) {
      return <FallbackComponent {...props} />;
    }

    return fallback;
  }
}
