import { get as _get } from 'lodash';

type Parameters = Record<string, unknown>;
export type MustacheMatcher = (parameters: Parameters, toMatch: string, fallback?: string) => string;
type MustacheRenderer = (template: string, parameters?: Parameters) => string;

interface MustacheRenderOptions {
  matcher?: MustacheMatcher;
  pattern?: RegExp;
  separator?: string;
}

const defaultMatcher: MustacheMatcher = (parameters, toMatch, fallback = '') =>
  `${_get(parameters, toMatch, fallback)}`;

export const mustacheLowercaseMatcher: MustacheMatcher = (parameters, toMatch, fallback = '') =>
  `${_get(parameters, toMatch.toLowerCase(), fallback)}`;

const defaultOptions: Required<MustacheRenderOptions> = {
  matcher: defaultMatcher,
  pattern: /{{[{]?(.*?)[}]?}}/g,
  separator: '|',
};

export const mustacheRenderer = (options: MustacheRenderOptions = {}): MustacheRenderer => {
  const mergedOptions: Required<MustacheRenderOptions> = Object.assign({}, defaultOptions, options);

  const getMatches = (template: string) => {
    const pattern = mergedOptions.pattern;
    return [...template.matchAll(pattern)].reverse();
  };

  const replaceAt = (source: string, replacement: string, start: number, end: number) => {
    const font = source.substring(0, start);
    const back = source.substring(end);
    return font + replacement + back;
  };

  const processMatch = (template: string, match: RegExpMatchArray, parameters: Parameters): string => {
    const start = match.index ?? 0;
    const end = start + match[0].length;
    const parts = match[1].split(mergedOptions.separator).map((part) => part.trim());
    const param = mergedOptions.matcher(parameters, parts[0], parts[1]);

    return replaceAt(template, `${param}`, start, end);
  };

  const processMatches = (template: string, matches: Array<RegExpMatchArray>, parameters: Parameters): string =>
    matches.reduce((value, match) => processMatch(value, match, parameters), template);

  return (template, parameters = {}) => {
    const matches = getMatches(template);
    if (matches.length === 0) {
      return template;
    }

    return processMatches(template, matches, parameters);
  };
};
