import { Position } from '@nucleus/types/web';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCalendarGrid } from '../../blocks/BlockCalendarGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CalendarBlockLayoutProps, getPositionRow } from './CalendarLayout';

export const CalendarBlockLayoutHorizontal = (props: CalendarBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const calendarOnTheLeft = props.blockLayoutVariant === 'variant1';
  const mobileMediaPosition = getMobileMediaPosition(props.blockLayoutVariantMobile);
  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout
      {...props}
      calendarOnTheLeft={calendarOnTheLeft}
      mobileMediaPosition={mobileMediaPosition}
      row={row}
    >
      <StyledInfoColumn>
        <BlockInfo {...innerProps} block={props.blocks[0]} />
      </StyledInfoColumn>
      <StyledCalendarColumn>
        <SpacerTop />
        <StyledBlockCalendarGrid {...innerProps} block={props.blocks[0]} />
      </StyledCalendarColumn>
    </StyledBlockLayout>
  );
};

const getMobileMediaPosition = (blockLayoutVariantMobile?: string) => {
  switch (blockLayoutVariantMobile) {
    case 'variant1':
      return 'top';
    case 'variant2':
      return 'bottom';
    default:
      return;
  }
};

const calendarOnTheLeft = booleanPropHelperFactory('calendarOnTheLeft');

const StyledBlockCalendarGrid = styled(BlockCalendarGrid)``;
const StyledHorizontalColumn = styled.div``;
const StyledInfoColumn = styled(StyledHorizontalColumn)``;
const StyledCalendarColumn = styled(StyledHorizontalColumn)``;

const StyledBlockLayout = styled(BlockLayout)<{
  calendarOnTheLeft: boolean;
  mobileMediaPosition?: 'top' | 'bottom';
  row: Position;
}>`
  flex-direction: row;
  flex-wrap: wrap;

  ${StyledHorizontalColumn} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    flex: 1 1 auto;
  }

  ${StyledCalendarColumn} {
    ${SpacerTop} {
      display: none;
    }

    justify-content: ${(props) => {
      switch (props.row) {
        case Position.Top:
          return 'flex-start';
        case Position.Center:
          return 'center';
        case Position.Bottom:
          return 'flex-end';
        default:
          return;
      }
    }};
  }

  ${StyledBlockCalendarGrid} {
    padding: var(--unit-length);
    padding-top: ${(props) => (props.spacing?.top ? 'calc(var(--unit-length) * 0.5)' : undefined)};
    padding-bottom: ${(props) => (props.spacing?.bottom ? 'calc(var(--unit-length) * 0.5)' : undefined)};
  }

  ${calendarOnTheLeft(css`
    ${StyledInfoColumn} {
      order: 2;
    }
    ${StyledCalendarColumn} {
      order: 1;
    }
  `)}

  /* NOTE: media.tabletLandscape */
  @media (max-width: 799px) {
    ${StyledInfoColumn} {
      min-height: ${(props) => {
        switch (props.height) {
          case 'medium':
            return '40vh';
          case 'large':
            return '70vh';
          default:
            return undefined;
        }
      }};
    }

    ${({ mobileMediaPosition }) => {
      switch (mobileMediaPosition) {
        case 'top':
          return css`
            ${StyledInfoColumn} {
              order: 2;
            }
            ${StyledCalendarColumn} {
              order: 1;
            }
          `;
        case 'bottom':
          return css`
            ${StyledInfoColumn} {
              order: 1;
            }
            ${StyledCalendarColumn} {
              order: 2;
            }
          `;
        default:
          return;
      }
    }}
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
    ${StyledHorizontalColumn} {
      width: 50%;
    }

    ${StyledCalendarColumn} {
      ${SpacerTop} {
        display: block;
      }
    }
  }
`;
