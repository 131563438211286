import { createPrefixedKey } from '@nucleus/lib-shape';
import { SectionDataWeb, SectionTypeEnum } from '@nucleus/types/web';

export const sectionRenderErrorFallback: SectionDataWeb = {
  id: createPrefixedKey('section'),
  type: SectionTypeEnum.Info,
  payload: {
    blockInfoPosition: 'center',
    blockBodyMaxColumns: 1,
    colorPaletteVariation: 'variation-2',
    blocks: [
      {
        body: [
          {
            type: 'paragraph',
            fontStyle: 'paragraph2',
            children: [
              { text: 'If you see this message repeatedly (even after reloading the page), please let us know.' },
            ],
          },
        ],
        headline: [
          { type: 'heading-three', fontStyle: 'headline3', children: [{ text: "This content couldn't be loaded." }] },
        ],
      },
    ],
    blockHeadlineAlignment: 'center',
    blockBodyAlignment: 'center',
    layout: 'constrained',
    blockHeadlineMaxWidth: 'medium',
    blockButtonsWidth: 'auto',
    blockLayout: 'layout4',
    blockButtonsAlignment: 'center',
    blockLayoutVariant: 'variant1',
    blockButtonsLayout: 'vertical',
    name: 'Error',
    height: 'medium',
  },
};
