import { EmbedItemPadding, Position } from '@nucleus/types/web';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockEmbedGrid } from '../../blocks/BlockEmbedGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { EmbedBlockLayoutProps, getPositionRow } from './EmbedLayout';

export const EmbedBlockLayoutHorizontal = (props: EmbedBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const itemPadding = props.blocks?.[0].itemPadding;
  const embedsOnTheLeft = props.blockLayoutVariant === 'variant1';
  const mobileMediaPosition = getMobileMediaPosition(props.blockLayoutVariantMobile);
  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout
      {...props}
      itemPadding={itemPadding}
      embedsOnTheLeft={embedsOnTheLeft}
      mobileMediaPosition={mobileMediaPosition}
      row={row}
    >
      <StyledInfoColumn>
        <BlockInfo {...innerProps} block={props.blocks[0]} />
      </StyledInfoColumn>
      <StyledEmbedColumn>
        <SpacerTop />
        <StyledBlockEmbedGrid {...innerProps} block={props.blocks[0]} />
      </StyledEmbedColumn>
    </StyledBlockLayout>
  );
};

const getMobileMediaPosition = (blockLayoutVariantMobile?: string) => {
  switch (blockLayoutVariantMobile) {
    case 'variant1':
      return 'top';
    case 'variant2':
      return 'bottom';
    default:
      return;
  }
};

const embedsOnTheLeft = booleanPropHelperFactory('embedsOnTheLeft');

const StyledBlockEmbedGrid = styled(BlockEmbedGrid)``;
const StyledHorizontalColumn = styled.div``;
const StyledInfoColumn = styled(StyledHorizontalColumn)``;
const StyledEmbedColumn = styled(StyledHorizontalColumn)``;

const StyledBlockLayout = styled(BlockLayout)<{
  itemPadding?: EmbedItemPadding;
  embedsOnTheLeft: boolean;
  mobileMediaPosition?: 'top' | 'bottom';
  row: Position;
}>`
  flex-direction: row;
  flex-wrap: wrap;

  ${StyledHorizontalColumn} {
    background: var(--color-media-background);
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    flex: 1 1 auto;
  }

  ${StyledEmbedColumn} {
    justify-content: center;

    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockEmbedGrid} {
    background: var(--color-media-background);
    padding: ${({ itemPadding }) => (itemPadding === 'none' ? undefined : `calc(var(--unit-length) * 0.5)`)};
  }

  ${embedsOnTheLeft(css`
    ${StyledInfoColumn} {
      order: 2;
    }
    ${StyledEmbedColumn} {
      order: 1;
    }
  `)}

  @media (max-width: 899px) {
    ${StyledInfoColumn} {
      min-height: ${(props) => {
        switch (props.height) {
          case 'medium':
            return '66vh';
          case 'large':
            return '80vh';
          default:
            return undefined;
        }
      }};
    }
    ${({ mobileMediaPosition }) => {
      switch (mobileMediaPosition) {
        case 'top':
          return css`
            ${StyledInfoColumn} {
              order: 2;
            }
            ${StyledEmbedColumn} {
              order: 1;
            }
          `;
        case 'bottom':
          return css`
            ${StyledInfoColumn} {
              order: 1;
            }
            ${StyledEmbedColumn} {
              order: 2;
            }
          `;
        default:
          return;
      }
    }}
  }

  @media (min-width: 900px) {
    flex-wrap: nowrap;
    ${StyledHorizontalColumn} {
      width: 50%;
    }

    ${StyledEmbedColumn} {
      ${SpacerTop} {
        display: block;
      }

      justify-content: ${(props) => {
        switch (props.row) {
          case Position.Top:
            return 'flex-start';
          case Position.Center:
            return 'center';
          case Position.Bottom:
            return 'flex-end';
          default:
            return;
        }
      }};
    }
  }
`;
