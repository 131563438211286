import { ImageWeb, SrcSet } from '@nucleus/types/media/image';
import { sortBy as _sortBy } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect';
import { BlurHash } from './BlurHash';

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'srcSet'> & {
  blurHash?: string;
  dimensions?: {
    height: number;
    width: number;
  };
  size?: number;
  srcSet?: Array<SrcSet>;
};

export const BlurHashImage = (props: Props): JSX.Element => {
  const sortedSets = _sortBy(props.srcSet ?? [], 'dimensions.width');
  const srcSet = sortedSets.map((srcSet) => `${srcSet.src} ${srcSet.dimensions.width}w`).join(', ');

  const onLoadProps = useImageOnLoadProps();

  if (props.dimensions === undefined) {
    return (
      <BasicContainer size={props.size}>
        <BasicImage {...props} srcSet={srcSet} />
      </BasicContainer>
    );
  }

  return (
    <Container dimensions={props.dimensions} size={props.size}>
      {props.blurHash && <StyledBlurHash hash={props.blurHash} />}
      <Image {...onLoadProps} {...props} srcSet={srcSet} />
    </Container>
  );
};

const useImageOnLoadProps = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const ref = React.useRef<HTMLImageElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      setIsLoaded(ref.current.complete);
    }
  }, []);

  return {
    onLoad: () => setIsLoaded(true),
    isLoaded: isLoaded,
    ref: ref,
  };
};

const Container = styled.div<{ dimensions?: ImageWeb['dimensions']; size?: number }>`
  position: relative;
  width: ${(props) => `${props?.size ?? '100%'}`};
  height: 0;
  padding-top: ${(props) =>
    ((props.dimensions?.height ?? 1) / (props.dimensions?.width ?? 1)) * (props.size ?? 100)}%; /* Aspect Ratio */
  border-radius: 10px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
`;

const Image = styled.img<{ isLoaded?: boolean }>`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    `}
`;

const StyledBlurHash = styled(BlurHash)`
  position: absolute;
  inset: 0;
`;

const BasicContainer = styled.div<{ size?: number }>`
  position: relative;
  width: ${(props) => `${props?.size ?? '100%'}`};
  border-radius: 10px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
`;

const BasicImage = styled.img`
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
`;
