import type { SrcSet } from '@nucleus/types/media/image';
import { Descendant, Element } from 'slate';

export const traverse = <T extends Descendant>(nodes: T[], visit: (node: T) => T): T[] => {
  return nodes.map((node) => {
    node = visit(node);
    if (Element.isElement(node)) {
      node.children = traverse(node.children as any, visit);
    }
    return node;
  });
};

export const traverseAsync = async <T extends Descendant>(nodes: T[], visit: (node: T) => Promise<T>): Promise<T[]> => {
  return await Promise.all(
    nodes.map(async (node) => {
      node = await visit(node);
      if (Element.isElement(node)) {
        node.children = await traverseAsync(node.children as any, visit);
      }
      return node;
    })
  );
};

export interface ImageElement {
  type: 'image';
  src: string;
  href?: string;
  fileId?: string;
  publicFileId?: string;
  bondId?: string;
  srcSet?: SrcSet[];
  size: string;
  children: Descendant[];
}

export const isImageElement = (node: unknown): node is ImageElement => (node as ImageElement).type === 'image';
