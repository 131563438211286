import { nucleusClass } from '@nucleus/web-theme-elements';
import { media } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ButtonSecondary } from './Button';
import { IconAngleLeft, IconAngleRight } from './Icons';

type SnapSwipeProps = {
  items: Array<any>;
  gridGap?: number | string;
  className?: string;
  style?: React.CSSProperties;
};

const ScrollOffset = 350;

export const SnapSwipe = (props: SnapSwipeProps): JSX.Element => {
  const swipeRef = React.useRef<HTMLDivElement>(null);

  const handleNextClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    swipeRef.current?.scrollTo({
      left: swipeRef.current?.scrollLeft + ScrollOffset,
      behavior: 'smooth',
    });
  };

  const handlePrevClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    swipeRef.current?.scrollTo({
      left: swipeRef.current?.scrollLeft - ScrollOffset,
      behavior: 'smooth',
    });
  };

  return (
    <SnapSwipeContainer
      className={classNames(nucleusClass('swipe'), props.className)}
      style={props.style}
      gridGap={props.gridGap}
    >
      <SnapSwipeRow>
        <SnapSwipeContent ref={swipeRef} className={nucleusClass('swipe-content')}>
          <SnapSwipePlatter>
            {props.items.map((item, index) => (
              <SnapSwipeItem key={index} className={nucleusClass('swipe-item')}>
                <SnapSwipeItemView>{item}</SnapSwipeItemView>
              </SnapSwipeItem>
            ))}
          </SnapSwipePlatter>
        </SnapSwipeContent>
        <PrevNextButtons className={nucleusClass('swipe-buttons')}>
          <ButtonSecondary shape="square" size="medium" icon={<IconAngleLeft />} onClick={handlePrevClick} />
          <ButtonSecondary shape="square" size="medium" icon={<IconAngleRight />} onClick={handleNextClick} />
        </PrevNextButtons>
      </SnapSwipeRow>
    </SnapSwipeContainer>
  );
};

const PrevNextButtons = styled.div`
  display: flex;
  gap: 2.4rem;
  padding: 1.7rem calc(var(--grid-padding) + 2.4rem) 2.4rem;
`;

const SnapSwipeContainer = styled.div<{ gridGap?: number | string }>`
  display: flex;
  position: relative;
`;

const SnapSwipeRow = styled.div`
  position: relative;
  width: 100%;
`;

const SnapSwipeContent = styled.div`
  position: relative;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: contain;
  overflow-x: scroll;
  // Hide scrollbar
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SnapSwipeItemView = styled.div<{ desktopHeight?: string; mobileHeight?: string }>`
  display: flex;
  height: ${(props) => props.mobileHeight};

  ${media.tabletPortraitAndUp`
    height: ${(props: { desktopHeight?: string; mobileHeight?: string }) => props.desktopHeight};
  `}
`;

const SnapSwipeItem = styled.div`
  display: inline-block;
  scroll-snap-coordinate: left;
  scroll-snap-align: start;
`;

const SnapSwipePlatter = styled.div`
  vertical-align: top;
  display: inline-flex;
  padding-top: var(--grid-gap);
  padding-bottom: var(--grid-gap);
  background-color: var(--color-snap-swipe-content-background);

  & > div {
    display: inline-block;
    scroll-snap-coordinate: left;
    scroll-snap-align: start;
  }

  ${SnapSwipeItemView} {
    transform: translateX(var(--grid-padding));
    margin-right: var(--grid-gap);
  }

  ${SnapSwipeItem} {
    &:last-child {
      ${SnapSwipeItemView} {
        padding-right: var(--grid-padding);
      }
    }
  }
`;
