import React from 'react';

export interface MenuListContext {
  setChildMenuListIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MenuListContext = React.createContext<MenuListContext>(null!);
MenuListContext.displayName = 'MenuListContext';

export const useMenuListContext = (): MenuListContext => React.useContext(MenuListContext);
