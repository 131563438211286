import { ParameterStoreService } from '../type/service';
import { ParameterValueService } from '../type/value';

export const newParameterValueService = (service: ParameterStoreService, name: string): ParameterValueService => {
  const getValue: ParameterValueService['getValue'] = async <T>(fallback?: T) => {
    return await service.getParameter(name, fallback);
  };

  const getMemoizedValue: ParameterValueService['getMemoizedValue'] = async <T>(options = {}, fallback?: T) => {
    return await service.getMemoizedParameter(name, fallback, options);
  };

  return {
    parameterName: service.getParameterName(name),
    getValue: getValue,
    getMemoizedValue: getMemoizedValue,
  };
};
