import React, { useEffect, useLayoutEffect, useRef } from 'react';

/**
 * useInterval calls the given callback at the intervalMs intervalMs.
 * Set the intervalMs to null, to stop the intervalMs
 *
 * @param callback
 * @param intervalMs
 */
export const useInterval = <T extends () => any>(callback: T, intervalMs: number | null): void => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (intervalMs === null) {
      return;
    }
    const id = window.setInterval(() => callbackRef.current(), intervalMs);
    return () => window.clearInterval(id);
  }, [intervalMs]);
};

/**
 * useInterval calls the given callback at the intervalMs intervalMs.
 * Set the intervalMs to null, to stop the intervalMs
 *
 * @param callback
 * @param intervalMs
 */
export const useIntervalControl = <T extends () => any>(callback: T, intervalMs: number): [() => void, () => void] => {
  const [state, setState] = React.useState<number | null>(null);

  useInterval(callback, state);

  const start = () => setState(intervalMs);
  const stop = () => setState(null);

  return [start, stop];
};
