import { useSection } from '@nucleus/web-theme';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SectionOverlayRenderer } from '../components/overlays/OverlayRenderer';
import { SectionGlobalStyle } from '../components/SectionGlobalStyle';
import { buildThemeColorsVariationClassName } from '../utils/styles';
import { useScrollToHash } from '@nucleus/web-theme-elements/src/hooks/useScrollToHash';

interface SectionProps {
  as?: any;
  className?: string;
  children?: React.ReactNode;
}

export const Section = ({ as, className, children }: SectionProps): JSX.Element | null => {
  const section = useSection();
  const ref = useScrollToHashWithOffset(section.id);

  if (section === undefined || section.id === undefined || section.payload === undefined) {
    return null;
  }

  const classNames = [className, buildThemeColorsVariationClassName(section.payload.colorPaletteVariation)];
  const sectionClassName = classNames.join(' ');

  return (
    <>
      <SectionOverlayRenderer />
      <SectionGlobalStyle />
      <StyledSection as={as} id={section.id} className={sectionClassName} ref={ref}>
        {children}
      </StyledSection>
    </>
  );
};

const StyledSection = styled.section``;

const useScrollToHashWithOffset = (id: string) => useScrollToHash(id, useHeightOfStickyNavbar());

/** Hack: gets the height of the sticky header navbar using the dom query */
const useHeightOfStickyNavbar = (): number => {
  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    // Get the sticky header
    for (const headerElement of document.querySelectorAll(`.nucleus-section-header-sticky`)) {
      // Gets the Navbar element
      const navbarElement = headerElement.querySelector(':scope > :first-child > :first-child > :first-child');

      if (navbarElement) {
        setState(navbarElement.clientHeight);
        return;
      }
    }

    setState(0);
  });

  return state;
};
